import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { useState } from "react";
import Customer from "../../types/customer/customer";
import AdminPortalCustomers from "./AdminPortalCustomers";
import CreateCustomerButton from "./create-customer/CreateCustomerButton";
import CreateCustomerDialog from "./create-customer/CreateCustomerDialog";

const AdminPortal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const addCustomer = (customer: Customer | undefined) => {
    if (!customer) return;
    setCustomers((customers) => [...customers, customer]);
  };

  return (
    <Box className="flex items-center justify-center w-full">
      <CreateCustomerDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSuccess={addCustomer}
      />
      <Card className="w-1/2">
        <CardHeader
          title="Admin Portal"
          action={<CreateCustomerButton openDialog={() => setIsOpen(true)} />}
        />
        <CardContent>
          <AdminPortalCustomers
            customers={customers}
            setCustomers={setCustomers}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdminPortal;
