import { Box } from "@mui/material";
import { LoginForm } from "../../components/auth/LoginForm";

export default function Login() {
  return (
    <Box display="flex" justifyContent="center">
      <LoginForm />
    </Box>
  );
}
