import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import FilePicker from "../../components/common/FilePicker/FilePicker";
import { FormHelperText } from "@mui/material";
import { ConfigureFederatedAuthValues } from "./FederatedAuthForm";

export interface MetadataDocumentInputProps {
  className?: string;
  size?: "small" | "medium";
}

const MetadataDocumentInput = ({
  className = "",
  size = "small",
}: MetadataDocumentInputProps) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<ConfigureFederatedAuthValues>();

  const {
    disabled: isFileDisabled,
    ref: fileRef,
    ...registerFile
  } = register("metadataFiles");
  const registerUrl = register("metadataUrl");

  const fileList = watch("metadataFiles");
  const url = watch("metadataUrl");

  return (
    <div className={`w-full flex flex-col space-y-2 m-auto ${className}`}>
      <FilePicker
        {...registerFile}
        inputRef={fileRef}
        error={errors.metadataFiles}
        acceptedFileTypes={[".xml"]}
        ButtonProps={{
          size,
          variant: "outlined",
          disabled: !!url,
        }}
      >
        Upload metadata
      </FilePicker>
      {errors.metadataFiles && (
        <FormHelperText error>{errors.metadataFiles.message}</FormHelperText>
      )}
      <Divider>OR</Divider>
      <TextField
        {...registerUrl}
        label="Metadata URL"
        error={!!errors.metadataUrl}
        disabled={isFileDisabled || (!!fileList && fileList.length > 0)}
        fullWidth
      />
      {errors.metadataUrl && (
        <FormHelperText error>{errors.metadataUrl.message}</FormHelperText>
      )}
    </div>
  );
};

export default MetadataDocumentInput;
