import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";

export interface CreateCustomerButtonProps {
  openDialog: () => void;
}

const CreateCustomerButton = ({ openDialog }: CreateCustomerButtonProps) => {
  return (
    <IconButton onClick={openDialog}>
      <AddIcon />
    </IconButton>
  );
};

export default CreateCustomerButton;
