import { UseMutateAsyncFunction } from "@tanstack/react-query";
import DeleteDialog, {
  DeleteDialogProps,
} from "../../components/common/DeleteDialog/DeleteDialog";
import { IConnectorRow } from "../../components/connectors/models/Connector";
import { useDeleteConnector } from "../../hooks/useConnector";
import { DeleteConnectorRequest } from "../../api/connector";
import { useAlert } from "../../context/AlertContext";
import ErrorMessage from "../../types/feedback/ErrorMessage";

const deleteConnector = async (
  connector: IConnectorRow | undefined,
  deleteAsync: UseMutateAsyncFunction<
    void,
    unknown,
    DeleteConnectorRequest,
    unknown
  >,
  setConnectors: React.Dispatch<React.SetStateAction<IConnectorRow[]>>
) => {
  if (!connector) return;
  const request: DeleteConnectorRequest = {
    customerId: connector.customerId,
    connectorId: connector.connectorId,
    jobConfigId: connector.jobConfigId,
    type: connector.type,
  };
  await deleteAsync(request);
  setConnectors((prev) => prev.filter((c) => c.connectorId !== connector.connectorId));
};

export interface DeleteConnectorDialogProps
  extends Omit<DeleteDialogProps, "entityName" | "deleteEntity"> {
  connector: IConnectorRow | undefined;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setConnectors: React.Dispatch<React.SetStateAction<IConnectorRow[]>>;
}

const DeleteConnectorDialog = ({
  isOpen,
  setIsOpen,
  connector,
  setConnectors,
  ...props
}: DeleteConnectorDialogProps) => {
  const showAlert = useAlert();
  const { isPending, mutateAsync } = useDeleteConnector({
    onError: (error) => {
      if (error instanceof Error) {
        showAlert(error.message, "error");
        return;
      }
      showAlert(ErrorMessage.UNKNOWN, "error");
    },
    onSuccess: () => {
      showAlert("Connector deleted successfully.", "success");
      setIsOpen(false);
    },

  });

  return (
    <DeleteDialog
      {...props}
      isLoading={isPending}
      entityName="Connector"
      deleteEntity={() => deleteConnector(connector, mutateAsync, setConnectors)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

export default DeleteConnectorDialog;
