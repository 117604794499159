import {
  GridColDef,
  GridColumnMenu,
  GridColumnMenuColumnsItem,
  GridColumnMenuProps,
  GridColumnMenuSortItem,
} from "@mui/x-data-grid";
import RenameFieldMenuItem from "./RenameFieldMenuItem";
import ResetFieldLabelMenuItem from "./ResetFieldLabelMenuItem";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import ResetAllFieldLabelsMenuItem from "./ResetAllFieldLabelsMenuItem";

export type ExtendedGridColDef = GridColDef & {
  disableRenameable?: boolean;
};

const RenameFieldMenu = (props: GridColumnMenuProps) => {
  const { colDef } = props;
  const extendedColDef = colDef as ExtendedGridColDef;

  if (extendedColDef.disableRenameable === true) {
    return <GridColumnMenu {...props} />;
  }

  const itemProps = {
    colDef: extendedColDef,
    onClick: props.hideMenu,
  };

  return (
    <>
      <Stack p={0.5}>
        <GridColumnMenuSortItem {...itemProps} />
      </Stack>
      <Divider />
      <Stack p={0.5}>
        <GridColumnMenuColumnsItem {...itemProps} />
      </Stack>
      <Divider />
      <Stack p={0.5}>
        <RenameFieldMenuItem {...itemProps} />
        <ResetFieldLabelMenuItem {...itemProps} />
        <ResetAllFieldLabelsMenuItem {...itemProps} />
      </Stack>
    </>
  );
};

export default RenameFieldMenu;
