import { Card, CardContent, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import { InteractionsByDay } from "../../types/dashboard/Dashboard";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";

interface InteractionAnalyticsBarChartProps {
  interactionsByDay: InteractionsByDay | undefined;
  isBarChartLoading: boolean;
}

const InteractionAnalyticsBarChart = ({
  interactionsByDay,
  isBarChartLoading,
}: InteractionAnalyticsBarChartProps) => {
  const [chartData, setChartData] = useState<{
    categories: string[];
    series: number[];
  }>({
    categories: [],
    series: [],
  });

  useEffect(() => {
    if (isBarChartLoading || !interactionsByDay) return;

    if (interactionsByDay) {
      const days = Object.keys(interactionsByDay);
      const count = Object.values(interactionsByDay);

      const dates = days.map((day) =>
        new Date(day).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
      setChartData({
        categories: dates,
        series: count,
      });
    }
  }, [isBarChartLoading, interactionsByDay]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories: chartData.categories,
    },
    title: {
      text: "Interactions by Day for the Last 30 Days",
      align: "center",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
  };

  const series = [
    {
      name: "Interactions",
      data: chartData.series,
    },
  ];

  return (
    <>
      {isBarChartLoading && <LoadingIndicator />}
      <Card>
        <CardContent>
          <Typography variant="h6">Interactions by Day</Typography>
          <Chart options={options} series={series} type="bar" height={285} />
        </CardContent>
      </Card>
    </>
  );
};

export default InteractionAnalyticsBarChart;
