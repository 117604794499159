import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";

export interface GridToolbarRefreshButtonProps {
  refresh: () => void | Promise<void>;
}

const GridToolbarRefreshButton = ({
  refresh,
}: GridToolbarRefreshButtonProps) => (
  <Button size="small" onClick={refresh}>
    <RefreshIcon className="mr-2 !text-lg" />
    Refresh
  </Button>
);

export default GridToolbarRefreshButton;
