import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";
import {
  CreateSiteRequest,
  CreateSiteResponse,
  EditSiteRequest,
  GetSitesResponse,
  createSite,
  editSite,
  getSite,
  getSites,
} from "../api/site";
import Site from "../types/site/Site";

export const useSite = (
  customerId: string | undefined,
  siteId: string | undefined
) => {
  return useQuery<Site | undefined, Error>({
    queryKey: ["site", customerId, siteId],
    queryFn: () => getSite(customerId!, siteId!),
    enabled: !!customerId && !!siteId,
  });
};

export const useSites = (customerId: string | undefined) => {
  return useQuery<GetSitesResponse, Error>({
    queryKey: ["sites"],
    queryFn: () => getSites(customerId!),
    enabled: !!customerId,
  });
};

export const useCreateSite = () => {
  const mutationOptions: UseMutationOptions<CreateSiteResponse, Error, CreateSiteRequest> = {
    mutationFn: (request: CreateSiteRequest) => {return createSite(request)}
  }
  return useMutation(mutationOptions);
};

export const useEditSite = () => {
  const mutationOptions: UseMutationOptions<Site | undefined, Error, EditSiteRequest> = {
    mutationFn: (request: EditSiteRequest) => {
      return editSite(request);
    }
  }
  return useMutation(mutationOptions);
};
