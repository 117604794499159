import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

interface DatePickerProps<T> extends MuiDatePickerProps<T> {
  name: string;
  control: any;
  label: string;
}

export const DatePicker = <T = unknown,>({
  name,
  control,
  label,
  ...props
}: DatePickerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <MuiDatePicker
          {...props}
          label={label}
          onChange={onChange}
          defaultValue={value}
          slotProps={{ textField: { size: "small" } }}
        />
      )}
    />
  );
};
