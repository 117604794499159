import Typography from "@mui/material/Typography";
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from "@mui/x-data-grid";

const getValueDisplay = (value: unknown): string => {
  if (Array.isArray(value) || typeof value === "object") {
    return JSON.stringify(value, null, 2);
  }
  return String(value);
};

export interface DictionaryGridViewProps<TRowModel extends GridValidRowModel>
  extends GridRenderCellParams<
    TRowModel,
    Record<string, unknown>,
    unknown,
    GridTreeNodeWithRender
  > {}

const DictionaryGridView = <TRowModel extends GridValidRowModel>({
  value,
}: DictionaryGridViewProps<TRowModel>) => {
  if (!value || Object.keys(value).length === 0) return null;

  return (
    <div className="flex flex-col space-y-2 justify-center h-full">
      {Object.entries(value)?.map(([key, value]) => (
        <Typography key={key} variant="body2">
          {key}: {getValueDisplay(value)}
        </Typography>
      ))}
    </div>
  );
};

export default DictionaryGridView;
