const setItem = (key: string, value: object) => {
  if (!key || !value) {
    throw new Error("Key and value are required");
  }
  localStorage.setItem(key, JSON.stringify(value));
}

const getItem = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
}

const removeItem = (key: string) => {
  localStorage.removeItem(key);
}

export { setItem, getItem, removeItem };