import { Link } from "@mui/material";
import { PropsWithChildren, useContext } from "react";
import { isAdmin } from "../../helpers/authHelpers";
import { useCustomer } from "../../hooks/useCustomer";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import MuiTypography from "@mui/material/Typography";
import { AdminInfoContext } from "../../context/AdminInfoContext";

const Bar = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex w-full justify-center items-center h-8 py-2 bg-yellow-100">
      {children}
    </div>
  );
};

const Typography = ({ children }: PropsWithChildren) => {
  return (
    <MuiTypography className="pl-2" variant="body1">
      {children}
    </MuiTypography>
  );
};

const AdminInfoBar = () => {
  const { isVisible } = useContext(AdminInfoContext);
  const { user } = useContext(AuthContext);
  const { customerId } = useParams();
  const shouldRender = isAdmin(user) && !!customerId && isVisible;
  const { data, error, isLoading } = useCustomer(customerId, shouldRender);

  if (!shouldRender) {
    return null;
  }

  if (isLoading) {
    return (
      <Bar>
        <Typography>Loading...</Typography>
      </Bar>
    );
  }

  if (error) {
    return (
      <Bar>
        <Typography>Error: {error.message}</Typography>
      </Bar>
    );
  }

  return (
    <Bar>
      <Typography>
        Customer: {data?.name ?? ""} ({customerId})
      </Typography>
      <Link href="/customers/admin">
        <Typography>Change</Typography>
      </Link>
    </Bar>
  );
};

export default AdminInfoBar;
