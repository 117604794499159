import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import AlertProvider from "../context/AlertContext";
import { AuthProvider } from "../context/AuthContext";
import AdminInfoProvider from "../context/AdminInfoContext";
import useVerifyAuth from "../hooks/useVerifyAuth";

export default function HeaderlessRoot() {
  useVerifyAuth();

  return (
    <AuthProvider>
      <AlertProvider>
        <AdminInfoProvider>
          <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
            <Box className="flex flex-col flex-1 overflow-y-auto" p={2}>
              <Outlet />
            </Box>
          </Box>
      </AdminInfoProvider>
      </AlertProvider> 
    </AuthProvider>
  );
}
