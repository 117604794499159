import {
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { ReactNode, useMemo, useState } from "react";
import DataTable, { DataTableProps } from "../DataTable/DataTable";
import ResultsPageControls from "./ResultsPageControls";
import { Box, Radio } from "@mui/material";
import { RowSelectorMode } from "../../../types/datatable/RowSelectorMode";

export interface ResultsPageProps<TRow extends GridValidRowModel>
  extends DataTableProps {
  permissionResource?: string;
  tableId: string;
  entityName: string;
  columns: GridColDef<TRow>[];
  rows: TRow[];
  selectedRowId: string | null;
  setSelectedRowId: (id: string | null) => void;
  setCreateFormOpen?: (open: boolean) => void;
  setEditFormOpen?: (open: boolean) => void;
  setDeleteDialogOpen?: (open: boolean) => void;
  disableCreatable?: boolean;
  disableEditable?: boolean;
  disableDeletable?: boolean;
  hideDefaultButtons?: boolean;
  buttons?: ReactNode[];
  rowSelectorMode?: RowSelectorMode;
  onSortModelChange?: (
    sortModel: GridSortModel,
    details: GridCallbackDetails
  ) => Promise<GridSortModel> | GridSortModel;
}

const ResultsPage = <TRow extends GridValidRowModel>({
  permissionResource,
  entityName,
  loading = false,
  columns,
  rows,
  selectedRowId,
  setSelectedRowId,
  setCreateFormOpen = () => {},
  setEditFormOpen = () => {},
  setDeleteDialogOpen = () => {},
  disableCreatable = false,
  disableEditable = false,
  disableDeletable = false,
  hideDefaultButtons = false,
  buttons = [],
  onSortModelChange,
  className = "",
  rowSelectorMode = RowSelectorMode.SINGLE,
  ...props
}: ResultsPageProps<TRow>) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );

  const radioButtonColumn: GridColDef<TRow> = useMemo(
    () => ({
      field: "radio",
      headerName: "",
      width: 70,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Radio
            checked={selectedRowId === params.id}
            onChange={() => setSelectedRowId(params.id as string)}
            value={params.id}
          />
        </Box>
      ),
    }),
    [selectedRowId, setSelectedRowId]
  );

  const memoizedColumns = useMemo(
    () => (rowSelectorMode === RowSelectorMode.SINGLE ? [radioButtonColumn, ...columns] : columns),
    [columns, rowSelectorMode, radioButtonColumn]
  );

  const determineSelectionModel = () => {
    if (rowSelectorMode === RowSelectorMode.MULTIPLE) {
      return selectionModel;
    } else if (rowSelectorMode === RowSelectorMode.SINGLE && selectedRowId) {
      return [selectedRowId];
    } else {
      return [];
    }
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    if (rowSelectorMode === RowSelectorMode.MULTIPLE) {
      setSelectionModel(newSelection);
    } else if (rowSelectorMode === RowSelectorMode.SINGLE) {
      setSelectedRowId(
        newSelection.length ? (newSelection[0] as string) : null
      );
    }
  };

  return (
    <>
      <ResultsPageControls
        permissionResource={permissionResource}
        entityName={entityName}
        onCreate={() => setCreateFormOpen(true)}
        onEdit={() => setEditFormOpen(true)}
        onDelete={() => setDeleteDialogOpen(true)}
        selectedRowId={selectedRowId}
        hideDefaultButtons={hideDefaultButtons}
        buttons={buttons}
      />
      <DataTable
        {...props}
        rowSelectionModel={determineSelectionModel()}
        onRowSelectionModelChange={handleSelectionChange}
        onSortModelChange={onSortModelChange}
        columns={memoizedColumns}
        rows={rows}
        loading={loading}
        rowSelectorMode={rowSelectorMode}
      />
    </>
  );
};

export default ResultsPage;
