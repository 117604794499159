import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { PropsWithChildren, ReactNode, useState } from "react";
import { FieldPath, FieldValues, UseFormTrigger } from "react-hook-form";

export interface WizardStep<TFieldValues extends FieldValues = FieldValues> {
  index: number;
  title?: string;
  content: ReactNode;
  optional?: boolean;
  fieldsToValidate?: FieldPath<TFieldValues>[];
}

export interface WizardProps<TFieldValues extends FieldValues = FieldValues> {
  formId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  steps: WizardStep<TFieldValues>[];
  validationTrigger?: UseFormTrigger<TFieldValues>;
  onClose?: () => void;
  activeStepIndex: number;
  setActiveStepIndex: (step: number) => void;
}

const StepLabelAlert = ({ children }: PropsWithChildren) => {
  return (
    <Typography variant="caption" color="error">
      {children}
    </Typography>
  );
};

export interface StepContentProps<
  TFieldValues extends FieldValues = FieldValues
> {
  formId: string;
  className?: string;
  activeStep: WizardStep<TFieldValues>;
  steps: WizardStep<TFieldValues>[];
  setActiveStep: (step: number) => void;
  onSubmit?: () => void;
  onClose: () => void;
  isNextDisabled?: boolean;
  isBackDisabled?: boolean;
  onNext?: OnNextStepFunction;
}

type OnNextStepFunction = () =>
  | boolean
  | Promise<boolean | undefined>
  | undefined;

export interface NextStepButtonProps<
  TFieldValues extends FieldValues = FieldValues
> extends ButtonProps {
  formId: string;
  onNext?: OnNextStepFunction;
  activeStep: WizardStep<TFieldValues>;
  setActiveStep: (step: number) => void;
}

const NextStepButton = <TFieldValues extends FieldValues = FieldValues>({
  formId,
  onNext,
  activeStep,
  setActiveStep,
  ...props
}: NextStepButtonProps<TFieldValues>) => {
  const handleNext = async () => {
    const shouldContinue = await onNext?.();
    if (shouldContinue === false) {
      return;
    }
    setActiveStep(activeStep.index + 1);
  };

  return (
    <Button {...props} form={formId} color="inherit" onClick={handleNext}>
      Next
    </Button>
  );
};

export interface FinishWizardButtonProps extends ButtonProps {
  formId: string;
  onNext?: OnNextStepFunction;
  onSubmit?: () => void;
}

const FinishWizardButton = ({
  formId,
  onNext,
  onSubmit,
  ...props
}: FinishWizardButtonProps) => {

  return (
    <Button
      {...props}
      type="submit"
      form={formId}
      color="inherit"
      onClick={onSubmit}
    >
      Finish
    </Button>
  );
};

const StepContent = <TFieldValues extends FieldValues = FieldValues>({
  formId,
  activeStep,
  steps,
  setActiveStep,
  onSubmit,
  onClose,
  onNext,
  isNextDisabled = false,
  isBackDisabled = false,
  ...props
}: StepContentProps<TFieldValues>) => {
  const isFirstStep = activeStep.index === 0;
  const isFinalStep = activeStep.index === steps.length - 1;

  const handleBack = () => {
    if (isFirstStep) {
      onClose();
      return;
    }
    setActiveStep(activeStep.index - 1);
  };

  const handleSkip = () => {
    setActiveStep(activeStep.index + 1);
  };

  return (
    <Box className={`${props.className} m-2`} {...props}>
      {activeStep.content}
      <Box className="flex flex-row pt-2">
        <Button color="inherit" onClick={handleBack}>
          {isFirstStep ? "Cancel" : "Back"}
        </Button>
        {activeStep.optional && (
          <Button color="inherit" onClick={handleSkip}>
            Skip
          </Button>
        )}
        <Box className="flex-auto" />
        {isFinalStep ? (
          <FinishWizardButton
            formId={formId}
            onSubmit={onSubmit}
            disabled={isNextDisabled}
          />
        ) : (
          <NextStepButton
            formId={formId}
            onNext={onNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            disabled={isNextDisabled}
          />
        )}
      </Box>
    </Box>
  );
};

const getStepTitle = <TFieldValues extends FieldValues = FieldValues>(
  step: WizardStep<TFieldValues>
) => {
  return step.title ?? `Step ${step.index + 1}`;
};

const FormWizard = <TFieldValues extends FieldValues = FieldValues>({
  formId,
  isOpen,
  setIsOpen,
  steps,
  onClose,
  isBackDisabled = false,
  isNextDisabled = false,
  validationTrigger,
  activeStepIndex,
  setActiveStepIndex,
}: WizardProps<TFieldValues>) => {
  const [errors, setErrors] = useState<Record<number, boolean>>({});

  const validateStep = async (): Promise<boolean | undefined> => {
    const activeStep = steps[activeStepIndex];
    const isValid = await validationTrigger?.(
      activeStep.fieldsToValidate ?? []
    );

    setErrors((prev) => ({
      ...prev,
      [activeStepIndex]: !isValid,
    }));

    return isValid;
  };

  const resetWizard = () => {
    setActiveStepIndex(0);
    setErrors({});
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
    resetWizard();
  };

  const isStepError = (index: number) => errors[index] === true;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <Box className="pt-4 w-full flex flex-col justify-center p-2">
        <Box className="flex flex-row justify-center">
          <Stepper className="w-3/4" activeStep={activeStepIndex}>
            {steps.map((step) => (
              <Step key={getStepTitle(step)}>
                <StepLabel
                  optional={<StepLabelAlert />}
                  error={isStepError(step.index)}
                >
                  {getStepTitle(step)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <StepContent
          formId={formId}
          className="pt-4"
          activeStep={steps[activeStepIndex]}
          setActiveStep={setActiveStepIndex}
          steps={steps}
          onNext={validateStep}
          onClose={handleClose}
          isNextDisabled={isNextDisabled ?? isStepError(activeStepIndex)}
          isBackDisabled={isBackDisabled}
        />
      </Box>
    </Dialog>
  );
};

export default FormWizard;
