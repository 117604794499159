import { useCallback, useEffect, useRef } from "react";
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";
import { convertSecondsToTimeDisplay } from "../../../../helpers/displayHelpers";
import { PlayerTimeState } from "./TimeDisplay";

const DEFAULT_HEIGHT = 200;

export const waveformConfig = (
  container: HTMLElement,
  height: number | "auto" = DEFAULT_HEIGHT
): WaveSurferOptions => ({
  container,
  height,
  waveColor: "#163b5b",
  progressColor: "#black",
  cursorColor: "black",
  dragToSeek: true,
  autoScroll: true,
  normalize: true,
  backend: "WebAudio",
  fillParent: true,
});

export interface WaveformProps {
  className?: string;
  height?: number;
  audioPath: string | undefined;
  setIsLoading: (isLoading: boolean) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  volume: number;
  muted: boolean;
  setFailedToLoad: (failedToLoad: boolean) => void;
  onTimeChange: React.Dispatch<React.SetStateAction<PlayerTimeState | undefined>>;
}

const Waveform = ({
  className = "",
  height = DEFAULT_HEIGHT,
  audioPath,
  isPlaying,
  muted,
  volume,
  onTimeChange,
  setIsPlaying,
  setFailedToLoad,
  setIsLoading,
}: WaveformProps) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);

  const onFinish = useCallback(() => {
    if (!wavesurfer.current) return;
    setIsPlaying(false);
  }, [setIsPlaying]);

  useEffect(() => {
    if (!waveformRef.current) return;
    if (!audioPath) return;

    const options = waveformConfig(waveformRef.current, height);
    wavesurfer.current = WaveSurfer.create(options);

    setIsLoading(true);
    wavesurfer.current
      .load(audioPath)
      .catch((error: unknown) => {
        console.error(error);
        setFailedToLoad(true);
      })
      .finally(() => {
        setIsLoading(false);
        onTimeChange?.({
          currentTime: "00:00:00",
          duration: convertSecondsToTimeDisplay(
            wavesurfer.current?.getDuration()
          ),
        });
      });
    wavesurfer.current.on("finish", onFinish);

     const updateTime = () => {
      if (!wavesurfer.current) return;
      const currentTime = convertSecondsToTimeDisplay(
        wavesurfer.current.getCurrentTime()
      );
      const duration = convertSecondsToTimeDisplay(
        wavesurfer.current.getDuration()
      );
      onTimeChange({ currentTime, duration });
    };

    wavesurfer.current.on("timeupdate", updateTime);

    return () => {
      wavesurfer.current?.un("finish", onFinish);
      wavesurfer.current?.un("timeupdate", updateTime);
      wavesurfer.current?.destroy();
      onTimeChange(undefined);
    };
  }, [
    audioPath,
    height,
    onFinish,
    onTimeChange,
    setFailedToLoad,
    setIsLoading,
  ]);

  useEffect(() => {
    if (isPlaying) wavesurfer.current?.play();
    else wavesurfer.current?.pause();
  }, [isPlaying]);

  useEffect(() => {
    if (muted) {
      wavesurfer.current?.setVolume(0);
      return;
    }
    wavesurfer.current?.setVolume(volume / 100);
  }, [muted, volume]);

  return <div className={`${className} cursor-pointer`} ref={waveformRef} />;
};

export default Waveform;
