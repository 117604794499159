import { FormProvider, useForm } from "react-hook-form";
import defaultFormConfig from "../../helpers/validation/defaultFormConfig";
import FederatedAuthFormView from "./FederatedAuthFormView";
import Customer from "../../types/customer/customer";
import { useContext } from "react";
import { AlertContext, UseShowAlert } from "../../context/AlertContext";
import { z } from "zod";
import { UseMutationResult } from "@tanstack/react-query";
import { useConfigureFederatedAuth } from "../../hooks/useAdmin";

export interface ConfigureFederatedAuthValues {
  name: string;
  customer: Customer | null;
  metadataFiles: FileList;
  metadataUrl: string;
}

const convertToRequest = (values: ConfigureFederatedAuthValues): FormData => {
  const formData = new FormData();
  formData.append("name", values.name);
  if (values.metadataFiles && values.metadataFiles.length > 0) {
    const file = values.metadataFiles.item(0);
    formData.append("metadataFiles", file as Blob, file!.name);
  }
  formData.append("customerId", values.customer?.customerId ?? "");
  formData.append("metadataUrl", values.metadataUrl);

  return formData;
};

const DEFAULT_VALUES: Partial<ConfigureFederatedAuthValues> = {
  name: "",
  customer: null,
  metadataUrl: "",
};

const customerSchema = z.object({
  customerId: z.string(),
  name: z.string(),
  isDeleted: z.boolean(),
});

const schema = z
  .object({
    name: z.string({ required_error: "Name is required." }).min(1, {
      message: "Name is required.",
    }),
    customer: customerSchema
      .nullable()
      .refine((value) => !!value, {
        message: "Customer is required.",
      })
      .refine((value) => !value?.isDeleted, {
        message: "Customer has been deleted.",
      }),
    metadataFiles: z.instanceof(FileList).refine(
      (value) => {
        return !value || value.length === 0 || value.length === 1;
      },
      { message: "Please upload a single metadata file." }
    ),
    metadataUrl: z.union([
      z.literal(""),
      z.string().url({
        message: "Please enter a valid URL.",
      }),
    ]),
  })
  .refine(
    ({ metadataFiles, metadataUrl }: ConfigureFederatedAuthValues) => {
      return metadataFiles?.length === 1 || metadataUrl;
    },
    {
      message: "Please specify either a metadata file or URL.",
      path: ["metadataUrl"],
    }
  );

const submit = async (
  values: ConfigureFederatedAuthValues,
  configMutation: UseMutationResult<void, unknown, FormData, unknown>,
  showAlert: UseShowAlert
) => {
  try {
    await configMutation.mutateAsync(convertToRequest(values));
  } catch (error) {
    console.error(error);
    showAlert(
      "An unexpected error occurred saving your changes. Please try again.",
      "error"
    );
  }
};

const FederatedAuthForm = () => {
  const configMutation = useConfigureFederatedAuth();
  const form = useForm<ConfigureFederatedAuthValues>({
    ...defaultFormConfig<ConfigureFederatedAuthValues>(schema),
    defaultValues: DEFAULT_VALUES,
  });
  const showAlert = useContext(AlertContext);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit((values) =>
          submit(values, configMutation, showAlert)
        )}
        noValidate
      >
        <FederatedAuthFormView form={form} />
      </form>
    </FormProvider>
  );
};

export default FederatedAuthForm;
