import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode, useEffect } from "react";

interface DialogFormState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isSubmitting: boolean;
  isSubmitDisabled?: boolean;
  isCancelDisabled?: boolean;
}

interface DialogFormProps extends DialogFormState {
  formId: string;
  dialogTitle: string;
  children?: ReactNode;
  beforeClose?: () => void;
  afterClose?: () => void;
  submitText?: string;
}

const DialogForm = ({
  formId,
  dialogTitle,
  isOpen,
  setIsOpen,
  beforeClose,
  afterClose,
  isSubmitting,
  isSubmitDisabled = false,
  isCancelDisabled = false,
  submitText = "Submit",
  children,
}: DialogFormProps) => {
  useEffect(() => {
    if (!isOpen && afterClose) {
      afterClose();
    }
  }, [afterClose, isOpen]);

  const handleClose = () => {
    beforeClose?.();
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent
        id="dialog-description"
        sx={{ paddingTop: "1rem !important" }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isSubmitting || isCancelDisabled}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form={formId}
          disabled={isSubmitting || isSubmitDisabled}
          variant="contained"
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogForm;
