import Grid from "@mui/material/Unstable_Grid2";
import { IAuditLog } from "../../types/audit-logs/auditLog";
import KeyValuePair from "../../components/common/KeyValuePair/KeyValuePair";

export interface AuditLogDataProps {
  data: IAuditLog;
}

const AuditLogData = ({ data }: AuditLogDataProps) => {
  // ? Should we display the IDs? I don't know what value they provide to the user
  return (
    <Grid spacing={1} container>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair label="User Name" value={data.userName} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair
          label="Date"
          value={new Date(data.date).toLocaleString()}
        />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair label="IP Address" value={data.ipAddress} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair label="HTTP Method" value={data.httpMethod} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair label="Path" value={data.path} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair label="Status Code" value={data.statusCode} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair
          label="Query String"
          value={
            data.queryString.length > 0 ? data.queryString.join(", ") : "N/A"
          }
        />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <KeyValuePair label="Resource ID" value={data.resourceId} />
      </Grid>
    </Grid>
  );
};

export default AuditLogData;
