import { Tooltip, IconButton } from "@mui/material";
import { LocalPhone, OndemandVideo, Chat, Email } from "@mui/icons-material";
import { InteractionType } from "../../../types/interaction/Interaction";

interface MediaTypeProps {
  interactionType: InteractionType;
}

const MediaTypeIndicator = ({ interactionType }: MediaTypeProps) => {
  const iconMap: { [key in InteractionType]: JSX.Element | null } = {
    [InteractionType.Audio]: <LocalPhone />,
    [InteractionType.Video]: <OndemandVideo />,
    [InteractionType.AudioAndVideo]: <OndemandVideo />,
    [InteractionType.Chat]: <Chat />,
    [InteractionType.Email]: <Email />,
  };

  return (
    <Tooltip title={interactionType}>
      <IconButton
      sx={{cursor: "default"}}>{iconMap[interactionType] || null}</IconButton>
    </Tooltip>
  );
};

export default MediaTypeIndicator;
