import "../../Audio/AudioPlayer.css";
import { PlayArrow, Pause } from "@mui/icons-material";
import { IconButton, IconButtonProps, SvgIconProps } from "@mui/material";

export interface PlayButtonProps extends IconButtonProps {
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  IconProps?: SvgIconProps;
}

const PlayButton = ({
  isPlaying,
  setIsPlaying,
  IconProps,
  ...props
}: PlayButtonProps) => {
  return (
    <IconButton
      className="PlayButton"
      onClick={() => setIsPlaying(!isPlaying)}
      {...props}
    >
      {isPlaying ? (
        <Pause {...IconProps} className="Icon Pause" />
      ) : (
        <PlayArrow {...IconProps} className="Icon Play" />
      )}
    </IconButton>
  );
};

export default PlayButton;
