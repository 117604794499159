import { GridInitialState, GridColumnVisibilityModel } from "@mui/x-data-grid";

export const getVisibleColumnsFromLocalStorage = (
  tableId?: string,
  initialState?: GridInitialState
): GridColumnVisibilityModel => {
  const columnVisibilityModel = localStorage.getItem(`${tableId}-columns`);
  if (columnVisibilityModel) {
    return JSON.parse(columnVisibilityModel);
  }
  return initialState?.columns?.columnVisibilityModel ?? {};
};

export const saveVisibleColumnsToLocalStorage = (
  tableId?: string,
  columnVisibilityModel?: GridColumnVisibilityModel
): void => {
  if (tableId && columnVisibilityModel) {
    localStorage.setItem(
      `${tableId}-columns`,
      JSON.stringify(columnVisibilityModel)
    );
  }
};
