import Customer from "../types/customer/customer";
import { getApi } from "./helpers/apiMethods";

export interface GetAllCustomersResponse {
  customers: Customer[];
}

export const getAllCustomers = async (): Promise<Customer[]> => {
  const response = await getApi<GetAllCustomersResponse>("/v1/customers");
  return response.data?.customers ?? [];
};

export interface GetCustomerResponse {
  customerId: string;
  name: string;
}

export const getCustomer = async (
  customerId: string
): Promise<GetCustomerResponse | undefined> => {
  const response = await getApi<GetCustomerResponse>(
    `/v1/customers/${customerId}`
  );
  return response.data;
};
