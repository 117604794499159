import { createContext } from "react";

export interface FieldLabel {
  defaultName: string;
  name: string;
}

export interface IFieldLabelContext {
  getFieldLabel: (field: string) => string;
  setFieldLabel: (field: string, label: string) => void;
  resetFieldLabel: (field: string) => void;
  resetAllFieldLabels: () => void;
}

const FieldLabelContext = createContext<IFieldLabelContext>({
  getFieldLabel: () => {
    throw new Error("getFieldLabel not implemented");
  },
  setFieldLabel: () => {
    throw new Error("setFieldLabel not implemented");
  },
  resetFieldLabel: () => {
    throw new Error("resetFieldLabel not implemented");
  },
  resetAllFieldLabels: () => {
    throw new Error("resetAllFieldLabels not implemented");
  },
});

export default FieldLabelContext;
