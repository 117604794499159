import PropTypes from "prop-types";
import { useContext } from "react";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import AuditLogs from "../pages/audit-logs/AuditLogs";
import Connectors from "../pages/connectors/connectors";
import SetLogo from "../pages/customization/SetLogo";
import FederatedAuth from "../pages/federated-auth/FederatedAuth";
import Login from "../pages/login/Login";
import ResetPassword from "../pages/login/ResetPassword";
import SetPassword from "../pages/login/SetPassword";
import Roles from "../pages/roles/Roles";
import Search from "../pages/search/Search";
import SiteList from "../pages/sites/SiteList";
import UserList from "../pages/users/UserList";
import ErrorPage403 from "./403";
import ErrorPage from "./error-page";
import Root from "./root";
import AdminPortal from "../pages/admin-portal/AdminPortal";
import ErrorPage440 from "../pages/error/Error440";
import NewWindowMediaPlayer from "../components/media/NewWindowMediaDisplay";
import HeaderlessRoot from "./headerlessRoot";
import Dashboard from "../pages/dashboard/Dashboard";
import PermissionsRestrictedRoute from "./PermissionsRestrictedRoute";

const ProtectedRoute = ({ children }) => {
  const { validating, authenticated } = useContext(AuthContext);

  if (validating) {
    return <>Loading...</>;
  } else if (!authenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

// All application routes will be defined here
const router = createBrowserRouter([
  {
    path: "/customers/:customerId/interactions/:playerInteractionId/:mediaType/:sourceIdLabel/:startTimeLabel/:endTimeLabel",
    element: (
      <HeaderlessRoot>
        <ProtectedRoute>
          <Outlet />
        </ProtectedRoute>
      </HeaderlessRoot>
    ),
    children: [
      {
        path: "",
        element: <NewWindowMediaPlayer />, 
      }
    ],
  },
  { 
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "403",
        element: <ErrorPage403 />,
      },
      {
        path: "440",
        element: <ErrorPage440 />
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
      {
        path: "reset-password",
        element: (
          <ProtectedRoute>
            <ResetPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "federated-auth",
        element: (
          <ProtectedRoute>
            <FederatedAuth />
          </ProtectedRoute>
        ),
      },
      {
        path: "/customers",
        element: <Outlet />,
        children: [
          {
            path: "/customers/:customerId",
            element: (
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: <Search />,
              },
              {
                path: "dashboard",
                element: 
                <PermissionsRestrictedRoute
                  resource="interactions"
                  requiredPermission="Read"
                  >
                  <Dashboard />
                </PermissionsRestrictedRoute>
              },
              {
                path: "connectors",
                element: <Connectors />,
              },
              {
                path: "users",
                element: <UserList />,
              },
              {
                path: "sites",
                element: <SiteList />,
              },
              {
                path: "roles",
                element: <Roles />,
              },
              {
                path: "audit-logs",
                element: <AuditLogs />,
              },            
            ],
          },
          {
            path: "/customers/admin",
            element: (
              <ProtectedRoute>
                <AdminPortal />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "customization",
        element: <Outlet />,
        children: [
          {
            path: "set-logo",
            element: <SetLogo />,
          },
        ],
      },
    ],
  },
]);

export default router;
