import { z } from "zod";

export const roleSchema = z.object(
  {
    roleId: z.string(),
    customerId: z.string(),
    siteIds: z.array(z.string()),
    name: z.string(),
    permissions: z.array(z.string()),
    allSites: z.boolean(),
    customerScope: z.string(),
  },
  {
    invalid_type_error: "Please specify a role.",
  }
);

type Role = {
  roleId: string;
  customerId: string;
  siteIds?: string[] | null;
  name: string;
  permissions: string[];
  allSites: boolean;
  customerScope: string;
};

export type RoleResponse = {
  role: Role;
};

export default Role;
