import { useEffect, useState } from "react";
import Waveform from "../ButtonsAndControls/VideoAndAudioOnly/Waveform";
import AudioPlayerTimeDisplay, { PlayerTimeState } from "../ButtonsAndControls/VideoAndAudioOnly/TimeDisplay";
import AudioControls from "./AudioControls";
import { getDefaultVolume } from "../MediaDrawer";
import LoadingIndicator from "../../common/LoadingIndicator/LoadingIndicator";

export interface AudioPlayerProps {
  audioPath: string | undefined;
  isPlaying: boolean;
  isLoading: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  closePlayer?: () => void;
  closeWindow?: () => void;
  playerInteractionId: string | undefined;
  handleOpenInNewWindow?: (
    playerInteractionId: string,
    closeWindow?: () => void
  ) => void;
}

const AudioPlayer = ({
  audioPath,
  isPlaying,
  setIsPlaying,
  closePlayer,
  closeWindow,
  playerInteractionId,
  isLoading,
  handleOpenInNewWindow,
}: AudioPlayerProps) => {
  const [timeState, setTimeState] = useState<PlayerTimeState | undefined>();
  const [muted, setMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(getDefaultVolume(50));
  const [isWaveformLoading, setIsWaveformLoading] = useState<boolean>(false);
  const isWaveLoading = isWaveformLoading || isLoading;
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);

  useEffect(() => {
    const reset = () => {
      setIsPlaying(false);
      setIsWaveformLoading(false);
      setFailedToLoad(false);
    };

    reset();
  }, [audioPath, setIsPlaying]);

  if (failedToLoad) {
    return <>Failed to load audio.</>;
  }

  return (
    <div>
      {isWaveLoading && (
        <div className="w-full flex justify-center">
          <LoadingIndicator />
        </div>
      )}
      <Waveform
        audioPath={audioPath}
        setIsLoading={setIsWaveformLoading}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        volume={volume}
        muted={muted}
        setFailedToLoad={setFailedToLoad}
        onTimeChange={setTimeState}
      />
      <div className="flex items-center text-center">
        <AudioPlayerTimeDisplay timeState={timeState} />
      </div>
      <AudioControls
        NewWindowButtonProps={{
          handleOpenInNewWindow,
          playerInteractionId,
        }}
        PlayButtonProps={{
          isPlaying,
          setIsPlaying,
          disabled: isWaveLoading,
        }}
        VolumeControlProps={{
          muted,
          setMuted,
          volume,
          setVolume: setVolume,
        }}
      />
    </div>
  );
};

export default AudioPlayer;
