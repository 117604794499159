import { AxiosError, HttpStatusCode } from "axios";
import ResourceInUseError from "../errors/ResourceInUseError";
import Role, { RoleResponse } from "../types/role/Role";
import { deleteApi, getApi, postApi, putApi } from "./helpers/apiMethods";

const path = "roles";

export interface CreateRoleRequest {
  siteIds: string[];
  allSites: boolean;
  name: string;
  permissions: string[];
}

export const getRole = async (
  customerId?: string,
  roleId?: string
): Promise<Role | undefined> => {
  const response = await getApi<RoleResponse>(
    `/v1/customers/${customerId}/${path}/${roleId}`
  );
  return response.data?.role;
};

export const getAllRoles = async (customerId?: string): Promise<Role[]> => {
  type Response = { roles: Role[] };
  const response = await getApi<Response>(
    `/v1/customers/${customerId}/${path}/`
  );
  return response.data?.roles ?? [];
};

export const createRole = async (
  customerId: string,
  request: CreateRoleRequest
): Promise<Role | undefined> => {
  type Response = { role: Role };
  const response = await postApi<Response>(
    `/v1/customers/${customerId}/${path}`,
    request
  );
  return response.data?.role;
};

export interface EditRoleRequest {
  roleId: string;
  siteIds: string[];
  allSites: boolean;
  name: string;
  permissions: string[];
}

export const editRole = async (
  customerId: string,
  { roleId, ...request }: EditRoleRequest
): Promise<Role | undefined> => {
  type Response = { role: Role };
  const response = await putApi<Response>(
    `/v1/customers/${customerId}/${path}/${roleId}`,
    request
  );
  return response.data?.role;
};

export interface DeleteRoleRequest {
  roleId: string;
}

export const deleteRole = async (
  customerId: string,
  { roleId }: DeleteRoleRequest
): Promise<Role | undefined> => {
  try {
    type Response = { role: Role };
    const response = await deleteApi<Response>(
      `/v1/customers/${customerId}/${path}/${roleId}`
    );
    return response.data?.role;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const statusCode =
        error.status ??
        error.response?.status ??
        HttpStatusCode.InternalServerError;
      if (statusCode === HttpStatusCode.Conflict) {
        throw new ResourceInUseError(
          "This role is in use and cannot be deleted. Remove this role from all users and try again."
        );
      }
    }
    throw error;
  }
};
