import { useEffect, useMemo, useState } from "react";
import {
  FieldValues,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import defaultFormConfig from "../../../helpers/validation/defaultFormConfig";
import ConnectorRegistry from "../controllers/ConnectorRegistry";
import { z } from "zod";
import ConnectorRuleSet from "../models/ConnectorRuleSet";
import FormWizard, { WizardStep } from "../../common/Wizard/FormWizard";
import ConnectorConfigForm, {
  ConnectorFormAction,
} from "./ConnectorConfigForm";
import RuleSetForm from "./RuleSetForm";
import Connector from "../models/Connector";

const FORM_ID = "connector-wizard";

const getSteps = (
  action: ConnectorFormAction,
  onSubmit: SubmitHandler<ConnectorWizardFormValues>,
  form: UseFormReturn<ConnectorWizardFormValues>,
  connector?: Connector<ConnectorWizardFormValues>
): WizardStep<ConnectorWizardFormValues>[] => {
  const connectorFields = connector?.fields ?? [];
  return [
    {
      index: 0,
      title: "Configure Secrets",
      content: (
        <ConnectorConfigForm
          action={action}
          formId={FORM_ID}
          form={form}
          onSubmit={onSubmit}
        />
      ),
      fieldsToValidate: ["type", "intervalSeconds", ...connectorFields],
    },
    {
      index: 1,
      title: "Add Rule Sets",
      content: <RuleSetForm formId={FORM_ID} form={form} onSubmit={onSubmit} />,
      fieldsToValidate: ["ruleSets"],
    },
  ];
};

type CommonConnectorConfigFormValues = {
  type: string;
  intervalSeconds: number;
  ruleSets: ConnectorRuleSet[];
  shouldSaveNoMedia: boolean;
};
export type ConnectorWizardFormValues = CommonConnectorConfigFormValues &
  z.infer<typeof controller.schema>;

const controller = ConnectorRegistry.getInstance();

export interface ConnectorWizardProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: SubmitHandler<ConnectorWizardFormValues>;
  action: ConnectorFormAction;
  selectedConnector?: Partial<ConnectorWizardFormValues> | null;
}

const getDefaultValues = (
  action: ConnectorFormAction,
  connectorRow: Partial<FieldValues> | null,
  connectorType?: string
) => {
  if (action === "Edit") {
    if (!connectorType) {
      return controller.defaultValues;
    }
    const connector = controller.connectorMap[connectorType];
    if (!connectorRow) return controller.defaultValues;
    return {
      ...controller.defaultValues,
      ...connectorRow,
      shouldSaveNoMedia: connectorRow.shouldSaveNoMedia,
      type: connectorRow.type,
      intervalSeconds: connectorRow.intervalSeconds
        ? connectorRow.intervalSeconds
        : controller.defaultValues.intervalSeconds,
      [connectorType]: connector.getEditDefaultValues(connectorRow),
    };
  }
  return controller.defaultValues;
};

const ConnectorWizard = ({
  isOpen,
  setIsOpen,
  onSubmit,
  action,
  selectedConnector = null,
}: ConnectorWizardProps) => {
  const defaultValues = useMemo(() => {
    return getDefaultValues(action, selectedConnector, selectedConnector?.type);
  }, [action, selectedConnector]);
  const form = useForm<ConnectorWizardFormValues>({
    ...defaultFormConfig<ConnectorWizardFormValues>(controller.schema),
    defaultValues,
  });
  const { formState, reset, trigger, watch } = form;
  const { isSubmitting, isLoading } = formState;
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  useEffect(() => {
    if (action === "Edit" || selectedConnector) {
      reset(defaultValues); // Reset with the selected connector's non-sensitive data
    } else {
      reset(); // Clear form for new connector creation
    }
    setActiveStepIndex(0);
  }, [isOpen, action, reset, selectedConnector, defaultValues]);
  

  const connectorType = watch("type");

  const handleFormSubmit = async () => {
    try {
      await onSubmit(form.getValues());
      reset();
      setActiveStepIndex(0);
      selectedConnector = null;
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };
  return (
    <FormWizard
      formId={FORM_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={reset}
      isBackDisabled={isSubmitting || isLoading}
      isNextDisabled={isSubmitting || isLoading}
      validationTrigger={trigger}
      steps={getSteps(
        action,
        handleFormSubmit,
        form,
        controller.connectorMap[connectorType] as
          | Connector<ConnectorWizardFormValues>
          | undefined
      )}
      activeStepIndex={activeStepIndex}
      setActiveStepIndex={setActiveStepIndex}
    />
  );
};

export default ConnectorWizard;
