import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Permissions from "../../types/permission/permission";
import Role from "../../types/role/Role";
import Site from "../../types/site/Site";
import DialogForm from "../common/DialogForm/DialogForm";
import MultiSelect from "../common/MultiSelect/MultiSelect";
import PermissionsInput from "./PermissionsInput";

const FORM_ID = "edit-role-form";

export interface EditRoleValues {
  sites: Site[];
  allSites: boolean;
  name: string;
  permissions: string[];
}

export interface EditRoleFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedRole?: Role;
  permissions?: Permissions;
  sites?: Site[];
  editRole: (formValues: EditRoleValues) => Promise<void>;
  isPermissionsLoading?: boolean;
  isSitesLoading?: boolean;
}

const EditRoleForm = ({
  selectedRole,
  isOpen,
  setIsOpen,
  permissions = new Permissions(),
  sites = [],
  isPermissionsLoading = false,
  isSitesLoading = false,
  editRole,
}: EditRoleFormProps) => {
  const { formState, control, register, watch, handleSubmit, reset } =
    useForm<EditRoleValues>({
      defaultValues: {
        sites: [],
        allSites: false,
        name: "",
        permissions: [],
      },
    });
  const { isDirty, isValid, isSubmitting, defaultValues, errors } = formState;

  const watchAllSites = watch("allSites");

  const initializeForm = useCallback(
    (selectedRole: Role) => {
      reset({
        sites:
          selectedRole.siteIds != null
            ? sites.filter((site: Site) =>
                selectedRole.siteIds!.includes(site.siteId)
              )
            : [],
        allSites: selectedRole.allSites,
        name: selectedRole.name,
        permissions: selectedRole.permissions,
      });
    },
    [reset, sites]
  );

  useEffect(() => {
    if (!isOpen || !selectedRole) return;
    initializeForm(selectedRole);
  }, [selectedRole, isOpen, initializeForm]);

  return (
    <DialogForm
      dialogTitle="Edit Role"
      formId={FORM_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSubmitting={isSubmitting}
      isSubmitDisabled={!isDirty || !isValid}
      isCancelDisabled={isSubmitting}
      afterClose={reset}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(editRole)} noValidate>
        <Stack spacing={2}>
          <FormGroup>
            <TextField
              {...register("name", { required: "Name is required." })}
              label="Name"
              error={!!errors.name}
              fullWidth
              required
            />
            {errors.name && (
              <FormHelperText error>{errors.name.message}</FormHelperText>
            )}
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              label="Grant access to all sites"
              control={
                <Controller
                  name="allSites"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.onChange(event.target.checked)
                      }
                    />
                  )}
                />
              }
            />
            {errors.allSites && (
              <FormHelperText error>{errors.allSites.message}</FormHelperText>
            )}
          </FormGroup>
          {!watchAllSites && (
            <FormGroup>
              <MultiSelect<Site, EditRoleValues>
                name="sites"
                control={control}
                label="Sites"
                options={sites}
                getOptionLabel={(option: Site) => option.name}
                isOptionEqualToValue={(option: Site, value: Site) => {
                  return option.siteId === value.siteId;
                }}
                filterOptions={(options: Site[], { inputValue }: any) => {
                  return options.filter((option: Site) =>
                    option.name.toLowerCase().includes(inputValue.toLowerCase())
                  );
                }}
                size="medium"
                getOptionKey={(option: Site) => option.siteId}
                error={!!errors.sites}
                loading={isSitesLoading}
                fullWidth
                required
              />
              {errors.sites && (
                <FormHelperText error>{errors.sites.message}</FormHelperText>
              )}
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel>Permissions</FormLabel>
            <div className="m-auto w-2/3">
              <Controller
                name="permissions"
                control={control}
                render={({ field }) => {
                  const { ref, value, onChange, ...rest } = field;
                  return (
                    <PermissionsInput<EditRoleValues>
                      {...rest}
                      defaultValue={
                        new Set<string>(defaultValues?.permissions as string[])
                      }
                      value={new Set<string>(value)}
                      onChange={(permissions: Set<string>) => {
                        const permissionsArray = Array.from(permissions);
                        onChange(permissionsArray);
                      }}
                      onBlur={rest.onBlur}
                      isLoading={isPermissionsLoading}
                      permissions={permissions}
                    />
                  );
                }}
                rules={{
                  required: "Permissions are required.",
                  minLength: { value: 1, message: "Permissions are required." },
                }}
              />
            </div>
          </FormGroup>
        </Stack>
      </form>
    </DialogForm>
  );
};

export default EditRoleForm;
