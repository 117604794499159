import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

export interface ToggleShowPasswordProps {
  showPassword: boolean;
  toggleShowPassword: () => void;
}

const ToggleShowPassword = ({
  showPassword,
  toggleShowPassword,
}: ToggleShowPasswordProps) => {
  return (
    <InputAdornment position="end">
      <IconButton
        onClick={toggleShowPassword}
        onMouseDown={(event) => event.preventDefault()}
        aria-label={showPassword ? "Hide password" : "Show password"}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

export default ToggleShowPassword;
