import SSOProvider from "../types/auth/SSOProvider";

export interface Config {
  ApiUrl: string;
  CognitoUserPoolId: string;
  CognitoAppClientId: string;
  CognitoDomain: string;
  CognitoCallback: string;
  CognitoLogoutUrl: string;
  SSOProvider?: SSOProvider;
  RetentionDateWarningWindowDays?: number;
}

export const loadConfig = async (): Promise<Config> => {
  try {
    return await loadConfigFromJson();
  } catch (error: unknown) {
    console.info(
      "Failed to load config from config.json, using environment variables."
    );
    return configFromEnv;
  }
};

const retentionDateWarningWindowDays: string | undefined | null =
  process.env.REACT_APP_RETENTION_DATE_WARNING_WINDOW_DAYS;

const configFromEnv: Config = {
  ApiUrl: process.env.REACT_APP_API_URL ?? "",
  CognitoUserPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
  CognitoAppClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? "",
  CognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN ?? "",
  CognitoCallback: process.env.REACT_APP_COGNITO_CALLBACK ?? "",
  CognitoLogoutUrl: process.env.REACT_APP_COGNITO_LOGOUT_URL ?? "",
  SSOProvider: process.env.REACT_APP_SSO_PROVIDERS as SSOProvider | undefined,
  RetentionDateWarningWindowDays: retentionDateWarningWindowDays
    ? parseInt(retentionDateWarningWindowDays)
    : undefined,
};

const normalizeCognitoCallback = (config: Config) => {
  const regex = /^(http|https):\/\//i;
  config.CognitoCallback.replace(regex, "");
  if (!config.CognitoCallback.endsWith("/")) {
    config.CognitoCallback += "/";
  }
};

const splitSsoProviders = (rawConfig: Config): Config => {
  return {
    ...rawConfig,
    SSOProvider: rawConfig.SSOProvider as SSOProvider,
  };
};

const parseConfig = (dirtyConfig: Config): Config => {
  const config = splitSsoProviders(dirtyConfig);
  normalizeCognitoCallback(config);
  return config;
};

const loadConfigFromJson = async (): Promise<Config> => {
  const configFile = await fetch(`${window.location.origin}/config.json`);
  return parseConfig(await configFile.json());
};
