import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { GridColumnMenuItemProps } from "@mui/x-data-grid";
import { useContext, useRef } from "react";
import FieldLabelContext from "../context/FieldLabelContext";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAlert } from "../../../context/AlertContext";

const ResetAllFieldLabelsMenuItem = (props: GridColumnMenuItemProps) => {
  const menuItemRef = useRef<HTMLLIElement>(null);
  const showAlert = useAlert();
  const { resetAllFieldLabels } = useContext(FieldLabelContext);

  const resetFieldLabels = () => {
    resetAllFieldLabels();
    showAlert("Field labels reset to default.", "success");
  };

  return (
    <MenuItem {...props} ref={menuItemRef} onClick={resetFieldLabels}>
      <ListItemIcon>
        <ReplayIcon />
      </ListItemIcon>
      <ListItemText>Reset All Field Labels</ListItemText>
    </MenuItem>
  );
};

export default ResetAllFieldLabelsMenuItem;
