import Button, { ButtonProps } from "@mui/material/Button";
import AuthorizedWrapper, {
  AuthorizedComponentProps,
} from "../AuthorizedWrapper/AuthorizedWrapper";

export type AuthorizedButtonProps = AuthorizedComponentProps & ButtonProps;

const AuthorizedButton = ({
  children,
  permission,
  unauthorizedAction = "hide",
  ...props
}: AuthorizedButtonProps) => (
  <AuthorizedWrapper
    permission={permission}
    unauthorizedAction={unauthorizedAction}
    Component={Button}
    ComponentProps={props}
  >
    {children}
  </AuthorizedWrapper>
);

export default AuthorizedButton;
