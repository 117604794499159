import { useCallback } from "react";
import { useForm } from "react-hook-form";
import DialogForm from "../../../components/common/DialogForm/DialogForm";
import { useAlert } from "../../../context/AlertContext";
import OpenableProps from "../../../helpers/openableProps";
import defaultFormConfig from "../../../helpers/validation/defaultFormConfig";
import { useCreateCustomer } from "../../../hooks/useAdmin";
import CreateCustomerRequest from "../../../types/admin/CreateCustomerRequest";
import Customer from "../../../types/customer/customer";
import ErrorMessage from "../../../types/feedback/ErrorMessage";
import CreateCustomerForm from "./CreateCustomerForm";
import createCustomerSchema from "./schema";

const FORM_ID = "create-customer-form";

const DEFAULT_VALUES: CreateCustomerRequest = {
  name: "",
};

export interface CreateCustomerDialogProps extends OpenableProps {
  onSuccess?: (response: Customer | undefined) => void | Promise<void>;
}

const CreateCustomerDialog = ({
  isOpen,
  setIsOpen,
  onSuccess,
}: CreateCustomerDialogProps) => {
  const form = useForm({
    ...defaultFormConfig<CreateCustomerRequest>(createCustomerSchema),
    defaultValues: DEFAULT_VALUES,
  });
  const { reset, formState } = form;
  const { isDirty, isLoading, isSubmitting } = formState;
  const { mutateAsync: createCustomerAsync } = useCreateCustomer();
  const showAlert = useAlert();

  const createCustomer = async (request: CreateCustomerRequest) => {
    try {
      const response = await createCustomerAsync(request);
      onSuccess?.(response);
      setIsOpen(false);
      showAlert("Customer created successfully", "success");
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  const resetForm = useCallback(() => {
    reset(DEFAULT_VALUES);
  }, [reset]);

  return (
    <DialogForm
      formId={FORM_ID}
      dialogTitle="Create Customer"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSubmitting={false}
      submitText="Create"
      isCancelDisabled={isSubmitting}
      isSubmitDisabled={isSubmitting || !isDirty || isLoading}
      afterClose={resetForm}
    >
      <CreateCustomerForm id={FORM_ID} form={form} onSubmit={createCustomer} />
    </DialogForm>
  );
};

export default CreateCustomerDialog;
