import { UseFormReturn } from "react-hook-form";
import ConnectorRuleSet from "../models/ConnectorRuleSet";
import { useSites } from "../../../hooks/useSite";
import { useParams } from "react-router-dom";
import { ConnectorWizardFormValues } from "../forms/ConnectorWizard";
import RuleSetListControlView from "./RuleSetListControlView";

export interface ConnectorRuleSetField extends ConnectorRuleSet {
  id: string;
}

export interface ConnectorRuleSetControlProps {
  form: UseFormReturn<ConnectorWizardFormValues>;
  ruleSetFields: ConnectorRuleSetField[];
  defaultSiteId?: string;
}

const ConnectorRuleSetControl = ({
  form,
  ruleSetFields,
  defaultSiteId,
}: ConnectorRuleSetControlProps) => {
  const { customerId } = useParams();
  const { data, error, isLoading } = useSites(customerId);

  return (
    <RuleSetListControlView
      ruleSets={ruleSetFields}
      form={form}
      sites={data?.sites ?? []}
      sitesQueryError={error}
      areSitesLoading={isLoading}
      defaultSiteId={defaultSiteId}
    />
  );
};

export default ConnectorRuleSetControl;
