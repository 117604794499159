import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { HTMLAttributes } from "react";
import { UseFormReturn } from "react-hook-form";
import CreateCustomerRequest from "../../../types/admin/CreateCustomerRequest";

export interface CreateCustomerFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit"> {
  form: UseFormReturn<CreateCustomerRequest>;
  onSubmit: (request: CreateCustomerRequest) => void | Promise<void>;
}

const CreateCustomerForm = ({
  form,
  onSubmit,
  ...props
}: CreateCustomerFormProps) => {
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  return (
    <form {...props} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <TextField
          label="Name"
          required
          error={!!errors.name}
          {...register("name")}
        />
        {errors.name && (
          <FormHelperText error>{errors.name.message}</FormHelperText>
        )}
      </FormGroup>
    </form>
  );
};

export default CreateCustomerForm;
