import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Controller, useFormContext } from "react-hook-form";
import Connector from "../models/Connector";
import ConnectorRegistry from "../controllers/ConnectorRegistry";
import IntervalPicker from "../../common/IntervalPicker/IntervalPicker";
import { ConnectorWizardFormValues } from "./ConnectorWizard";
import { ConnectorFormAction } from "./ConnectorConfigForm";

const controller = ConnectorRegistry.getInstance();

export interface ConnectorConfigFormViewProps {
  action: ConnectorFormAction;
}

const ConnectorConfigFormView = ({
  action = "Create",
}: ConnectorConfigFormViewProps) => {
  const { control, register, watch, getValues, setValue, formState } =
    useFormContext<ConnectorWizardFormValues>();
  const { defaultValues, errors } = formState;

  const watchConnectorType = watch("type");

  return (
    <Stack spacing={2}>
      <FormGroup>
        <FormControl>
          <InputLabel id="type-label" required>
            Type
          </InputLabel>
          <Controller
            name="type"
            control={control}
            render={({ field }) => {
              const { ref, ...rest } = field;
              return (
                <Select
                  {...rest}
                  data-testid="connector-type"
                  onChange={(event: SelectChangeEvent<unknown>) => {
                    const prevValue = field.value;
                    const newValue = event.target.value as string;
                    field.onChange(event);
                    setValue(prevValue, undefined);
                    if (action === "Edit") {
                      setValue(
                        newValue,
                        controller.connectorMap[newValue].getEditDefaultValues(
                          getValues()
                        )
                      );
                      return;
                    }
                    setValue(
                      newValue,
                      controller.connectorMap[newValue].defaultValues
                    );
                  }}
                  labelId="type-label"
                  label="Type"
                  defaultValue={defaultValues?.type ?? ""}
                  error={!!errors.type}
                  disabled={action === "Edit"}
                  required
                >
                  {Object.values(controller.connectorMap).map(
                    (connector: Connector) => (
                      <MenuItem key={connector.type} value={connector.type}>
                        {connector.type}
                      </MenuItem>
                    )
                  )}
                </Select>
              );
            }}
          />
        </FormControl>
        {errors.type && (
          <FormHelperText error>{errors.type.message as string}</FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel hidden>How often should the connector run?</FormLabel>
        <Controller
          name="intervalSeconds"
          control={control}
          render={({ field }) => {
            const { ref, ...rest } = field;
            return (
              <IntervalPicker
                {...rest}
                label="Run Interval"
                units={["day", "week"]}
                defaultUnit="day"
                setValue={field.onChange}
                className="hidden"
              />
            );
          }}
        />
      </FormGroup>
      {!!watchConnectorType &&
        controller.connectorMap[watchConnectorType]?.renderFormFields(
          register,
          control,
          errors
        )}
    </Stack>
  );
};

export default ConnectorConfigFormView;
