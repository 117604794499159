import {
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useFieldArray,
} from "react-hook-form";
import ConnectorRuleSetControl from "../input/RuleSetListControl";
import { ConnectorWizardFormValues } from "./ConnectorWizard";

export interface RuleSetFormProps {
  formId: string;
  form: UseFormReturn<ConnectorWizardFormValues>;
  onSubmit: SubmitHandler<ConnectorWizardFormValues>;
}

// TODO: Attribute mapping rules
const RuleSetForm = ({ formId, form, onSubmit }: RuleSetFormProps) => {
  const { handleSubmit, control } = form;
  const { fields } = useFieldArray({
    name: "ruleSets",
    control,
  });

  return (
    <FormProvider {...form}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
        <ConnectorRuleSetControl form={form} ruleSetFields={fields} />
      </form>
    </FormProvider>
  );
};

export default RuleSetForm;
