import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { GridColumnMenuItemProps } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useRef, useState } from "react";
import RenameField from "./input/RenameField";

const RenameFieldMenuItem = (props: GridColumnMenuItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuItemRef = useRef<HTMLLIElement>(null);

  const { colDef } = props;

  const openRenamePopover = () => {
    setIsOpen(true);
  };

  return (
    <>
      <RenameField
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        anchorEl={menuItemRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        field={colDef.field}
      />
      <MenuItem ref={menuItemRef} onClick={openRenamePopover}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
    </>
  );
};

export default RenameFieldMenuItem;
