import { Box, Typography } from "@mui/material";
import NewWindowButton from "../ButtonsAndControls/NewWindowButton";
import { useChatTranscript } from "../../../hooks/useInteraction";

interface ChatDisplayProps {
  fileUrl: string | undefined;
  agentId: string | undefined;
  closePlayer: () => void;
  closeWindow?: () => void;
  playerInteractionId: string | undefined;
  handleOpenInNewWindow?: (
    playerInteractionId: string,
    closeWindow?: () => void
  ) => void;
}

const ChatDisplay = ({ fileUrl, agentId, closePlayer, closeWindow, playerInteractionId, handleOpenInNewWindow }: ChatDisplayProps) => {
  const { data: transcript = [], isLoading, isError, error } = useChatTranscript(fileUrl);

  if (isLoading) return <div>Loading transcript...</div>;
  if (isError) {
    console.error(`Error fetching transcript: ${error.message}`);
    return <div>Error fetching transcript. Please try again or contact support.</div>;
  }


  return (
    <Box sx={{ height: "100%", overflowY: "auto", marginBottom: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <NewWindowButton
          playerInteractionId={playerInteractionId}
          handleOpenInNewWindow={handleOpenInNewWindow}
        />
      </Box>
      <Box padding={2}>
        {transcript.map((message) => {
          const isAgent = message.participantId === agentId;
          const setAlignment = isAgent ? "right" : "left";
          return (
            <Box
              key={message.id}
              marginBottom={2}
              padding={1}
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align={setAlignment}
              >
                {message.displayName} ({message.participantRole})
              </Typography>
              <Box
                padding={2}
                borderRadius={1}
                bgcolor={isAgent ? "#e0f7fa" : "#f5f5f5"}
                alignSelf={isAgent ? "flex-end" : "flex-start"}
                flexDirection="column"
              >
                {message.type === "MESSAGE" ? (
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align={setAlignment}
                  >
                    {message.content}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align={setAlignment}
                  >
                    {message.contentType.includes("joined")
                      ? `${message.displayName} has joined the chat`
                      : `${message.displayName} has left the chat`}
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align={setAlignment}
                >
                  {new Date(message.absoluteTime).toLocaleString()}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ChatDisplay;
