import { Amplify } from "aws-amplify";
import { loadConfig } from "../configuration/config";
import SSOProvider from "../types/auth/SSOProvider";

export const configureAmplify = async () => {
  const config = await loadConfig();
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.CognitoUserPoolId,
        userPoolClientId: config.CognitoAppClientId,
        loginWith: {
          oauth: {
            domain: config.CognitoDomain,
            redirectSignIn: [config.CognitoCallback],
            redirectSignOut: [config.CognitoLogoutUrl],
            responseType: "code",
            scopes: ["openid"],
            providers: [
              {
                custom: SSOProvider.Ping,
              },
            ],
          },
        },
      },
    },
  });
};
