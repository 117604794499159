import {
  Alert,
  Button,
  Card,
  CardContent,
  Divider,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAlert } from "../../context/AlertContext";
import { AuthContext } from "../../context/AuthContext";
import { ConfigContext } from "../../context/ConfigContext";
import defaultFormConfig from "../../helpers/validation/defaultFormConfig";
import { type LoginRequest } from "../../services/AuthService";
import ToggleShowPassword from "../common/ToggleShowPassword/ToggleShowPassword";
import SSOButton from "./SSOButton/SSOButton";

const schema = z.object({
  email: z.string().trim().min(1, {
    message: "Email is required.",
  }),
  password: z.string().trim().min(1, {
    message: "Password is required.",
  }),
});

export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const form = useForm<LoginRequest>({
    ...defaultFormConfig<LoginRequest>(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const showAlert = useAlert();

  const config = useContext(ConfigContext);
  const { login } = useContext(AuthContext);

  const { register, handleSubmit, formState } = form;
  const { isDirty, isSubmitting, errors, isValid, isLoading } = formState;

  const onSubmit = async (formData: LoginRequest) => {
    try {
      await login(formData);
    } catch (error: unknown) {
      console.error(error);
      if (!(error instanceof Error)) return;
      if (error.name === "UserNotFoundException") {
        showAlert("Incorrect username or password.", "error");
        return;
      }
      showAlert(error.message, "error");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Card sx={{ minWidth: 400 }}>
      <CardContent>
        <h1>Login</h1>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={2}>
            <FormGroup>
              <TextField
                label="Email"
                type="email"
                {...register("email", { required: "Email is required." })}
                error={!!errors.email}
                required
              />
              {!!errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
            </FormGroup>
            <FormGroup>
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                {...register("password", { required: "Password is required." })}
                InputProps={{
                  endAdornment: (
                    <ToggleShowPassword
                      showPassword={showPassword}
                      toggleShowPassword={toggleShowPassword}
                    />
                  ),
                }}
                error={!!errors.password}
                autoComplete="current-password"
                required
              />
              {!!errors.password && (
                <FormHelperText error>{errors.password.message}</FormHelperText>
              )}
            </FormGroup>
            {!!errors.root?.generic && (
              <Alert severity="error">{errors.root?.generic?.message}</Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || isSubmitting || !isValid || isLoading}
            >
              Login
            </Button>
            {!!config?.SSOProvider && (
              <>
                <Divider>
                  <Typography color="gray" variant="subtitle2">
                    OR
                  </Typography>
                </Divider>
                <SSOButton
                  provider={config.SSOProvider}
                  disabled={isSubmitting}
                />
              </>
            )}
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};
