import {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import FieldLabelContext, {
  FieldLabel,
  IFieldLabelContext,
} from "./FieldLabelContext";
import FieldLabelStorageContext from "./FieldLabelStorageContext";

const mergeFieldLabels = (
  fieldLabelMapping: Record<string, string | undefined>,
  storedFieldLabels: Map<string, FieldLabel> | undefined
) => {
  const fieldLabels = new Map();
  for (const fieldName in fieldLabelMapping) {
    const fieldLabel = fieldLabelMapping[fieldName];
    const defaultName = fieldLabel ?? fieldName;
    const name = storedFieldLabels?.get(fieldName)?.name ?? defaultName;
    fieldLabels.set(fieldName, { defaultName, name });
  }
  for (const fieldName in storedFieldLabels) {
    const fieldLabel = storedFieldLabels?.get(fieldName);
    if (fieldLabel) {
      fieldLabels.set(fieldName, fieldLabel);
    }
  }
  return fieldLabels;
};

export interface FieldLabelProviderProps extends PropsWithChildren {
  labelMapping: Record<string, string | undefined>;
  tableId: string;
}

const FieldLabelProvider = ({
  children,
  labelMapping,
  tableId,
}: FieldLabelProviderProps) => {
  const { saveFieldLabelsToTable, getFieldLabelsByTable } = useContext(
    FieldLabelStorageContext
  );
  const [fieldLabels, setFieldLabels] = useState<Map<string, FieldLabel>>(() =>
    mergeFieldLabels(labelMapping, getFieldLabelsByTable(tableId))
  );

  const getFieldLabel = useCallback(
    (field: string) => {
      return fieldLabels.get(field)?.name ?? field;
    },
    [fieldLabels]
  );

  const setFieldLabel = useCallback(
    (field: string, fieldLabel: string) => {
      const defaultName = fieldLabels.get(field)?.defaultName;
      return setFieldLabels((prevFieldLabels) => {
        const newFieldLabels = prevFieldLabels.set(field, {
          defaultName: defaultName ?? field,
          name: fieldLabel,
        });
        const fieldLabels = new Map(newFieldLabels);
        saveFieldLabelsToTable(tableId, fieldLabels);
        return fieldLabels;
      });
    },
    [fieldLabels, saveFieldLabelsToTable, tableId]
  );

  const resetFieldLabel = useCallback(
    (field: string) => {
      const fieldLabel = fieldLabels.get(field);
      if (!fieldLabel) {
        return;
      }
      return setFieldLabels((prevFieldLabels) => {
        const fieldLabels = new Map(
          prevFieldLabels.set(field, {
            ...fieldLabel,
            name: fieldLabel?.defaultName ?? field,
          })
        );
        saveFieldLabelsToTable(tableId, fieldLabels);
        return fieldLabels;
      });
    },
    [fieldLabels, saveFieldLabelsToTable, tableId]
  );

  const resetAllFieldLabels = useCallback(() => {
    setFieldLabels((prevFieldLabels) => {
      const fieldLabels = new Map();
      for (const [field, fieldLabel] of prevFieldLabels) {
        fieldLabels.set(field, {
          ...fieldLabel,
          name: fieldLabel.defaultName,
        });
      }
      saveFieldLabelsToTable(tableId, fieldLabels);
      return fieldLabels;
    });
  }, [saveFieldLabelsToTable, tableId]);

  const contextValue = useMemo<IFieldLabelContext>(
    () => ({
      getFieldLabel,
      setFieldLabel,
      resetFieldLabel,
      resetAllFieldLabels,
    }),
    [getFieldLabel, resetFieldLabel, setFieldLabel, resetAllFieldLabels]
  );

  return (
    <FieldLabelContext.Provider value={contextValue}>
      {children}
    </FieldLabelContext.Provider>
  );
};

export default FieldLabelProvider;
