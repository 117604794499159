import { UpdateInteractionRequest } from "../../../api/interaction";
import { useUpdateInteraction } from "../../../hooks/useInteraction";
import ConfirmDialog from "../../../components/common/ConfirmDialog/ConfirmDialog";
import {
  MutableRefObject,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useAlert } from "../../../context/AlertContext";
import { ConfigContext } from "../../../context/ConfigContext";
import dayjs from "dayjs";

export interface ConfirmRetentionDateDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  request?: UpdateInteractionRequest;
  previousRetentionDate?: number | null;
  apiRef: MutableRefObject<GridApiCommunity>;
}

enum RetentionStatus {
  WARN,
  EMPTY,
  CONFIRM,
  SKIP,
}

const ConfirmRetentionDateDialog = ({
  isOpen,
  setIsOpen,
  request,
  previousRetentionDate: previousRetentionDateUnix,
  apiRef,
}: ConfirmRetentionDateDialogProps) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const { mutateAsync: updateInteraction } = useUpdateInteraction();
  const showAlert = useAlert();
  const config = useContext(ConfigContext);
  const retentionDateUnix = request?.newRetentionDate;
  const interactionId = request?.interactionId;

  const noChangeOccurred = useMemo((): boolean => {
    if (!retentionDateUnix && !previousRetentionDateUnix) {
      return true;
    }
    return retentionDateUnix === previousRetentionDateUnix;
  }, [retentionDateUnix, previousRetentionDateUnix]);

  const retentionStatus = useMemo((): RetentionStatus => {
    if (noChangeOccurred) {
      return RetentionStatus.SKIP;
    }
    if (!retentionDateUnix) {
      return RetentionStatus.EMPTY;
    }
    const retentionDate = dayjs.unix(retentionDateUnix);
    if (!retentionDate.isValid()) {
      return RetentionStatus.EMPTY;
    }
    const warningWindowDays = config?.RetentionDateWarningWindowDays;
    if (!warningWindowDays) {
      return RetentionStatus.WARN;
    }
    const now = dayjs();
    const differenceInDays = retentionDate.diff(now, "days");
    if (differenceInDays >= warningWindowDays) {
      return RetentionStatus.CONFIRM;
    }
    return RetentionStatus.WARN;
  }, [
    retentionDateUnix,
    config?.RetentionDateWarningWindowDays,
    noChangeOccurred,
  ]);

  const update = useCallback(async () => {
    try {
      const interaction = await updateInteraction(request!);
      if (!interaction) {
        throw new Error("Failed to update interaction.");
      }
      apiRef.current.updateRows([
        {
          interactionId,
          legalHold: interaction.legalHold,
          retentionDate: interaction.retentionDate,
          retentionDateHold: interaction.retentionDateHold,
        },
      ]);
      showAlert("Interaction updated successfully.", "success");
      setIsOpen(false);
    } catch (error: unknown) {
      console.error("Error while updating interaction:", error);
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert("An error occurred while updating interaction.", "error");
      }
    }
  }, [apiRef, interactionId, request, setIsOpen, showAlert, updateInteraction]);

  const confirmUpdate = () => {
    setConfirmed(true);
  };

  const cancelRetentionDateUpdate = async () => {
    showAlert("Retention date update cancelled.", "info");
    if (!!request?.newLegalHold) {
      request.newRetentionDate = undefined;
      await update();
    }
  };

  useEffect(() => {
    if (!confirmed) {
      return;
    }
    update();
    setIsOpen(false);
    setConfirmed(false);
  }, [confirmed, update, setIsOpen]);

  useEffect(() => {
    if (!interactionId) return;
    if (retentionStatus === RetentionStatus.CONFIRM) {
      confirmUpdate();
    }
  }, [interactionId, setIsOpen, retentionStatus]);

  if (
    !interactionId ||
    [RetentionStatus.CONFIRM, RetentionStatus.SKIP].includes(retentionStatus)
  ) {
    return null;
  }

  const retentionDate = new Date(retentionDateUnix! * 1_000);

  return (
    <ConfirmDialog
      title="Update retention date"
      action={
        retentionStatus === RetentionStatus.EMPTY
          ? "remove the retention date"
          : `update retention date to ${retentionDate.toLocaleString()}`
      }
      onConfirm={confirmUpdate}
      onCancel={cancelRetentionDateUpdate}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

export default ConfirmRetentionDateDialog;
