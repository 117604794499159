import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

declare module "dayjs" {
  interface Dayjs {
    toISODateString(): string;
    toISOEndDateString(): string;
  }
}

export const dayjsExtensionMethods = (_: unknown, dayjsClass: typeof Dayjs) => {
  dayjsClass.prototype.toISODateString = function () {
    return this.utc()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
      .toISOString();
  };

  dayjsClass.prototype.toISOEndDateString = function () {
    return this.utc()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .set("millisecond", 999)
      .toISOString();
  };
};
