import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage440 = () => {
  const navigate = useNavigate();
  return (
    <div id="error-page">
      <Typography>440 Error</Typography>
      <Typography>Session Timed Out</Typography>
      <Button variant="contained" onClick={() => {navigate("/login")}}>Return to login page</Button>
    </div>
  );
}

export default ErrorPage440;