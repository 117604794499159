import { postApi, putApi } from "./helpers/apiMethods";

const path = "customization";

export interface GetLogoUploadUrlRequest {
  filename: string;
  fileExtension: string;
  fileSizeBytes: number;
  contentType: string;
}

export interface LogoUploadUrlResponse {
  uploadUrl: string;
  filePath: string;
  fileUrl: string;
}

export interface UpdateLogoRequest {
  customerId?: string;
  fileUrl: string;
}

export interface UpdateLogoResponse {
  fileUrl: string;
}

export const getLogoUploadUrl = async (
  customerId?: string,
  request?: GetLogoUploadUrlRequest | null
): Promise<LogoUploadUrlResponse> => {
  if (!customerId || !request) {
    throw new Error("Invalid request.");
  }
  const response = await postApi(`/v1/customers/${customerId}/${path}/logo`, {
    contentType: request.contentType,
    fileExtension: request.fileExtension,
    fileSizeBytes: request.fileSizeBytes,
    filename: request.filename,
  });
  return response.data as LogoUploadUrlResponse;
};

export const updateLogo = async (
  request: UpdateLogoRequest
): Promise<UpdateLogoResponse> => {
  const response = await putApi(`/v1/customers/${request.customerId}/${path}`, {
    fileUrl: request.fileUrl,
  });
  return response.data as UpdateLogoResponse;
};
