import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, UseFormProps } from "react-hook-form";
import { z } from "zod";

const defaultFormConfig = <TFormValues extends FieldValues = FieldValues>(
  schema?: z.Schema
): UseFormProps<TFormValues> => ({
  resolver: schema ? zodResolver(schema) : undefined,
  mode: "onTouched",
  reValidateMode: "onChange",
});

export default defaultFormConfig;
