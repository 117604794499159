import { RefinementCtx, z } from "zod";

export const MIN_LENGTH = 16;
export const MIN_LOWERCASE = 1;
export const MIN_UPPERCASE = 1;
export const MIN_NUMBERS = 1;
export const MIN_SPECIAL_CHARACTERS = 1;

export const hasLowerCase = (str: string) => {
  const regex = /[a-z]/g;
  return (str.match(regex) ?? []).length >= MIN_LOWERCASE;
};
export const hasUpperCase = (str: string) => {
  const regex = /[A-Z]/g;
  return (str.match(regex) ?? []).length >= MIN_UPPERCASE;
};
export const hasNumbers = (str: string) => {
  const regex = /\d/g;
  return (str.match(regex) ?? []).length >= MIN_NUMBERS;
};
export const hasSpecialCharacters = (str: string) => {
  const regex = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/g;
  return (str.match(regex) ?? []).length >= MIN_SPECIAL_CHARACTERS;
};

const passwordSchema = (
  name: string
): z.ZodEffects<z.ZodString, string, string> =>
  z
    .string({ required_error: `${name} is required.` })
    .trim()
    .min(MIN_LENGTH, {
      message: `Password must be at least ${MIN_LENGTH} characters.`,
    })
    .superRefine((password: string, ctx: RefinementCtx) => {
      if (!hasLowerCase(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Password must contain at least ${MIN_LOWERCASE} lowercase letter(s).`,
        });
      }
      if (!hasUpperCase(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Password must contain at least ${MIN_UPPERCASE} uppercase letter(s).`,
        });
      }
      if (!hasNumbers(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Password must contain at least ${MIN_NUMBERS} number(s).`,
        });
      }
      if (!hasSpecialCharacters(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Password must contain at least ${MIN_SPECIAL_CHARACTERS} special character(s).`,
        });
      }
    });

export default passwordSchema;
