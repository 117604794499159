import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import SetPasswordForm from "../../components/auth/SetPasswordForm";

const SetPassword = () => {
  const { state } = useLocation();

  return (
    <Box display="flex" justifyContent="center">
      <SetPasswordForm action="Set" {...state} />
    </Box>
  );
};

export default SetPassword;
