import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover, { PopoverProps } from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useContext, useState } from "react";
import FieldLabelContext from "../../context/FieldLabelContext";
import { useAlert } from "../../../../context/AlertContext";

export interface RenameFieldProps
  extends Omit<PopoverProps, "open" | "onClose" | "children"> {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  field: string;
}

const RenameField = ({
  isOpen,
  setIsOpen,
  field,
  ...props
}: RenameFieldProps) => {
  const { getFieldLabel, setFieldLabel } = useContext(FieldLabelContext);
  const [newFieldLabel, setNewFieldLabel] = useState<string>(
    getFieldLabel(field)
  );
  const [error, setError] = useState<boolean>(false);
  const showAlert = useAlert();

  const close = () => {
    setIsOpen(false);
    setNewFieldLabel(getFieldLabel(field));
    setError(false);
  };

  const saveFieldLabel = () => {
    if (newFieldLabel === "") {
      const errorMessage = "Field label cannot be empty.";
      showAlert(errorMessage, "warning");
      setError(true);
      return;
    }
    setFieldLabel(field, newFieldLabel);
    close();
  };

  return (
    <Popover open={isOpen} onClose={close} {...props}>
      <Box className="flex p-2 items-center">
        <TextField
          label="Field Label"
          value={newFieldLabel}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setNewFieldLabel(event.target.value);
            if (error) {
              setError(false);
            }
          }}
          error={error}
        />
        <div className="h-min flex flex-row pl-2 space-x-1">
          <IconButton onClick={saveFieldLabel}>
            <CheckIcon color="success" />
          </IconButton>
          <IconButton onClick={close}>
            <CancelIcon color="error" />
          </IconButton>
        </div>
      </Box>
    </Popover>
  );
};

export default RenameField;
