import React from "react";
import { Box } from "@mui/material";
import {
  InteractionType,
} from "../../types/interaction/Interaction";
import AudioPlayer from "./Audio/AudioPlayer";
import MediaPlayer from "./Video/VideoPlayer";
import ChatDisplay from "./Chat/ChatDisplay";
import EmptyDisplay from "./EmptyDisplay";

interface MediaDisplaySelectorProps {
  interactionType: InteractionType;
  playerInteractionId: string | undefined;
  data: any;
  agentId: string | undefined;
  isLoading: boolean;
  isPlaying: boolean;
  setIsPlaying: (playing: boolean) => void;
  closePlayer?: () => void;
  closeWindow?: () => void;
  mediaDrawerWidth?: number;
  handleOpenInNewWindow?: (
    playerInteractionId: string,
    closeWindow?: () => void
  ) => void;
}


const MediaDisplaySelector: React.FC<MediaDisplaySelectorProps> = ({
  interactionType,
  playerInteractionId,
  data,
  agentId,
  isLoading,
  isPlaying,
  setIsPlaying,
  closePlayer,
  closeWindow,
  mediaDrawerWidth,
  handleOpenInNewWindow,
}) => {

  const MediaDisplay: Record<InteractionType, React.ComponentType<any>> = {
    [InteractionType.Video]: MediaPlayer,
    [InteractionType.AudioAndVideo]: MediaPlayer,
    [InteractionType.Audio]: AudioPlayer,
    [InteractionType.Chat]: ChatDisplay,
    [InteractionType.Email]: EmptyDisplay,
  };

  const SelectedMediaDisplay = MediaDisplay[interactionType];

  const mediaProps = (() => {
    switch (interactionType) {
      case InteractionType.Audio:
        return {
          audioPath: data?.temporaryUrl,
          isPlaying,
          setIsPlaying,
          isLoading,
          playerInteractionId,
          closePlayer: closePlayer,
          closeWindow: closeWindow,
          handleOpenInNewWindow,
        };
      case InteractionType.Chat:
        return {
          fileUrl: data?.temporaryUrl,
          agentId,
          closePlayer: closePlayer,
          closeWindow: closeWindow,
          playerInteractionId,
          handleOpenInNewWindow,
        };
      case InteractionType.AudioAndVideo:
      case InteractionType.Video:
        return {
          mediaUrl: data?.temporaryUrl,
          playerInteractionId,
          isUrlLoading: isLoading,
          closePlayer: closePlayer,
          closeWindow: closeWindow,
          isPlaying,
          setIsPlaying,
          mediaDrawerWidth,
          handleOpenInNewWindow,
        };
      default:
        return {};
    }
  })();

  return (
    <Box>
      {SelectedMediaDisplay && <SelectedMediaDisplay {...mediaProps} />}
    </Box>
  );
};

export default MediaDisplaySelector;
