import Grid from "@mui/material/Unstable_Grid2";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { useAlert } from "../../context/AlertContext";
import { AuthContext } from "../../context/AuthContext";
import { useAllCustomers } from "../../hooks/useCustomer";
import type Customer from "../../types/customer/customer";
import AdminPortalCustomer from "./AdminPortalCustomer";

export interface AdminPortalCustomersProps {
  customers: Customer[];
  setCustomers: Dispatch<SetStateAction<Customer[]>>;
}

const AdminPortalCustomers = ({
  customers,
  setCustomers,
}: AdminPortalCustomersProps) => {
  const [userCustomer, setUserCustomer] = useState<Customer | null>(null);
  const { user } = useContext(AuthContext);
  const { data, error, isLoading } = useAllCustomers();
  const showAlert = useAlert();

  useEffect(() => {
    if (!data) return;
    setCustomers(
      data.filter((customer: Customer) => {
        if (customer.customerId !== user?.customerId) {
          return true;
        }
        setUserCustomer(customer);
        return false;
      })
    );
  }, [data, user?.customerId, setCustomers]);

  useEffect(() => {
    if (!error) return;
    console.error("Failed to fetch customers", error);
    showAlert(`Failed to fetch customers: ${error?.message}`, "error");
  }, [error, showAlert]);

  return (
    <Grid spacing={2} container>
      {isLoading && (
        <div className="flex w-full h-full items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
      {userCustomer && (
        <Grid xs={12} md={6} key={userCustomer.customerId}>
          <AdminPortalCustomer customer={userCustomer} userCustomer />
        </Grid>
      )}
      {customers?.map((customer: Customer) => (
        <Grid xs={12} md={6} key={customer.customerId}>
          <AdminPortalCustomer customer={customer} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminPortalCustomers;
