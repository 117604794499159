export enum InteractionType {
  Audio = "Audio",
  Chat = "Chat",
  Email = "Email",
  Video = "Video",
  AudioAndVideo = "AudioAndVideo",
}

export type InteractionDirection = "in" | "out";

export const normalizeMediaType = (input: string): InteractionType | null => {
  switch (input) {
    case "Audio":
    case "0":
      return InteractionType.Audio;
    case "Chat":
    case "1":
      return InteractionType.Chat;
    case "Email":
    case "2":
      return InteractionType.Email;
    case "Video":
    case "3":
      return InteractionType.Video;
    case "AudioAndVideo":
    case "4":
      return InteractionType.AudioAndVideo;
    default:
      return null;
  }
};

type Interaction = {
  customerId: string;
  siteId: string;
  interactionId: string;
  masterInteractionId?: string;
  archivePath: string;
  interactionType: InteractionType;
  fileName: string;
  startTime: string;
  endTime: string;
  legalHold: boolean;
  hasMedia: boolean;
  retentionDate: number | null;
  retentionDateHold: number | null;
  importTimestamp: string;
  filesizeBytes?: number;
  sourceId?: string;
  agentId?: string;
  agentName?: string;
  durationMs?: number;
  phoneNumber?: string;
  dnis?: string;
  extension?: string;
  group?: string;
  direction?: InteractionDirection;
  device?: string;
  participants?: string[];
  segmentIds?: string[];
};

export const getRetentionDate = (interaction: Interaction): number | null => {
  return interaction.legalHold
    ? interaction.retentionDateHold
    : interaction.retentionDate;
};

export const setRetentionDate = (
  interaction: Interaction,
  retentionDate: number | null
) => {
  if (interaction.legalHold) {
    interaction.retentionDateHold = retentionDate;
    interaction.retentionDate = null;
  } else {
    interaction.retentionDate = retentionDate;
    interaction.retentionDateHold = null;
  }
};

export const setLegalHold = (interaction: Interaction, legalHold: boolean) => {
  interaction.legalHold = legalHold;
  if (interaction.legalHold) {
    interaction.retentionDateHold = interaction.retentionDate;
    interaction.retentionDate = null;
  } else {
    interaction.retentionDate = interaction.retentionDateHold;
    interaction.retentionDateHold = null;
  }
};

export default Interaction;
