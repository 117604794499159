import AuditLogData from "../../pages/audit-logs/AuditLogData";

export interface IAuditLog {
  auditLogId: string;
  customerId: string;
  resourceId: string;
  userId?: string;
  httpMethod?: string;
  statusCode: number;
  userName?: string;
  path?: string;
  queryString: string[];
  ipAddress?: string;
  date: string;
  payload?: Record<string, string>;
}

export default class AuditLog implements IAuditLog {
  public auditLogId: string = "";
  public customerId: string = "";
  public resourceId: string = "";
  public date: string = "";
  public statusCode: number = 0;
  public queryString: string[] = [];
  public httpMethod?: string;
  public path?: string;
  public userId?: string;
  public userName?: string;
  public ipAddress?: string;
  public payload?: Record<string, string>;

  public static fromIAuditLog(auditLog: IAuditLog): AuditLog {
    return Object.assign(new AuditLog(), auditLog);
  }

  public displayInfo() {
    return <AuditLogData data={this} />;
  }
}
