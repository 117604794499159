import { Box, LinearProgress } from "@mui/material";
import { useRole } from "../../../hooks/useRole";

export interface RoleNameDisplayProps {
  customerId?: string;
  roleId?: string;
}

export const RoleNameDisplay = (params: RoleNameDisplayProps) => {
  const { data, isLoading } = useRole(params.customerId, params.roleId);
  return isLoading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <span>{data?.name}</span>
  );
};
