import { useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useLocation, useNavigate } from "react-router-dom";

const useVerifyAuth = (): void => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") return;
    const verifyAuth = async () => {
      try {
        const session = await fetchAuthSession();
        const customerId = session.tokens?.idToken?.payload.customerId;

        if (!customerId) throw new Error("No customer Id found in token.");
        navigate(`/customers/${customerId}`);
      } catch {
        navigate("/login");
      }
    };
    verifyAuth();
  }, [location.pathname, navigate]);
};

export default useVerifyAuth;
