import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../Select/Select";

const MultiSelect = <
  TOption,
  TFormData extends FieldValues,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  props: SelectProps<TFormData, TOption, true, DisableClearable, FreeSolo>
) => {
  return <Select {...props} multiple />;
};

export default MultiSelect;
