import AxiosInstance from "./axiosInstance";

const uploadToS3 = async (
  file: File,
  uploadUrl: string,
  headers?: Record<string, string>,
) => {
  console.log(file.type);
  const instance = await AxiosInstance();
  await instance.put(uploadUrl, file, {
    headers: {
      "Content-Type": file.type,
      ...headers,
    },
  });
};

export default uploadToS3;
