import React from "react";
import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";

interface AnalyticWidgetProps {
  title: string;
  units?: string;
  total: number|string|undefined;
  color: "primary" | "secondary" | "error" | "warning" | "info" | "success";
  icon: React.ReactNode;
  tooltipContent: string;
  isLoading: boolean;
}

const AnalyticWidget = ({
  title,
  total,
  units,
  color,
  icon,
  tooltipContent,
  isLoading
}: AnalyticWidgetProps) => {

  return (
    <>
    {isLoading &&  <LoadingIndicator />}
    <Card>
      <Tooltip title={tooltipContent} followCursor={true} arrow>
        <CardContent>
          <Typography variant="h6">
            {icon} {title}
          </Typography>
          <Typography variant="h4" color={color}>
            {total?.toLocaleString()} {units}
          </Typography>
        </CardContent>
      </Tooltip>
    </Card>
    </>
  );
};

export default AnalyticWidget;
