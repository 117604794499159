import Button, { ButtonProps } from "@mui/material/Button";
import { signInWithRedirect } from "aws-amplify/auth";
import { useAlert } from "../../../context/AlertContext";
import SSOProvider from "../../../types/auth/SSOProvider";
import ErrorMessage from "../../../types/feedback/ErrorMessage";

export interface SSOButtonProps extends ButtonProps {
  provider: SSOProvider;
}

const SSOButton = ({ provider, ...props }: SSOButtonProps) => {
  const showAlert = useAlert();

  const ssoSignIn = async () => {
    try {
      await signInWithRedirect({
        provider: {
          custom: provider,
        },
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
        showAlert(error.message, "error");
        return;
      }
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  };

  return (
    <Button variant="outlined" onClick={ssoSignIn} {...props}>
      Sign in with {provider}
    </Button>
  );
};

export default SSOButton;
