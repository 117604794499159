import { UseMutationOptions, UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";
import {
  CreateConnectorRequest,
  DeleteConnectorRequest,
  EditConnectorRequest,
  createConnector,
  deleteConnector,
  editConnector,
  getConnectors,
} from "../api/connector";

export const useGetConnectors = (customerId?: string) => {
  return useQuery({
    queryKey: ["getConnectors", customerId],
    queryFn: () => getConnectors(customerId!),
    enabled: !!customerId,
  });
};

export const useCreateConnector = () => {
  return useMutation({
    mutationFn: async (request: CreateConnectorRequest) => {
      return await createConnector(request);
    },
  });
};

export const useEditConnector = () => {
  return useMutation({
    mutationFn: async (request: EditConnectorRequest) => {
      return await editConnector(request);
    },
  });
};

export const useDeleteConnector = (
  options: UseMutationOptions<void, unknown, DeleteConnectorRequest, unknown>
): UseMutationResult<void, unknown, DeleteConnectorRequest, unknown> => {
  return useMutation({
    ...options,
    mutationKey: ["deleteConnector"],
    mutationFn: async (request: DeleteConnectorRequest) => {
      return await deleteConnector(request);
    },
  });
};

