import "./AudioPlayer.css";
import PlayButton, { PlayButtonProps } from "../ButtonsAndControls/VideoAndAudioOnly/PlayButton";
import VolumeControl, { VolumeControlProps } from "../ButtonsAndControls/VideoAndAudioOnly/VolumeControl";
import NewWindowButton, { NewWindowButtonProps } from "../ButtonsAndControls/NewWindowButton";

interface PlayerControlsProps {
  PlayButtonProps: PlayButtonProps;
  VolumeControlProps: VolumeControlProps;

  NewWindowButtonProps: NewWindowButtonProps;
}

const PlayerControls = ({
  PlayButtonProps,
  VolumeControlProps,
  NewWindowButtonProps,
}: PlayerControlsProps) => {
  const { isPlaying, setIsPlaying, ...playButtonProps } = PlayButtonProps;
  const { muted, setMuted, volume, setVolume, ...volumeControlProps } =
    VolumeControlProps;
  const { playerInteractionId, handleOpenInNewWindow } = NewWindowButtonProps;

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <PlayButton
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          {...playButtonProps}
        />

        <VolumeControl
          muted={muted}
          setMuted={setMuted}
          volume={volume}
          setVolume={setVolume}
          {...volumeControlProps}
        />
      </div>
      <div className="flex items-center">
        <NewWindowButton
          handleOpenInNewWindow={handleOpenInNewWindow}
          playerInteractionId={playerInteractionId}
        />
      </div>
    </div>
  );
};

export default PlayerControls;
