import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { IconButton, Tooltip } from "@mui/material";
import {
  GridSortModel,
  useGridApiRef,
  type DataGridProps,
  type GridInitialState,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { UpdateInteractionRequest } from "../../api/interaction";
import AuthorizedIconButton from "../../components/common/AuthorizedIconButton/AuthorizedIconButton";
import DataTable from "../../components/common/DataTable/DataTable";
import { SiteNameDisplay } from "../../components/common/FriendlyDisplay/SiteNameDisplay";
import { useAlert } from "../../context/AlertContext";
import { convertMsToTimeDisplay } from "../../helpers/displayHelpers";
import { useUpdateInteraction } from "../../hooks/useInteraction";
import type Interaction from "../../types/interaction/Interaction";
import DownloadInteractionFileButton from "./DownloadInteractionFileButton";
import InteractionInfo from "./InteractionInfo";
import ConfirmRetentionDateDialog, {
  ConfirmRetentionDateDialogProps,
} from "./components/ConfirmRetentionDateDialog";
import EditRetentionDateCell from "./components/EditRetentionDateCell";
import RenameFieldMenu, {
  ExtendedGridColDef,
} from "./components/RenameFieldMenu";
import FieldLabelContext from "./context/FieldLabelContext";
import SegmentButton from "./components/SegmentButton";
import {
  getRetentionDate,
  InteractionType,
  setLegalHold,
  setRetentionDate,
} from "../../types/interaction/Interaction";
import MediaTypeIndicator from "./components/MediaTypeIndicator";
import { InteractionTableToolbarProps } from "../../pages/search/components/table/InteractionTableToolbar";
import InteractionTableToolbar from "./components/table/InteractionTableToolbar";
import { AuthContext } from "../../context/AuthContext";
import { RowSelectorMode } from "../../types/datatable/RowSelectorMode";

declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides extends InteractionTableToolbarProps {}
}

export const SEARCH_DEFAULT_SORT_MODEL: GridSortModel = [
  {
    field: "startTime",
    sort: "desc",
  },
];

interface SearchResultsProps
  extends Omit<
    DataGridProps,
    "columns" | "rows" | "getRowId" | "initialStateValue"
  > {
  tableId: string;
  results: Interaction[];
  customerId: string;
  isLoading?: boolean;
  playerInteractionId: string;
  refresh: () => void | Promise<void>;
  setPlayerInteractionId: (interactionId: string) => void;
  onSortModelChange?: (sortModel: GridSortModel) => Promise<void> | void;
  setMediaDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchResults = ({
  tableId,
  results,
  customerId,
  isLoading = false,
  playerInteractionId,
  refresh,
  setPlayerInteractionId,
  onSortModelChange,
  onPaginationModelChange,
  setMediaDrawerOpen,
  ...props
}: SearchResultsProps) => {
  const { user } = useContext(AuthContext);
  const [isInteractionInfoOpen, setIsInteractionInfoOpen] =
    useState<boolean>(false);
  const [
    isConfirmRetentionDateDialogOpen,
    setIsConfirmRetentionDateDialogOpen,
  ] = useState<boolean>(false);
  const [confirmRetentionDateDialogProps, setConfirmRetentionDateDialogProps] =
    useState<Partial<ConfirmRetentionDateDialogProps>>({});
  const [infoInteractionId, setInfoInteractionId] = useState<string>("");
  const { mutateAsync: updateInteraction } = useUpdateInteraction();
  const { getFieldLabel } = useContext(FieldLabelContext);
  const showAlert = useAlert();
  const apiRef = useGridApiRef();
  const isInteractionEditable = user?.hasPermission("interactions:Update") || user?.hasPermission("interactions:*");

  const columns: ExtendedGridColDef[] = [
    {
      renderCell: ({ row }: GridRenderCellParams<Interaction>) =>
        !row.fileName ? (
          <>
            <Tooltip placement="top" title="No recording">
              <IconButton>
                <DoNotDisturbIcon />
              </IconButton>
            </Tooltip>
            <MediaTypeIndicator interactionType={row.interactionType} />
          </>
        ) : (
          <>
            <AuthorizedIconButton
              permission="interactions:Replay"
              unauthorizedAction="disable"
              onClick={() => playOrStopAudio(row)}
              aria-label="play-interaction-button"
            >
              <PlayCircleIcon
                className={
                  row.interactionId === playerInteractionId
                    ? "text-blue-500"
                    : ""
                }
              />
            </AuthorizedIconButton>
            <MediaTypeIndicator interactionType={row.interactionType} />
            <SegmentButton interaction={row} />
          </>
        ),
      field: "Play",
      headerName: getFieldLabel("Play"),
      sortable: false,
      hideable: false,
      filterable: false,
      disableRenameable: true,
      minWidth: 150,
    },
    {
      field: "siteId",
      headerName: getFieldLabel("siteId"),
      type: "string",
      renderCell: (params) => (
        <SiteNameDisplay customerId={customerId} siteId={params.value} />
      ),
    },
    {
      field: "agentId",
      headerName: getFieldLabel("agentId"),
      type: "string",
    },
    {
      field: "agentName",
      headerName: getFieldLabel("agentName"),
      type: "string",
      hideable: false,
    },
    {
      field: "startTime",
      headerName: getFieldLabel("startTime"),
      type: "string",
      valueFormatter: (value: string) => new Date(value).toLocaleString(),
      hideable: false,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "endTime",
      headerName: getFieldLabel("endTime"),
      type: "string",
      valueFormatter: (value: string) => new Date(value).toLocaleString(),
      hideable: false,
    },
    {
      field: "sourceId",
      headerName: getFieldLabel("sourceId"),
      type: "string",
    },
    {
      field: "direction",
      headerName: getFieldLabel("direction"),
      type: "string",
    },
    {
      field: "device",
      headerName: getFieldLabel("device"),
      type: "string",
    },
    {
      field: "phoneNumber",
      headerName: getFieldLabel("phoneNumber"),
      type: "string",
    },
    {
      field: "dnis",
      headerName: getFieldLabel("dnis"),
      type: "string",
    },
    {
      field: "extension",
      headerName: getFieldLabel("extension"),
      type: "string",
    },
    {
      field: "durationMs",
      headerName: getFieldLabel("durationMs"),
      type: "number",
      valueFormatter: (value: number | undefined) =>
        convertMsToTimeDisplay(value),
    },
    {
      field: "group",
      headerName: getFieldLabel("group"),
      type: "string",
    },
    {
      field: "skill",
      headerName: getFieldLabel("skill"),
      type: "string",
    },
    {
      field: "masterInteractionId",
      headerName: getFieldLabel("masterInteractionId"),
      type: "string",
    },
    {
      field: "interactionId",
      headerName: getFieldLabel("interactionId"),
      type: "string",
    },
    {
      field: "retentionDate",
      headerName: getFieldLabel("retentionDate"),
      type: "dateTime",
      editable: isInteractionEditable,
      valueGetter: (_, row: Interaction): number | undefined => {
        return getRetentionDate(row) ?? undefined;
      },
      valueSetter: (newValue: number | undefined | null, row: Interaction) => {
        setRetentionDate(row, newValue ?? null);
        return row;
      },
      renderEditCell: EditRetentionDateCell,
      valueFormatter: (value: number | undefined) =>
        value ? new Date(value * 1000).toLocaleString() : "",
    },
    {
      field: "legalHold",
      headerName: getFieldLabel("legalHold"),
      type: "boolean",
      renderCell: ({ value }) => (value ? "Yes" : "No"),
      editable: isInteractionEditable,
      valueGetter: (_, row: Interaction): boolean => {
        return row.legalHold;
      },
      valueSetter: (newValue: boolean, row: Interaction) => {
        if (newValue === row.legalHold) {
          return row;
        }
        setLegalHold(row, newValue);
        return row;
      },
    },
    {
      field: 'participants',
      headerName: getFieldLabel('participants'),
      type: 'string',
      valueGetter: (_, row: Interaction) => row.participants?.join(', ') ?? '',
    },
    {
      field: 'segmentIds',
      headerName: getFieldLabel('segmentIds'),
      type: 'string',
      valueGetter: (_, row: Interaction) => row.segmentIds?.join(', ') ?? '',
    },
    {
      renderCell: ({ row }: GridRenderCellParams<Interaction>) => {
        const canExport =
          user?.hasPermission("interactions:Export") ||
          user?.hasPermission("interactions:*");
        const isDisabled = !row.fileName || !canExport;
        const tooltipMessage = isDisabled
          ? "Download disabled. Ensure file exists and you have the correct permissions."
          : "Download interaction file";
        return (
          <>
            <Tooltip title={tooltipMessage}>
              <span>
                <DownloadInteractionFileButton
                  customerId={customerId}
                  interactionId={row.interactionId}
                  disabled={isDisabled}
                />
              </span>
            </Tooltip>
            <Tooltip title="Info">
              <IconButton
                onClick={() => {
                  setIsInteractionInfoOpen(true);
                  setInfoInteractionId(row.interactionId);
                }}
                aria-label="display-interaction-info-button"
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      field: "Actions",
      headerName: getFieldLabel("Actions"),
      align: "center",
      sortable: false,
      hideable: false,
      filterable: false,
      disableRenameable: true,
    },
  ];

  const getRowId = (row: Interaction) => row.interactionId;

  const playOrStopAudio = (row: Interaction) => {
    if (playerInteractionId === row.interactionId) {
      setPlayerInteractionId("");
      setMediaDrawerOpen(false);
      return;
    }
    if (
      [
        InteractionType.Audio,
        InteractionType.Video,
        InteractionType.AudioAndVideo,
        InteractionType.Chat,
      ].includes(row.interactionType)
    ) {
      setPlayerInteractionId(row.interactionId);
      setMediaDrawerOpen(true);
      return;
    }

    setMediaDrawerOpen(false);
  };

  const initialState: GridInitialState = {
    ...props.initialState,
    columns: {
      columnVisibilityModel: {
        from: false,
        to: false,
        callId: false,
        continuityId: false,
        agentId: false,
      },
      ...props.initialState?.columns,
    },
    sorting: {
      sortModel: SEARCH_DEFAULT_SORT_MODEL,
      ...props.initialState?.sorting,
    },
  };

  const handleSortModelChange = (model: GridSortModel) => {
    const newModel = model.length === 0 ? SEARCH_DEFAULT_SORT_MODEL : model;
    onSortModelChange?.(newModel);
    return newModel;
  };

  const confirmRetentionDate = (
    request: UpdateInteractionRequest,
    previousRetentionDate?: number | null
  ) => {
    setIsConfirmRetentionDateDialogOpen(true);
    setConfirmRetentionDateDialogProps({
      request,
      previousRetentionDate,
    });
  };

  const processRowUpdate = async (newRow: Interaction, oldRow: Interaction) => {
    try {
      const didLegalHoldChange = newRow.legalHold !== oldRow.legalHold;
      const didRetentionDateChange =
        getRetentionDate(newRow) !== getRetentionDate(oldRow);
      const newLegalHold = didLegalHoldChange ? newRow.legalHold : undefined;
      const newRetentionDate = didRetentionDateChange
        ? getRetentionDate(newRow) ?? 0
        : undefined;
      if (!didRetentionDateChange && !didLegalHoldChange) {
        return oldRow;
      }
      const request: UpdateInteractionRequest = {
        customerId,
        interactionId: newRow.interactionId,
        newLegalHold,
        newRetentionDate,
      };
      if (didRetentionDateChange) {
        confirmRetentionDate(request, getRetentionDate(oldRow));
        return oldRow;
      }
      return await updateInteraction(request);
    } catch (error: unknown) {
      console.error(error);
      showAlert("Failed to update interaction.", "error");
      return oldRow;
    }
  };

  return (
    <>
      <DataTable
        {...props}
        refresh={refresh}
        apiRef={apiRef}
        rowCount={props.rowCount ?? 0}
        tableId={tableId}
        slots={{
          columnMenu: RenameFieldMenu,
          toolbar: InteractionTableToolbar,
        }}
        slotProps={{
          toolbar: {
            refresh,
          },
        }}
        columns={columns}
        rows={results}
        loading={isLoading}
        getRowId={getRowId}
        initialState={initialState}
        editMode="row"
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        disableColumnFilter
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={handleSortModelChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={() => {
          showAlert("Failed to update interaction", "error");
        }}
        rowSelectorMode={RowSelectorMode.MULTIPLE}
      />
      <ConfirmRetentionDateDialog
        isOpen={isConfirmRetentionDateDialogOpen}
        setIsOpen={setIsConfirmRetentionDateDialogOpen}
        apiRef={apiRef}
        {...confirmRetentionDateDialogProps}
      />
      <InteractionInfo
        isOpen={isInteractionInfoOpen}
        setIsOpen={setIsInteractionInfoOpen}
        interactionId={infoInteractionId}
        customerId={customerId}
      />
    </>
  );
};

export default SearchResults;
