import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DeleteRoleRequest,
  EditRoleRequest,
  createRole,
  deleteRole,
  editRole,
  getAllRoles,
  getRole,
  type CreateRoleRequest,
} from "../api/role";
import Role from "../types/role/Role";

export const useGetRoles = (customerId?: string) => {
  return useQuery({
    queryKey: ["roles", customerId],
    queryFn: () => getAllRoles(customerId),
    enabled: !!customerId,
  });
};

export const useCreateRole = (customerId: string) => {
  return useMutation({
    mutationKey: ["createRole"],
    mutationFn: async (request: CreateRoleRequest) => {
      return await createRole(customerId, request);
    },
  });
};

export const useEditRole = (customerId: string) => {
  return useMutation({
    mutationKey: ["editRole"],
    mutationFn: async (request: EditRoleRequest) => {
      return await editRole(customerId, request);
    },
  });
};

export const useDeleteRole = (customerId: string) => {
  return useMutation({
    mutationKey: ["deleteRole"],
    mutationFn: async (request: DeleteRoleRequest) => {
      return await deleteRole(customerId, request);
    },
  });
};

export const useRole = (customerId?: string, roleId?: string) => {
  return useQuery<Role | undefined, Error>({
    queryKey: ["roles", customerId, roleId],
    queryFn: () => getRole(customerId, roleId),
    enabled: !!customerId && !!roleId,
  });
};
