import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "./Select";

export interface AsyncSelectProps<
  TFormData extends FieldValues,
  TOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> extends Omit<
    SelectProps<TFormData, TOption, Multiple, DisableClearable, FreeSolo>,
    "onOpen"
  > {
  setIsReady: (isReady: boolean) => void;
}

const AsyncSelect = <
  TFormData extends FieldValues,
  TOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  setIsReady,
  ...props
}: AsyncSelectProps<
  TFormData,
  TOption,
  Multiple,
  DisableClearable,
  FreeSolo
>) => {
  return <Select {...props} onOpen={() => setIsReady(true)} />;
};

export default AsyncSelect;
