import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  Checkbox,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from "react-hook-form";
import ListboxComponent from "../Virtualization/ListboxComponent";
import StyledPopper from "../Virtualization/StyledPopper";

export interface SelectProps<
  TFormData extends FieldValues,
  TOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "disableCloseOnSelect"
  > {
  name: Path<TFormData>;
  label: string;
  control: Control<TFormData, unknown>;
  rules?: Omit<
    RegisterOptions<TFormData, Path<TFormData>>,
    "disabled" | "setValueAs" | "valueAsNumber" | "valueAsDate"
  >;
  placeholder?: string;
  options: TOption[];
  error?: boolean;
  required?: boolean;
  TextFieldProps?: TextFieldProps;
  defaultValue?: PathValue<TFormData, Path<TFormData>>;
  virtualized?: boolean;
  mutateOption?: (
    option: AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo>
  ) => PathValue<TFormData, Path<TFormData>>;
}

const CHECKBOX_ICON = <CheckBoxOutlineBlank fontSize="small" />;
const CHECKBOX_ICON_CHECKED = <CheckBox fontSize="small" />;

const Select = <
  TFormData extends FieldValues,
  TOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  name,
  label,
  control,
  rules,
  error = false,
  placeholder = "",
  options,
  getOptionLabel,
  getOptionKey,
  TextFieldProps,
  mutateOption,
  required = false,
  size = "small",
  defaultValue,
  virtualized = false,
  ...props
}: SelectProps<TFormData, TOption, Multiple, DisableClearable, FreeSolo>) => {
  const renderOption = (
    listItemProps: React.HTMLAttributes<HTMLLIElement>,
    option: TOption,
    { selected }: AutocompleteRenderOptionState
  ): React.ReactNode => {
    const label = getOptionLabel
      ? getOptionLabel(option)
      : (option as string) ?? "";
    return (
      <li {...listItemProps} key={getOptionKey ? getOptionKey(option) : label}>
        {props.multiple && (
          <Checkbox
            icon={CHECKBOX_ICON}
            checkedIcon={CHECKBOX_ICON_CHECKED}
            style={{ marginRight: 8 }}
            checked={selected}
          />
        )}
        {label}
      </li>
    );
  };
  return (
    <Controller
      name={name}
      control={control}
      disabled={props.disabled}
      key={name}
      defaultValue={defaultValue}
      rules={{ required: required ? `${label} is required.` : false, ...rules }}
      render={({ field }) => {
        const { ref, onChange, ...rest } = field;
        return (
          <Autocomplete
            renderOption={renderOption}
            {...props}
            {...rest}
            id={name}
            options={options}
            onChange={(
              _,
              value: AutocompleteValue<
                TOption,
                Multiple,
                DisableClearable,
                FreeSolo
              >
            ) => {
              const mutated = mutateOption ? mutateOption(value) : value;
              onChange(mutated);
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                {...TextFieldProps}
                name={name}
                label={label}
                placeholder={placeholder}
                error={error}
                required={required}
                inputRef={ref}
              />
            )}
            PopperComponent={virtualized ? StyledPopper : undefined}
            ListboxComponent={virtualized ? ListboxComponent : undefined}
            getOptionLabel={getOptionLabel}
            size={size}
            disableCloseOnSelect={props.multiple}
          />
        );
      }}
    />
  );
};

export default Select;
