import { useContext } from "react";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";
import ClearableTextField from "../../../../components/common/ClearableTextField/ClearableTextField";
import FieldLabelContext from "../../context/FieldLabelContext";

export interface DnisFilterProps<TFormValues extends FieldValues> {
  name: FieldPath<TFormValues>;
  control: Control<TFormValues>;
  label?: string;
}

const DnisFilter = <TFormValues extends FieldValues>({
  name,
  control,
  label,
}: DnisFilterProps<TFormValues>) => {

  const {getFieldLabel} = useContext(FieldLabelContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <ClearableTextField
          {...field}
          inputRef={ref}
          label={getFieldLabel("dnis")}
          size="small"
          fullWidth
        />
      )}
    />
  );
};

export default DnisFilter;
