import { useQuery } from "@tanstack/react-query";
import {
  GetCustomerResponse,
  getAllCustomers,
  getCustomer,
} from "../api/customer";
import Customer from "../types/customer/customer";

export const useAllCustomers = (readyToQuery: boolean = true) => {
  return useQuery<Customer[], Error>({
    queryKey: ["customers"],
    queryFn: getAllCustomers,
    enabled: readyToQuery,
  });
};

export const useCustomer = (
  customerId: string | undefined,
  readyToQuery: boolean = true
) => {
  return useQuery<GetCustomerResponse | undefined, Error>({
    queryKey: ["customer", customerId],
    queryFn: () => getCustomer(customerId!),
    enabled: !!customerId && readyToQuery,
  });
};
