import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import MenuBar from "../components/menuBar/MenuBar";
import AlertProvider from "../context/AlertContext";
import { AuthProvider } from "../context/AuthContext";
import AdminInfoProvider from "../context/AdminInfoContext";
import AdminInfoBar from "../components/menuBar/AdminInfoBar";
import useVerifyAuth from "../hooks/useVerifyAuth";

export default function Root() {
  useVerifyAuth();

  return (
    <AuthProvider>
      <AlertProvider>
        <AdminInfoProvider>
          <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
            <MenuBar sx={{ flexGrow: 0 }} />
            <AdminInfoBar />
            <Box className="flex flex-col flex-1 overflow-y-auto" p={2}>
              <Outlet />
            </Box>
          </Box>
        </AdminInfoProvider>
      </AlertProvider>
    </AuthProvider>
  );
}
