import { GridRenderEditCellParams } from "@mui/x-data-grid";
import Interaction from "../../../types/interaction/Interaction";
import dayjs, { Dayjs } from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useAlert } from "../../../context/AlertContext";

export interface EditRetentionDateCellProps
  extends GridRenderEditCellParams<Interaction, number> {}

const EditRetentionDateCell = ({
  id,
  value,
  field,
  api,
  row,
}: GridRenderEditCellParams<Interaction, number>) => {
  const showAlert = useAlert();

  return (
    <DateTimePicker
      disabled={row.legalHold}
      slotProps={{
        textField: { size: "small" },
        field: { clearable: true },
        actionBar: { actions: ["clear", "accept"] },
      }}
      value={value ? dayjs(new Date(value * 1000)) : null}
      views={["year", "month", "day", "hours", "minutes", "seconds"]}
      onChange={async (newValue: Dayjs | null) => {
        if (newValue?.isValid() || newValue === null) {
          api.setEditCellValue({
            id,
            field,
            value: newValue?.unix(),
          });
        } else {
          showAlert("Please enter a valid date", "error");
        }
      }}
    />
  );
};

export default EditRetentionDateCell;
