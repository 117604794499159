import { ConnectorWizardFormValues } from "../components/connectors/forms/ConnectorWizard";
import {
  IConnectorRow,
  omitEmptyConnectorDetails,
} from "../components/connectors/models/Connector";
import { deleteApi, getApi, postApi, putApi } from "./helpers/apiMethods";
import {
  Five9ConnectorRow,
  flattenFive9ConnectorDetails,
} from "../components/connectors/models/Five9Connector";

const path = "connectors";

export interface GetConnectorsResponse {
  connectors: IConnectorRow[];
}

type CreateConnectorResponse = { connector: IConnectorRow };

type EditConnectorResponse = { connector: IConnectorRow };

// Transformation function for connectors
const transformConnector = (connector: IConnectorRow): IConnectorRow => {
  if(connector.type === "Five9"){
    return flattenFive9ConnectorDetails(connector as Five9ConnectorRow);
  }
  else{
    return omitEmptyConnectorDetails(connector); // Default to omit empty connectorDetails (only CXone uses this for now)
  }
};

export const getConnectors = async (
  customerId: string
): Promise<GetConnectorsResponse | undefined> => {
  try {
    const response = await getApi<GetConnectorsResponse>(
      `/v1/customers/${customerId}/${path}`
    );

    if (!response?.data?.connectors) {
      console.warn("No connectors found.");
      return undefined;
    }

    const transformedConnectors: IConnectorRow[] =
      response.data.connectors.map(transformConnector);

    return { connectors: transformedConnectors };
  } catch (error) {
    console.error("Error fetching connectors:", error);
    return undefined;
  }
};

export type CreateConnectorRequest = ConnectorWizardFormValues & {
  customerId: string;
};

export const createConnector = async ({
  customerId,
  type: name,
  ...request
}: CreateConnectorRequest): Promise<CreateConnectorResponse | undefined> => {
  const response = await postApi<CreateConnectorResponse>(
    `/v1/customers/${customerId}/${path}/${name}`,
    request
  );
  return response.data;
};

export type EditConnectorRequest = ConnectorWizardFormValues & {
  customerId: string;
  connectorId: string;
};

export const editConnector = async ({
  customerId,
  connectorId,
  type: name,
  ...request
}: EditConnectorRequest): Promise<EditConnectorResponse | undefined> => {
  const response = await putApi<EditConnectorResponse>(
    `/v1/customers/${customerId}/${path}/${name}/${connectorId}`,
    request
  );
  return response.data;
};

export interface DeleteConnectorRequest {
  customerId: string;
  connectorId: string;
  jobConfigId: string;
  type: string;
}

export const deleteConnector = async ({
  customerId,
  type,
  connectorId,
  ...request
}: DeleteConnectorRequest): Promise<void> => {
  await deleteApi(
    `/v1/customers/${customerId}/${path}/${type}/${connectorId}`,
    {
      params: request,
    }
  );
};
