import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { UseFormReturn } from "react-hook-form";
import { useAllCustomers } from "../../hooks/useCustomer";
import { useState } from "react";
import AsyncSelect from "../../components/common/Select/AsyncSelect";
import { ConfigureFederatedAuthValues } from "./FederatedAuthForm";
import MetadataDocumentInput from "./MetadataDocumentInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { FormHelperText } from "@mui/material";

export interface FederatedAuthFormViewProps {
  form: UseFormReturn<ConfigureFederatedAuthValues>;
}

const FederatedAuthFormView = ({ form }: FederatedAuthFormViewProps) => {
  const [readyToQueryCustomers, setReadyToQueryCustomers] =
    useState<boolean>(false);
  const { register, control, formState } = form;
  const { errors, isValid, isDirty, isSubmitting } = formState;
  const {
    data: customers,
    error: customerError,
    isLoading: areCustomersLoading,
  } = useAllCustomers(readyToQueryCustomers);

  return (
    <Box className="w-3/4 pt-2 m-auto flex flex-col space-y-4">
      <FormGroup>
        <TextField
          {...register("name")}
          label="Name"
          error={!!errors.name}
          size="small"
          required
        />
        {errors.name && (
          <FormHelperText error>{errors.name.message}</FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <AsyncSelect
          label="Customer"
          control={control}
          name="customer"
          options={customers ?? []}
          setIsReady={setReadyToQueryCustomers}
          error={!!customerError || !!errors.customer}
          loading={areCustomersLoading}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.customerId === value.customerId
          }
          required
        />
        {errors.customer && (
          <FormHelperText error>{errors.customer.message}</FormHelperText>
        )}
      </FormGroup>
      <MetadataDocumentInput className="mt-2" />
      <Button
        type="submit"
        variant="contained"
        disabled={!isValid || !isDirty || isSubmitting}
        fullWidth
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default FederatedAuthFormView;
