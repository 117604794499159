import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
} from "@mui/material";
import { ConnectorConfigFormProps } from "../models/Connector";
import CXoneConnector, { CXoneFormValues } from "../models/CXOneConnector";
import { Controller } from "react-hook-form";

const CXOneConfigForm = ({
  defaultValues,
  register,
  control,
  errors,
}: ConnectorConfigFormProps<CXoneFormValues, "CXone">) => {
  const registerPrefix = CXoneConnector.Type;

  return (
    <>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.clientId`)}
          label="Client ID"
          defaultValue={defaultValues.clientId}
          error={!!errors?.CXone?.clientId}
          autoComplete="off"
        />
        {errors?.CXone?.clientId && (
          <FormHelperText error>
            <>{errors.CXone.clientId.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.clientSecret`)}
          type="password"
          label="Client Secret"
          defaultValue={defaultValues.clientSecret}
          error={!!errors?.CXone?.clientSecret}
          autoComplete="off"
        />
        {errors?.CXone?.clientSecret && (
          <FormHelperText error>
            <>{errors.CXone.clientSecret.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.accessKey`)}
          label="Access Key"
          defaultValue={defaultValues.accessKey}
          error={!!errors?.CXone?.accessKey}
          autoComplete="off"
          required
        />
        {errors?.CXone?.accessKey && (
          <FormHelperText error>
            <>{errors.CXone.accessKey.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.secretKey`)}
          type="password"
          label="Secret Key"
          defaultValue={defaultValues.secretKey}
          error={!!errors?.CXone?.secretKey}
          autoComplete="off"
          required
        />
        {errors?.CXone?.secretKey && (
          <FormHelperText error>
            <>{errors.CXone.secretKey.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          label="Save files with no media file?"
          control={
            <Controller
              name="shouldSaveNoMedia"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    field.onChange(event.target.checked)
                  }
                />
              )}
            />
          }
        />
        {errors?.shouldSaveNoMedia && (
          <FormHelperText error>
            <>{errors.shouldSaveNoMedia.message}</>
          </FormHelperText>
        )}
      </FormGroup>
    </>
  );
};

export default CXOneConfigForm;
