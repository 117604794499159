import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  CreateConnectorRequest,
  EditConnectorRequest,
} from "../../api/connector";
import ResultsPage from "../../components/common/ResultsPage/ResultsPage";
import ConnectorWizard, {
  ConnectorWizardFormValues,
} from "../../components/connectors/forms/ConnectorWizard";
import { IConnectorRow } from "../../components/connectors/models/Connector";
import {
  useCreateConnector,
  useEditConnector,
  useGetConnectors,
} from "../../hooks/useConnector";
import { ConnectorFormAction } from "../../components/connectors/forms/ConnectorConfigForm";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import { UseShowAlert, useAlert } from "../../context/AlertContext";
import DeleteConnectorDialog from "./DeleteConnectorDialog";

export const createValuesToRequest = (
  customerId: string,
  formValues: ConnectorWizardFormValues
): CreateConnectorRequest => {
  return {
    ...formValues,
    ruleSets: formValues.ruleSets.map((ruleSet) => ({
      ...ruleSet,
      siteId: ruleSet.site?.siteId ?? ruleSet.siteId,
    })),
    customerId,
  };
};

export const editValuesToRequest = (
  customerId: string,
  connectorId: string,
  formValues: ConnectorWizardFormValues
): EditConnectorRequest => {
  return {
    ...createValuesToRequest(customerId, formValues),
    connectorId,
  };
};

const handleError = (error: unknown, showAlert: UseShowAlert) => {
  console.error(error);
  if (error instanceof Error) {
    showAlert(error.message, "error");
  } else {
    showAlert(ErrorMessage.UNKNOWN, "error");
  }
};

const Connectors = () => {
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | null>(
    null
  );
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [action, setAction] = useState<ConnectorFormAction>("Create");
  const { customerId } = useParams();
  const { data, isLoading: connectorsLoading } = useGetConnectors(customerId);
  const [connectors, setConnectors] = useState<IConnectorRow[]>([]);
  const createConnectorMutation = useCreateConnector();
  const editConnectorMutation = useEditConnector();
  const showAlert = useAlert();

  useEffect(() => {
    if (data?.connectors) {
      setConnectors(data.connectors);
    }
  }, [data]);

  const createConnector = async (formValues: ConnectorWizardFormValues) => {
    try {
      const request = createValuesToRequest(customerId!, formValues);
      const response = await createConnectorMutation.mutateAsync(request);
      if (response) {
        setConnectors((prev) => [...prev, response.connector]);
        setFormIsOpen(false);
        showAlert("Connector created successfully.", "success");
      }
    } catch (error: unknown) {
      handleError(error, showAlert);
    }
  };

  const editConnector = async (formValues: ConnectorWizardFormValues) => {
    try {
      const request = editValuesToRequest(
        customerId!,
        selectedConnectorId!,
        formValues
      );
      const response = await editConnectorMutation.mutateAsync(request);
      if (response) {
        setConnectors((prev) =>
          prev.map((connector) =>
            connector.connectorId === selectedConnectorId
              ? response.connector
              : connector
          )
        );
        setFormIsOpen(false);
        showAlert("Connector updated successfully.", "success");
      }
    } catch (error: unknown) {
      handleError(error, showAlert);
    }
  };

  const onCreateClicked = () => {
    setAction("Create");
    setFormIsOpen(true);
  };

  const onEditClicked = () => {
    setAction("Edit");
    setFormIsOpen(true);
  };

  const onSubmit: Record<
    ConnectorFormAction,
    SubmitHandler<ConnectorWizardFormValues>
  > = {
    Create: createConnector,
    Edit: editConnector,
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 150,
      maxWidth: 350,
      valueGetter: (_: string, row: IConnectorRow) => row.connectorId,
    },
    {
      field: "type",
      headerName: "Connector Type",
      flex: 1,
      minWidth: 150,
      maxWidth: 250,
    },
    {
      field: "intervalSeconds",
      headerName: "Run Interval (seconds)",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
  ];

  const selectedConnector = useMemo(
    () =>
      connectors.find(
        (connector) => connector.connectorId === selectedConnectorId
      ),
    [selectedConnectorId, connectors]
  );

  return (
    <div className="h-full flex flex-col">
      <ResultsPage
        permissionResource="connectors"
        tableId="connectors"
        entityName="Connector"
        columns={columns}
        rows={connectors ?? []}
        loading={connectorsLoading}
        getRowId={(row: IConnectorRow) => row.connectorId}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        selectedRowId={selectedConnectorId}
        setSelectedRowId={setSelectedConnectorId}
        setCreateFormOpen={onCreateClicked}
        setEditFormOpen={onEditClicked}
        setDeleteDialogOpen={setIsDeleteDialogOpen}
      />
      <ConnectorWizard
        isOpen={formIsOpen}
        setIsOpen={setFormIsOpen}
        onSubmit={onSubmit[action]}
        action={action}
        selectedConnector={selectedConnector}
      />
      <DeleteConnectorDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        connector={selectedConnector}
        setConnectors={setConnectors}
      />
    </div>
  );
};

export default Connectors;
