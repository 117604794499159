import { ListSubheader, useMediaQuery, useTheme } from "@mui/material";
import {
  HTMLAttributes,
  ReactElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";

export const renderRow = ({ data, index }: ListChildComponentProps) => {
  const row = data[index];

  if (row.hasOwnProperty("group")) {
    return (
      <ListSubheader key={row.key} component="div">
        {row.group}
      </ListSubheader>
    );
  }

  return row;
};

// istanbul ignore next
export const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// istanbul ignore next
const OuterContext = createContext({});

// istanbul ignore next
const OuterElement = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// istanbul ignore next
const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props;
  const itemData: ReactElement[] = [];
  (children as ReactElement[]).forEach(
    (item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children ?? []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  // istanbul ignore next
  const getChildSize = (child: React.ReactElement) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  // istanbul ignore next
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * 8}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElement}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          itemCount={itemCount}
          overscanCount={5}
        >
          {renderRow}
        </VariableSizeList>
      </OuterContext.Provider>
    </div>
  );
});

export default ListboxComponent;
