import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AxiosError, HttpStatusCode } from "axios";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "../../context/AlertContext";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import Permissions from "../../types/permission/permission";
import Site from "../../types/site/Site";
import DialogForm from "../common/DialogForm/DialogForm";
import MultiSelect from "../common/MultiSelect/MultiSelect";
import PermissionsInput from "./PermissionsInput";

const FORM_ID = "create-role-form";

export interface CreateRoleValues {
  sites: Site[];
  allSites: boolean;
  name: string;
  permissions: string[];
}

export interface CreateRoleFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  permissions?: Permissions;
  sites?: Site[];
  createRole: (formValues: CreateRoleValues) => Promise<void>;
  isSitesLoading?: boolean;
  isPermissionsLoading?: boolean;
}

const CreateRoleForm = ({
  isOpen,
  setIsOpen,
  createRole,
  permissions = new Permissions(),
  sites = [],
  isSitesLoading = false,
  isPermissionsLoading = false,
}: CreateRoleFormProps) => {
  const { formState, control, register, watch, handleSubmit, reset } =
    useForm<CreateRoleValues>({
      defaultValues: {
        sites: [],
        allSites: false,
        name: "",
        permissions: [],
      },
    });
  const { isDirty, isValid, isSubmitting, defaultValues, errors } = formState;
  const showAlert = useAlert();

  const allSitesChecked = watch("allSites");

  const onSubmitError = (error: unknown) => {
    console.error(error);
    if (!(error instanceof AxiosError)) {
      showAlert(
        error instanceof Error ? error.message : ErrorMessage.UNKNOWN,
        "error"
      );
      return;
    }
    if (error.response?.status === HttpStatusCode.InternalServerError) {
      showAlert(
        "An unexpected error occurred. Please try again or contact support.",
        "error"
      );
      return;
    }
    showAlert(error.message, "error");
  };

  const onSubmit = async (formValues: CreateRoleValues) => {
    try {
      await createRole(formValues);
      showAlert("Role created successfully.", "success");
      setIsOpen(false);
    } catch (error: unknown) {
      onSubmitError(error);
    }
  };

  return (
    <DialogForm
      dialogTitle="Create Role"
      formId={FORM_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSubmitting={isSubmitting}
      isSubmitDisabled={!isDirty || !isValid}
      isCancelDisabled={isSubmitting}
      afterClose={reset}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          <FormGroup>
            <TextField
              {...register("name", { required: "Name is required." })}
              label="Name"
              error={!!errors.name}
              fullWidth
              required
            />
            {errors.name && (
              <FormHelperText error>{errors.name.message}</FormHelperText>
            )}
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              label="Grant access to all sites"
              control={
                <Controller
                  name="allSites"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.onChange(event.target.checked)
                      }
                    />
                  )}
                />
              }
            />
            {errors.allSites && (
              <FormHelperText error>{errors.allSites.message}</FormHelperText>
            )}
          </FormGroup>
          {!allSitesChecked && (
            <FormGroup>
              <MultiSelect<Site, CreateRoleValues>
                name="sites"
                control={control}
                label="Sites"
                loading={isSitesLoading}
                options={sites}
                getOptionLabel={(option: Site) => option.name}
                isOptionEqualToValue={(option: Site, value: Site) => {
                  return option.siteId === value.siteId;
                }}
                filterOptions={(options: Site[], { inputValue }: any) => {
                  return options.filter((option: Site) =>
                    option.name.toLowerCase().includes(inputValue.toLowerCase())
                  );
                }}
                getOptionKey={(option: Site) => option.siteId}
                error={!!errors.sites}
                fullWidth
                size="medium"
                required
              />
              {errors.sites && (
                <FormHelperText error>{errors.sites.message}</FormHelperText>
              )}
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel>Permissions</FormLabel>
            <div className="m-auto w-2/3">
              <Controller
                name="permissions"
                control={control}
                render={({ field }) => {
                  const { ref, value, onChange, ...rest } = field;
                  return (
                    <PermissionsInput<CreateRoleValues>
                      {...rest}
                      size="small"
                      defaultValue={
                        new Set<string>(defaultValues?.permissions as string[])
                      }
                      value={new Set<string>(value)}
                      onChange={(permissions: Set<string>) =>
                        onChange(Array.from(permissions))
                      }
                      isLoading={isPermissionsLoading}
                      permissions={permissions}
                    />
                  );
                }}
                rules={{
                  validate: (value) =>
                    value.length > 0 || "Permissions are required.",
                }}
              />
            </div>
          </FormGroup>
        </Stack>
      </form>
    </DialogForm>
  );
};

export default CreateRoleForm;
