import { Control } from "react-hook-form";
import IntervalPicker, {
  IntervalPickerProps,
} from "../IntervalPicker/IntervalPicker";

export type DurationFieldProps = Partial<IntervalPickerProps> & {
  control: Control;
  name: string;
  label: string;
  value: number | undefined | null;
  onChange: (value: number | undefined | null) => void;
};

const DurationField = ({
  control,
  name,
  label,
  value,
  onChange,
  ...props
}: DurationFieldProps) => {
  // Ensure value is either a number or null (for clearing purposes)
  const handleChange = (newValue: number | undefined | null) => {
    if (newValue === null || newValue === undefined) {
      onChange(null); // Set to null when cleared
    } else {
      onChange(newValue); // Otherwise, update with the new value
    }
  };
  return (
    <IntervalPicker
      {...props}
      label={label}
      value={value ?? null}
      setValue={handleChange}
      units={["hour", "minute", "second"]}
      TextFieldProps={{
        className: "w-28",
        size: "small",
        sx: { flex: 1 },
        ...props.TextFieldProps,
      }}
      SelectProps={{
        size: "small",
        ...props.SelectProps,
      }}
      clearable
    />
  );
};

export default DurationField;
