import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import type {
  CreateUserRequest,
  DeleteUserRequest,
  EditUserRequest,
  EditUserResponse,
} from "../../api/user";
import DeleteDialog from "../../components/common/DeleteDialog/DeleteDialog";
import { RoleNameDisplay } from "../../components/common/FriendlyDisplay/RoleNameDisplay";
import ResultsPage from "../../components/common/ResultsPage/ResultsPage";
import { UseShowAlert, useAlert } from "../../context/AlertContext";
import {
  useCreateUser,
  useDeleteUser,
  useEditUser,
  useUsers,
} from "../../hooks/useUser";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import User from "../../types/user/User";
import UserForm, { UserAction, UserFormValues } from "./UserForm";

export const createUser = async (
  data: UserFormValues,
  createUserMutation: UseMutationResult<
    User | undefined,
    unknown,
    CreateUserRequest,
    unknown
  >,
  setUsers: React.Dispatch<React.SetStateAction<User[]>>,
  showAlert: UseShowAlert,
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const request: CreateUserRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email!,
      roleId: data.role?.roleId ?? "",
      password: data.password!,
    };
    const user = await createUserMutation.mutateAsync(request);
    if (!user) {
      throw new Error("Failed to create user.");
    }
    setUsers((prevUsers: User[]) => {
      return [...prevUsers, user];
    });
    showAlert("User created successfully.", "success");
    setIsFormOpen(false);
  } catch (error: unknown) {
    if (error instanceof Error) {
      showAlert(error.message, "error");
    } else {
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  }
};

export const editUser = async (
  data: UserFormValues,
  selectedRowId: string | null,
  editUserMutation: UseMutationResult<
    EditUserResponse,
    unknown,
    EditUserRequest,
    unknown
  >,
  setUsers: React.Dispatch<React.SetStateAction<User[]>>,
  showAlert: UseShowAlert,
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const request: EditUserRequest = {
      userId: selectedRowId!,
      firstName: data.firstName,
      lastName: data.lastName,
      roleId: data.role?.roleId ?? "",
    };
    const { user } = await editUserMutation.mutateAsync(request);
    if (!user) {
      throw new Error("Failed to edit user.");
    }
    setUsers((prevUsers: User[]) => {
      return [...prevUsers.filter((u) => u.userId !== user.userId), user];
    });
    showAlert("User edited successfully.", "success");
    setIsFormOpen(false);
  } catch (error: unknown) {
    if (error instanceof Error) {
      showAlert(error.message, "error");
    } else {
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  }
};

export const deleteUser = async (
  deleteUserMutation: UseMutationResult<
    void,
    unknown,
    DeleteUserRequest,
    unknown
  >,
  selectedRowId: string | null,
  showAlert: UseShowAlert,
  users: User[],
  setUsers: React.Dispatch<React.SetStateAction<User[]>>
): Promise<void> => {
  try {
    await deleteUserMutation.mutateAsync({
      userId: selectedRowId!,
      username: users.find((user) => user.userId === selectedRowId!)?.email,
    });
    showAlert("User deleted successfully.", "success");
    setUsers((prevUsers: User[]) => {
      return prevUsers.filter((u) => u.userId !== selectedRowId);
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      showAlert(error.message, "error");
    } else {
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  }
};

export default function UserList() {
  const [action, setAction] = useState<UserAction>("Create");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const { customerId } = useParams();
  const [users, setUsers] = useState<User[]>([]);
  const { isLoading, data } = useUsers(customerId);
  const createUserMutation = useCreateUser(customerId!);
  const editUserMutation = useEditUser(customerId!);
  const deleteUserMutation = useDeleteUser(customerId!);
  const showAlert = useAlert();

  useEffect(() => {
    const users = data?.users;
    const apiErrors = data?.errors;
    if (!!apiErrors && !apiErrors?.isEmpty()) {
      const errorMessage = apiErrors.getFieldError("root.generic");
      if (errorMessage) showAlert(errorMessage, "error");
      return;
    }
    setUsers(users ?? []);
  }, [data, showAlert]);

  const getFullName = (_: string, user: User) => {
    return `${user.firstName} ${user.lastName}`;
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Full Name",
      valueGetter: getFullName,
      minWidth: 200,
      maxWidth: 400,
    },
    { field: "email", headerName: "Email", minWidth: 150, maxWidth: 400 },
    {
      field: "roleId",
      headerName: "Role",
      renderCell: (params) => (
        <RoleNameDisplay customerId={customerId} roleId={params.value} />
      ),
      minWidth: 150,
      maxWidth: 350,
    },
  ];

  const getRowId = (user: User) => user.userId;

  return (
    <div className="h-full flex flex-col">
      <ResultsPage
        permissionResource="users"
        tableId="users"
        entityName="User"
        columns={columns}
        rows={users}
        getRowId={getRowId}
        loading={isLoading}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        setCreateFormOpen={(open: boolean) => {
          setAction("Create");
          setIsFormOpen(open);
        }}
        setEditFormOpen={(open: boolean) => {
          setAction("Edit");
          setIsFormOpen(open);
        }}
        setDeleteDialogOpen={setIsDeleteDialogOpen}
      />
      <UserForm
        isOpen={isFormOpen}
        setIsOpen={setIsFormOpen}
        createUser={(formValues) =>
          createUser(
            formValues,
            createUserMutation,
            setUsers,
            showAlert,
            setIsFormOpen
          )
        }
        editUser={(formValues) =>
          editUser(
            formValues,
            selectedRowId,
            editUserMutation,
            setUsers,
            showAlert,
            setIsFormOpen
          )
        }
        action={action}
        selectedUser={
          users.find((user) => user.userId === selectedRowId) ?? null
        }
      />
      <DeleteDialog
        entityName="User"
        deleteEntity={() =>
          deleteUser(
            deleteUserMutation,
            selectedRowId,
            showAlert,
            users,
            setUsers
          )
        }
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
    </div>
  );
}
