import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { SyntheticEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import PasswordRules from "../../components/auth/PasswordRules/PasswordRules";
import DialogForm from "../../components/common/DialogForm/DialogForm";
import passwordSchema from "../../helpers/auth/passwordSchema";
import defaultFormConfig from "../../helpers/validation/defaultFormConfig";
import { useGetRoles } from "../../hooks/useRole";
import Role, { roleSchema } from "../../types/role/Role";
import User from "../../types/user/User";

const FORM_ID = "user-form";

export type UserFormValues = {
  firstName: string;
  lastName: string;
  email?: string;
  role: Role | null;
  password?: string;
  confirmPassword?: string;
};

export type UserAction = "Create" | "Edit";

export type UserFormProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  createUser: (formData: UserFormValues) => Promise<void>;
  editUser: (formData: UserFormValues) => Promise<void>;
  selectedUser: User | null;
  action?: UserAction;
};

const DEFAULT_VALUES: UserFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  role: null,
  password: "",
  confirmPassword: "",
};

const createSchema = z
  .object({
    email: z
      .string()
      .email({ message: "Please specify a valid email." })
      .min(1, {
        message: "Email is required.",
      }),
    firstName: z.string().min(1, {
      message: "First name is required.",
    }),
    lastName: z.string().min(1, {
      message: "Last name is required.",
    }),
    role: roleSchema,
    password: passwordSchema("Password"),
    confirmPassword: z.string().min(1, {
      message: "Please confirm the password.",
    }),
  })
  .refine(
    ({ password, confirmPassword }) => {
      return password === confirmPassword;
    },
    {
      message: "Passwords do not match.",
      path: ["confirmPassword"],
    }
  );

const editSchema = z.object({
  firstName: z.string().min(1, {
    message: "First name is required.",
  }),
  lastName: z.string().min(1, {
    message: "Last name is required.",
  }),
  role: roleSchema,
});

const schemaByAction = {
  Create: createSchema,
  Edit: editSchema,
};

const UserForm = ({
  isOpen,
  setIsOpen,
  createUser,
  editUser,
  selectedUser,
  action = "Create",
}: UserFormProps) => {
  const { customerId } = useParams();
  const { data: roles, isLoading: isRolesLoading } = useGetRoles(customerId);
  const {
    formState,
    watch,
    control,
    register,
    handleSubmit,
    reset,
    getValues,
  } = useForm<UserFormValues>({
    ...defaultFormConfig<UserFormValues>(schemaByAction[action]),
    defaultValues: DEFAULT_VALUES,
  });
  const { isSubmitting, isDirty, isValid, errors } = formState;

  const renderCreateFields = () => (
    <>
      <FormGroup>
        <TextField
          type="email"
          label="Email"
          error={!!errors.email}
          {...register("email", {
            required: "Email is required",
          })}
          required
        />
        {!!errors.email && (
          <FormHelperText error>
            {errors.email.message as string}
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          type="password"
          label="Temporary Password"
          error={!!errors.password}
          {...register("password", {
            required: "Temporary password is required",
          })}
          required
        />
        {!!errors.password && (
          <FormHelperText error>
            {errors.password.message as string}
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          type="password"
          label="Confirm Password"
          error={!!errors.confirmPassword}
          {...register("confirmPassword", {
            required: "Please confirm the password",
            validate: (value) =>
              value === getValues("password") || "Passwords do not match.",
          })}
          required
        />
        {!!errors.confirmPassword && (
          <FormHelperText error>
            {errors.confirmPassword.message as string}
          </FormHelperText>
        )}
      </FormGroup>
      <PasswordRules
        password={watch("password")}
        confirmPassword={watch("confirmPassword")}
      />
    </>
  );

  const onSubmit = async (formValues: UserFormValues) => {
    if (action === "Create") {
      await createUser(formValues);
    } else {
      await editUser(formValues);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen || action !== "Edit" || !selectedUser) return;
    reset({
      ...selectedUser,
      role: roles?.find((role: Role) => role.roleId === selectedUser.roleId),
    });
  }, [action, isOpen, reset, roles, selectedUser]);

  return (
    <DialogForm
      formId={FORM_ID}
      dialogTitle={`${action} User`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSubmitting={isSubmitting}
      isCancelDisabled={isSubmitting}
      isSubmitDisabled={!isDirty || !isValid}
      afterClose={reset}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack marginTop={1} spacing={2}>
          <div className="flex space-x-4">
            <FormGroup className="flex-grow">
              <TextField
                label="First Name"
                error={!!errors.firstName}
                {...register("firstName", {
                  required: "First name is required",
                })}
                required
              />
              {!!errors.firstName && (
                <FormHelperText error>
                  {errors.firstName.message as string}
                </FormHelperText>
              )}
            </FormGroup>
            <FormGroup className="flex-grow">
              <TextField
                label="Last Name"
                error={!!errors.lastName}
                {...register("lastName", {
                  required: "Last name is required",
                })}
                required
              />
              {!!errors.lastName && (
                <FormHelperText error>
                  {errors.lastName.message as string}
                </FormHelperText>
              )}
            </FormGroup>
          </div>
          {action === "Create" && renderCreateFields()}
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field: { ref: roleRef, ...field } }) => (
                <Autocomplete
                  {...field}
                  id="role"
                  options={roles ?? []}
                  onChange={(_: SyntheticEvent, data: Role | null) => {
                    field.onChange(data);
                  }}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      name="role"
                      label="Role"
                      error={!!errors.role}
                      inputRef={roleRef}
                      required
                    />
                  )}
                  getOptionLabel={(option: Role) => option.name}
                  isOptionEqualToValue={(option: Role, value: Role) => {
                    return option.roleId === value.roleId;
                  }}
                  loading={isRolesLoading}
                />
              )}
              defaultValue={DEFAULT_VALUES.role}
              disabled={isRolesLoading}
              rules={{ required: "Role is required." }}
            />
            {!!errors.role && (
              <FormHelperText error>
                {errors.role.message as string}
              </FormHelperText>
            )}
          </FormGroup>
        </Stack>
      </form>
    </DialogForm>
  );
};

export default UserForm;
