import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";
import {
  CreateUserRequest,
  DeleteUserRequest,
  EditUserRequest,
  EditUserResponse,
  GetUsersResponse,
  createUser,
  deleteUser,
  editUser,
  getUsers,
} from "../api/user";
import User from "../types/user/User";

export const useUsers = (
  customerId: string | undefined,
  ready: boolean = true
) => {
  return useQuery<GetUsersResponse, Error>({
    queryKey: ["users"],
    queryFn: () => getUsers(customerId!),
    enabled: !!customerId && ready,
  });
};

export const useCreateUser = (customerId: string) => {
  const mutationOptions: UseMutationOptions<User | undefined, Error, CreateUserRequest> = {

  mutationFn: (request: CreateUserRequest) => {
    return createUser(customerId, request);
  },
  }
  return useMutation(mutationOptions);
};

export const useEditUser = (customerId: string) => {
  const mutationOptions: UseMutationOptions<EditUserResponse, Error, EditUserRequest> = {

  mutationFn: (request: EditUserRequest) => {
    return editUser(customerId, request);
  },
  }
  return useMutation(mutationOptions);
};

export const useDeleteUser = (customerId: string) => {
  const mutationOptions: UseMutationOptions<void | undefined, Error, DeleteUserRequest> = {

  mutationFn: (request: DeleteUserRequest) => {
    return deleteUser(customerId, request);
  },
  }
  return useMutation(mutationOptions);
};
