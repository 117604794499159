import Typography from "@mui/material/Typography";

const getRowRange = (
  page: number | undefined,
  rowsPerPage: number | undefined,
  rowCount?: number,
  estimatedRowCount?: number
) => {
  const start = getStartRow(page, rowsPerPage, rowCount, estimatedRowCount);
  const end = getEndRow(page, rowsPerPage, rowCount, estimatedRowCount);

  return `${start.toLocaleString()} - ${end.toLocaleString()}`;
};

const getRowCount = (
  page: number = 0,
  rowsPerPage: number = 0,
  rowCount?: number,
  approximate: boolean = false,
  startApproximatingAt: number = 0,
  endRow?: number
) => {
  if (rowCount && (!approximate || rowCount < startApproximatingAt)) {
    return (rowCount ?? page * rowsPerPage).toLocaleString();
  }
  if (rowCount) {
    return `${rowCount.toLocaleString()}+`;
  }
  if (endRow) {
    return `${endRow.toLocaleString()}+`;
  }
  return "0";
};

export interface PaginationInfoProps {
  className?: string;
  page: number | undefined;
  rowCount?: number;
  estimatedRowCount?: number;
  rowsPerPage: number | undefined;
  approximate?: boolean;
  startApproximatingAt?: number;
  isLoading?: boolean;
}

const getStartRow = (
  page: number | undefined,
  rowsPerPage: number | undefined,
  rowCount: number | undefined,
  estimatedRowCount?: number
) => {
  if (page == null || !rowsPerPage || (!rowCount && !estimatedRowCount)) {
    return 0;
  }
  return page * rowsPerPage + 1;
};

const getEndRow = (
  page: number | undefined,
  rowsPerPage: number | undefined,
  rowCount: number | undefined,
  estimatedRowCount?: number
) => {
  const currentPage = page ? page + 1 : 0;

  if (
    page == null ||
    rowsPerPage == null ||
    (rowCount == null && estimatedRowCount == null) ||
    (rowCount === 0 && estimatedRowCount == null)
  ) {
    return 0;
  }
  if (estimatedRowCount) {
    return currentPage * rowsPerPage;
  }
  if (currentPage === 0 && rowCount) {
    return Math.min(rowCount, rowsPerPage);
  } else if (rowCount && currentPage * rowsPerPage > rowCount) {
    return rowCount;
  } else {
    return currentPage * rowsPerPage;
  }
};

interface PaginationDetailsProps {
  page: number | undefined;
  rowsPerPage: number | undefined;
  rowCount?: number;
  estimatedRowCount?: number;
  approximate?: boolean;
  startApproximatingAt?: number;
}

const PaginationDetails = ({
  page,
  rowsPerPage,
  rowCount,
  estimatedRowCount,
  approximate,
  startApproximatingAt,
}: PaginationDetailsProps) => {
  return (
    <Typography variant="body2" display="inline">
      {getRowRange(page, rowsPerPage, rowCount, estimatedRowCount)} of{" "}
      {getRowCount(
        page,
        rowsPerPage,
        rowCount,
        approximate,
        startApproximatingAt,
        estimatedRowCount
      )}
    </Typography>
  );
};

const PaginationInfo = ({
  className = "",
  approximate = false,
  startApproximatingAt = 0,
  isLoading = false,
  ...props
}: PaginationInfoProps) => {
  return (
    <div className={`${className} flex items-center`}>
      {isLoading ? (
        <Typography variant="body2">Loading...</Typography>
      ) : (
        <PaginationDetails
          {...props}
          approximate={approximate}
          startApproximatingAt={startApproximatingAt}
        />
      )}
    </div>
  );
};

export default PaginationInfo;
