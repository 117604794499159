import { AxiosError } from "axios";
import ErrorMessage from "../../types/feedback/ErrorMessage";

export type ApiResponseData = {
  errors?: {
    [key: string]: string[];
  };
};

export type FieldError = { fieldName: string; errorMessage: string };

export default class ApiErrors {
  private errors?: Map<string, string[]>;

  constructor(
    errors?: { [fieldName: string]: string[] } | Map<string, string[]>,
  ) {
    if (!errors) return;
    if (errors instanceof Map) this.errors = errors;
    else {
      const converted: [string, string[]][] = [];
      for (const fieldName in errors) {
        const errorMessages: string[] = errors[fieldName];
        converted.push([fieldName, errorMessages]);
      }
      this.errors = new Map<string, string[]>(converted);
    }
  }

  public getFieldError(fieldName: string): string | undefined {
    return !this.isFieldEmpty(fieldName)
      ? this.get(fieldName)[0] ?? ErrorMessage.UNKNOWN
      : undefined;
  }

  public get(fieldName: string): string[] {
    if (!this.errors) return [];
    if (!this.errors.has(fieldName)) return [];
    return this.errors.get(fieldName) as string[];
  }

  public set(fieldName: string, errorMessages: string[]): void {
    this.errors?.set(fieldName, errorMessages);
  }

  public clear(): void {
    this.errors?.clear();
  }

  public isFieldEmpty(fieldName: string): boolean {
    return !this.errors?.has(fieldName);
  }

  public isEmpty(): boolean {
    return !this.errors;
  }

  public toArray(): FieldError[] {
    if (!this.errors) return [];
    const asArray: FieldError[] = [];
    this.errors.forEach((errorMessages: string[], fieldName: string) => {
      if (errorMessages.length <= 0) return;
      asArray.push({ fieldName, errorMessage: errorMessages[0] });
    });
    return asArray;
  }
}

export const getAllAxiosErrorMessages = (
  error: AxiosError<ApiResponseData | undefined>,
) => {
  const noResponse = {
    "root.generic": [ErrorMessage.NO_RESPONSE],
  };
  if (!navigator.onLine) {
    return {
      "root.generic": [ErrorMessage.NETWORK_ERROR],
    };
  } else if (!error.response?.data) {
    return noResponse;
  } else if (error.response.data.errors) {
    if (error.response?.data.errors?.generalErrors) {
      return {
        ...error.response?.data.errors,
        "root.generic": error.response?.data.errors?.generalErrors,
      };
    }
    return error.response?.data.errors;
  } else {
    return noResponse;
  }
};

export const handleApiError: (error: unknown) => ApiErrors = (
  error: unknown,
) => {
  let errorMessages: { [fieldName: string]: string[] };
  if (error instanceof AxiosError) {
    errorMessages = getAllAxiosErrorMessages(error);
  } else if (error instanceof Error) {
    errorMessages = {
      "root.generic": [error.message],
    };
  } else {
    errorMessages = {
      "root.generic": [ErrorMessage.UNKNOWN],
    };
  }
  return new ApiErrors(errorMessages);
};
