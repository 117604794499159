import Grid from "@mui/material/Unstable_Grid2";
import { SyntheticEvent } from "react";
import { type FieldValues, type Path } from "react-hook-form";
import type Permissions from "../../types/permission/permission";
import PermissionGroup, {
  PermissionGroupProps,
} from "../permissions/PermissionGroup";

export interface PermissionsInputProps<F extends FieldValues>
  extends Omit<
    PermissionGroupProps,
    "onChange" | "defaultValue" | "label" | "resource" | "checkboxes"
  > {
  name: Path<F>;
  onChange: (value: Set<string>) => void;
  onBlur: () => void;
  defaultValue: Set<string>;
  value: Set<string>;
  permissions?: Permissions;
  isLoading?: boolean;
}

const PermissionsInput = <F extends FieldValues>({
  defaultValue,
  onChange,
  onBlur,
  permissions,
  isLoading = false,
  value,
  ...props
}: PermissionsInputProps<F>) => {
  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    permission: string
  ) => {
    const checked = (event.currentTarget as HTMLInputElement).checked;
    const newPermissions = new Set<string>(value);
    if (checked) {
      newPermissions.add(permission);
    } else {
      newPermissions.delete(permission);
    }
    onChange(newPermissions);
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  const getCheckboxLabel = (value: string) => {
    return value.split(":")[1];
  };

  return (
    <Grid container spacing={12}>
      <Grid xs={6}>
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.auditLogPermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Audit Logs"
          resource="auditlog"
        />
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.interactionPermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Interactions"
          resource="interactions"
        />
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.sitePermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Sites"
          resource="sites"
        />
      </Grid>
      <Grid xs={6}>
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.customersPermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Customer"
          resource="customer"
        />
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.rolePermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Roles"
          resource="roles"
        />
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.userPermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Users"
          resource="users"
        />
        <PermissionGroup
          {...props}
          className="mb-4"
          checkboxes={permissions?.connectorPermissions ?? []}
          getCheckboxLabel={getCheckboxLabel}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          label="Connectors"
          resource="connectors"
        />
      </Grid>
    </Grid>
  );
};

export default PermissionsInput;
