import Typography from "@mui/material/Typography";

export interface PlayerTimeState {
  currentTime?: string;
  duration: string;
}

export interface TimeDisplayProps {
  timeState?: PlayerTimeState;
}

const TimeDisplay = ({ timeState }: TimeDisplayProps) => {
  if (!timeState) return null;

  return (
    <div className="min-w-fit flex-1 h-full m-auto px-4">
      <Typography variant="body2">
        {timeState.currentTime ? timeState.currentTime : "00:00:00"} /{" "}
        {timeState.duration}
      </Typography>
    </div>
  );
};

export default TimeDisplay;
