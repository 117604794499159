import { getDashboard, GetDashboardDataResponse } from "../api/dashboard";
import { useQuery } from "@tanstack/react-query";

export const useDashboardData = (
  customerId: string | undefined,
  readyToQuery: boolean = true
) => {
  return useQuery<GetDashboardDataResponse | undefined, Error>({
    queryKey: ["customer", customerId],
    queryFn: () => getDashboard(customerId!),
    enabled: !!customerId && readyToQuery,
  });
}