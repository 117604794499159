import { z } from "zod";
import Connector, { EditDefaultValuesGetter, IConnectorRow } from "./Connector";
import { ReactNode } from "react";
import { UseFormRegister, Control, FieldErrors } from "react-hook-form";
import Five9ConfigForm from "../forms/Five9ConfigForm";
import { ConnectorWizardFormValues } from "../forms/ConnectorWizard";

export interface Five9ConnectorRow extends Omit<IConnectorRow, "connectorDetails"> {
  connectorDetails: {
    folderName: string;
    reportName: string;
  };
}

type FlattenedFive9ConnectorRow = Omit<
  IConnectorRow,
  "connectorDetails"
> & {
  folderName: string;
  reportName: string;
};

export function flattenFive9ConnectorDetails(
  response: Five9ConnectorRow
): FlattenedFive9ConnectorRow {
  const { connectorDetails, ...rest } = response;
  return {
    ...rest,
    folderName: connectorDetails.folderName,
    reportName: connectorDetails.reportName,
  };
}

export const five9Schema = z.object({
  username: z
    .string({
      required_error: "Username is required.",
    })
    .min(1, {
      message: "Username is required.",
    }),
  password: z
    .string({
      required_error: "Password is required.",
    })
    .min(1, {
      message: "Password is required.",
    }),
  reportName: z
    .string({
      required_error: "Report name is required.",
    })
    .min(1, {
      message: "Report name is required.",
    }),
  folderName: z
    .string({
      required_error: "Folder name is required.",
    })
    .min(1, {
      message: "Folder name is required.",
    }),
});
export type Five9FormValues = z.infer<typeof five9Schema>;

export default class Five9Connector implements Connector<Five9FormValues> {
  public static readonly Type = "Five9";

  public get fields(): string[] {
    return [
      ...five9Schema
        .keyof()
        .options.map((key) => `${Five9Connector.Type}.${key}`),
    ];
  }

  public getEditDefaultValues: EditDefaultValuesGetter<Five9FormValues> = (
    values: Partial<Five9FormValues>
  ): Five9FormValues => {
    return {
      username: "",
      password: "",
      reportName: values.reportName ?? "",
      folderName: values.folderName ?? "",
    };
  };

  public get type(): string {
    return Five9Connector.Type;
  }

  public get defaultValues(): Five9FormValues {
    return {
      username: "",
      password: "",
      reportName: "",
      folderName: "",
    };
  }

  public renderFormFields(
    register: UseFormRegister<ConnectorWizardFormValues>,
    _: Control<ConnectorWizardFormValues>,
    errors?: FieldErrors<ConnectorWizardFormValues>
  ): ReactNode {
    return (
      <Five9ConfigForm
        defaultValues={this.defaultValues}
        register={register}
        errors={errors}
      />
    );
  }
}
