import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export interface InteractionMetadataViewProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  interactionMetadata: Record<string, unknown>;
  isLoading?: boolean;
  error?: Error | null;
}

interface InteractionMetadata {
  key: string;
  value: unknown;
  source: "Continuity Replay" | "Source";
  id: string;
}

const InteractionMetadataView = ({
  isOpen,
  setIsOpen,
  interactionMetadata,
  isLoading = false,
  error,
}: InteractionMetadataViewProps) => {
  const [interactionData, setInteractionData] = useState<InteractionMetadata[]>(
    []
  );

  useEffect(() => {
    const common = Object.keys(interactionMetadata).map((key: string) => ({
      id: key,
      source: key.startsWith("src_") ? "Source" : "Continuity Replay",
      key: key.startsWith("src_") ? key.replace("src_", "") : key,
      value: interactionMetadata[key]
    }) as InteractionMetadata);
    
    common.sort((a, b) => 
      b.source.localeCompare(a.source) || a.key.localeCompare(b.key)
    );
    setInteractionData(common);
  }, [interactionMetadata]);

  const columns: GridColDef[] = [
    {
      field: "key",
      headerName: "Key",
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Interaction Metadata</DialogTitle>
      <DialogContent>
        {error && <div>Error: {error.message}</div>}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingIndicator size={36} />
          </div>
        )}
        <DataGrid columns={columns} rows={interactionData} density="compact" />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InteractionMetadataView;
