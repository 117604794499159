import { IconButton, IconButtonProps } from "@mui/material";
import AuthorizedWrapper, {
  AuthorizedComponentProps,
} from "../AuthorizedWrapper/AuthorizedWrapper";

export type AuthorizedIconButtonProps = AuthorizedComponentProps &
  IconButtonProps;

const AuthorizedIconButton = ({
  children,
  permission,
  unauthorizedAction,
  ...props
}: AuthorizedIconButtonProps) => (
  <AuthorizedWrapper
    permission={permission}
    unauthorizedAction={unauthorizedAction}
    Component={IconButton}
    ComponentProps={props}
  >
    {children}
  </AuthorizedWrapper>
);

export default AuthorizedIconButton;
