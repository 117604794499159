import type {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  CreateRoleRequest,
  DeleteRoleRequest,
  EditRoleRequest,
} from "../../api/role";
import DeleteDialog from "../../components/common/DeleteDialog/DeleteDialog";
import ResultsPage from "../../components/common/ResultsPage/ResultsPage";
import CreateRoleForm, {
  CreateRoleValues,
} from "../../components/roles/CreateRoleForm";
import EditRoleForm, {
  EditRoleValues,
} from "../../components/roles/EditRoleForm";
import { UseShowAlert, useAlert } from "../../context/AlertContext";
import { useGetPermissions } from "../../hooks/usePermission";
import {
  useCreateRole,
  useDeleteRole,
  useEditRole,
  useGetRoles,
} from "../../hooks/useRole";
import { useSites } from "../../hooks/useSite";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import type Role from "../../types/role/Role";
import Site from "../../types/site/Site";
import Typography from "@mui/material/Typography";

const siteNamesGetter = (sites: Site[], role: Role) => {
  if (role.allSites) {
    return "All Sites";
  }
  if (role.siteIds == null) return "";
  return sites
    .filter((site) => role.siteIds!.includes(site.siteId))
    .map((site) => site.name)
    .join(", ");
};

export const getColumns = (sites: Site[]): GridColDef[] => [
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
    maxWidth: 400,
    cellClassName: "flex items-center",
  },
  {
    field: "siteNames",
    headerName: "Site Names",
    valueGetter: (_: string[], row: Role) => siteNamesGetter(sites, row),
    minWidth: 200,
    maxWidth: 400,
    cellClassName: "flex items-center",
  },
  {
    field: "permissions",
    headerName: "Permissions",
    renderCell: ({ value: permissions }: GridRenderCellParams<Role>) => {
      return (
        <div className="flex flex-col space-y-2">
          {permissions?.map((permission: string) => (
            <Typography key={permission} variant="body2">
              {permission}
            </Typography>
          ))}
        </div>
      );
    },
    minWidth: 150,
    maxWidth: 300,
    cellClassName: "flex items-center !py-2",
  },
];

export const createValuesToRequest = (
  values: CreateRoleValues
): CreateRoleRequest => ({
  siteIds: values.allSites ? [] : values.sites.map((site) => site.siteId),
  name: values.name,
  permissions: Array.from(values.permissions),
  allSites: values.allSites,
});

export const editValuesToRequest = (
  roleId: string,
  values: EditRoleValues
): EditRoleRequest => ({
  roleId,
  siteIds: values.allSites ? [] : values.sites.map((site) => site.siteId),
  name: values.name,
  permissions: values.permissions,
  allSites: values.allSites,
});

export const createRole = async (
  formValues: CreateRoleValues,
  createRoleMutation: UseMutationResult<
    Role | undefined,
    unknown,
    CreateRoleRequest,
    unknown
  >,
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>,
  setIsCreateFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showAlert: UseShowAlert
) => {
  try {
    const role = await createRoleMutation.mutateAsync(
      createValuesToRequest(formValues)
    );
    if (!role) {
      throw new Error("Failed to create role.");
    }
    setRoles((prevRoles: Role[]) => [...prevRoles, role]);
    showAlert("Role created successfully.", "success");
    setIsCreateFormOpen(false);
  } catch (error: unknown) {
    if (error instanceof Error) {
      showAlert(error.message, "error");
    } else {
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  }
};

export const editRole = async (
  formValues: EditRoleValues,
  editRoleMutation: UseMutationResult<
    Role | undefined,
    unknown,
    EditRoleRequest,
    unknown
  >,
  selectedRoleId: string | null,
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>,
  setIsEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showAlert: UseShowAlert
) => {
  try {
    const role = await editRoleMutation.mutateAsync(
      editValuesToRequest(selectedRoleId!, formValues)
    );
    if (!role) {
      throw new Error("Failed to update role.");
    }
    setRoles((prevRoles: Role[]) => {
      return [
        ...prevRoles.filter((role: Role) => role.roleId !== selectedRoleId!),
        role,
      ];
    });
    showAlert("Role edited successfully.", "success");
    setIsEditFormOpen(false);
  } catch (error: unknown) {
    if (error instanceof Error) {
      showAlert(error.message, "error");
    } else {
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  }
};

export const deleteRole = async (
  deleteRoleMutation: UseMutationResult<
    Role | undefined,
    unknown,
    DeleteRoleRequest,
    unknown
  >,
  selectedRoleId: string | null,
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>,
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showAlert: UseShowAlert
) => {
  try {
    await deleteRoleMutation.mutateAsync({ roleId: selectedRoleId! });
    setRoles((prevRoles: Role[]) =>
      prevRoles.filter((role) => role.roleId !== selectedRoleId)
    );
    showAlert("Role deleted successfully.", "success");
    setIsDeleteDialogOpen(false);
  } catch (error: unknown) {
    if (error instanceof Error) {
      showAlert(error.message, "error");
    } else {
      showAlert(ErrorMessage.UNKNOWN, "error");
    }
  }
};

const Roles = () => {
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const { customerId } = useParams();
  const showAlert = useAlert();
  const createRoleMutation = useCreateRole(customerId!);
  const editRoleMutation = useEditRole(customerId!);
  const deleteRoleMutation = useDeleteRole(customerId!);
  const [roles, setRoles] = useState<Role[]>([]);

  const { data: allSitesResponse, isLoading: sitesLoading } =
    useSites(customerId);
  const sites: Site[] = useMemo(
    () => allSitesResponse?.sites ?? [],
    [allSitesResponse?.sites]
  );
  const { data: permissions, isLoading: permissionsLoading } =
    useGetPermissions();
  const {
    data: rolesData,
    isError,
    isLoading,
    error,
  } = useGetRoles(customerId);

  useEffect(() => {
    if (rolesData){
    // Remove custom permissions for display
    const updatedRoles = rolesData.map((role: Role) => ({
      ...role,
      permissions: role.permissions.filter((permission: string) =>
        !(
          permission.startsWith("customization:") ||
          permission.includes("customers:*") ||
          permission.includes("customers:Create") ||
          permission.includes("customers:Delete") 
        )
      ),
    }));
    setRoles(updatedRoles);
  }
  }, [rolesData]);

  const memoizedColumns = useMemo(() => getColumns(sites), [sites]);

  if (isError) {
    return (
      <>
        An error occurred:{" "}
        {error instanceof Error ? error.message : String(error)}
      </>
    );
  }

  /* istanbul ignore next */
  const onRowSelected = (rowSelectionModel: GridRowSelectionModel) => {
    const selectedId = rowSelectionModel[rowSelectionModel.length - 1];
    setSelectedRoleId(selectedId as string | null);
  };

  return (
    <div className="h-full flex flex-col">
      <ResultsPage
        permissionResource="roles"
        tableId="roles"
        entityName="Role"
        columns={memoizedColumns}
        rows={roles}
        getRowId={(row: Role) => row.roleId}
        onRowSelectionModelChange={onRowSelected}
        rowSelectionModel={selectedRoleId ? [selectedRoleId] : []}
        loading={isLoading}
        selectedRowId={selectedRoleId}
        setSelectedRowId={setSelectedRoleId}
        setCreateFormOpen={setIsCreateFormOpen}
        setEditFormOpen={setIsEditFormOpen}
        setDeleteDialogOpen={setIsDeleteDialogOpen}
        getRowHeight={() => "auto"}
      />
      <CreateRoleForm
        isOpen={isCreateFormOpen}
        setIsOpen={setIsCreateFormOpen}
        permissions={permissions}
        sites={sites}
        createRole={(formValues) =>
          createRole(
            formValues,
            createRoleMutation,
            setRoles,
            setIsCreateFormOpen,
            showAlert
          )
        }
        isPermissionsLoading={permissionsLoading}
        isSitesLoading={sitesLoading}
      />
      <EditRoleForm
        permissions={permissions}
        sites={sites}
        isOpen={isEditFormOpen}
        setIsOpen={setIsEditFormOpen}
        selectedRole={(roles ?? []).find(
          (role) => role.roleId === selectedRoleId
        )}
        isPermissionsLoading={permissionsLoading}
        isSitesLoading={sitesLoading}
        editRole={(formValues) =>
          editRole(
            formValues,
            editRoleMutation,
            selectedRoleId,
            setRoles,
            setIsEditFormOpen,
            showAlert
          )
        }
      />
      <DeleteDialog
        entityName="Role"
        deleteEntity={() =>
          deleteRole(
            deleteRoleMutation,
            selectedRoleId,
            setRoles,
            setIsDeleteDialogOpen,
            showAlert
          )
        }
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
    </div>
  );
};

export default Roles;
