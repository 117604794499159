import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { ChangeEvent, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

export interface ClearableTextFieldProps
  extends Omit<TextFieldProps, "onChange"> {
  defaultValue?: string | null;
  value: string | undefined | null;
  onChange?: (newValue: string | null) => void;
}

const ClearableTextField = ({
  className = "",
  InputProps,
  value: propValue,
  defaultValue: propDefaultValue,
  onChange,
  ...props
}: ClearableTextFieldProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const defaultValue = propDefaultValue ?? "";
  const [localValue, setLocalValue] = useState<string | null | undefined>(
    defaultValue
  );
  const value = propValue ?? localValue;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setLocalValue(newValue);
    onChange?.(newValue);
  };

  const clear = () => {
    setLocalValue(defaultValue);
    onChange?.(defaultValue);
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment
            sx={{
              visibility: hovered ? "visible" : "hidden",
            }}
            position="end"
          >
            <IconButton
              sx={{
                padding: 0.5,
                marginRight: "-0.5rem",
                visibility: value ? undefined : "hidden",
              }}
              aria-label="Clear text"
              edge="end"
              onClick={clear}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ClearableTextField;
