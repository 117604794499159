import User from "../types/user/User";
import { deleteApi, getApi, postApi, putApi } from "./helpers/apiMethods";
import ApiErrors, { handleApiError } from "./helpers/errorHandling";

const path = "users";

export type CreateUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  password: string;
};

export type GetUsersResponse = {
  users: User[];
  errors: ApiErrors;
};

export const getUsers = async (
  customerId: string
): Promise<GetUsersResponse> => {
  try {
    type Response = { users: User[] };
    const response = await getApi<Response>(
      `/v1/customers/${customerId}/${path}`
    );
    if (!customerId) throw new Error("Customer ID is required.");
    const users: User[] = response.data?.users ?? [];
    return { users, errors: new ApiErrors() };
  } catch (error: unknown) {
    const errorMessage = handleApiError(error);
    return { users: [], errors: errorMessage };
  }
};

export const createUser = async (
  customerId: string,
  data: CreateUserRequest
): Promise<User | undefined> => {
  if (!customerId) throw new Error("Customer ID is required.");
  const response = await postApi<User>(
    `/v1/customers/${customerId}/users`,
    data
  );
  return response.data;
};

export type EditUserRequest = {
  userId: string;
  firstName: string;
  lastName: string;
  roleId: string;
};

export type EditUserResponse = {
  user?: User;
};

export const editUser = async (
  customerId: string,
  { userId, ...request }: EditUserRequest
): Promise<EditUserResponse> => {
  const response = await putApi<User>(
    `/v1/customers/${customerId}/users/${userId}`,
    request
  );
  const user: User | undefined = response.data;
  return { user };
};

export interface DeleteUserRequest {
  userId: string;
  username?: string;
}

export const deleteUser = async (
  customerId: string,
  { userId, username }: DeleteUserRequest
): Promise<void> => {
  if (!username) throw new Error("Username is required.");
  await deleteApi<User>(`/v1/customers/${customerId}/users/${userId}`, {
    data: { username },
  });
};
