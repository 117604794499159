import FormHelperText from "@mui/material/FormHelperText";
import Select from "../../common/Select/Select";
import { ConnectorRuleSetField } from "./RuleSetListControl";
import { UseFormReturn } from "react-hook-form";
import { ConnectorWizardFormValues } from "../forms/ConnectorWizard";
import Site from "../../../types/site/Site";
import LoadingIndicator from "../../common/LoadingIndicator/LoadingIndicator";

const mapSiteIdToSite = (siteId?: string, sites?: Site[]): Site | null => {
  if (!sites || !siteId) return null;
  return sites.find((site) => site.siteId === siteId) ?? null;
};

export interface RuleSetListControlViewProps {
  ruleSets: ConnectorRuleSetField[];
  form: UseFormReturn<ConnectorWizardFormValues>;
  defaultSiteId?: string;
  sites: Site[];
  sitesQueryError?: Error | null;
  areSitesLoading?: boolean;
}

const RuleSetListControlView = ({
  ruleSets,
  form,
  defaultSiteId,
  sites,
  sitesQueryError: sitesError,
  areSitesLoading = false,
}: RuleSetListControlViewProps) => {
  const { control, formState } = form;
  const { errors } = formState;

  if (areSitesLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {ruleSets.map((ruleSet, index) => {
        const siteError = errors.ruleSets?.[index]?.site;

        const defaultSite = mapSiteIdToSite(
          ruleSet.siteId ?? defaultSiteId,
          sites
        );

        return (
          <div key={ruleSet.id} className="flex flex-col space-y-4">
            <div className="w-full">
              <Select
                key={ruleSet.id}
                label="Site"
                name={`ruleSets.${index}.site`}
                control={control}
                defaultValue={defaultSite}
                options={sites}
                error={!!sitesError || !!errors.ruleSets?.[index]?.site}
                loading={areSitesLoading}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.customerId === value.customerId
                }
                required
              />
              {(!!sitesError || !!siteError) && (
                <FormHelperText error>
                  {sitesError?.message ??
                    errors.ruleSets?.[index]?.site?.message}
                </FormHelperText>
              )}
            </div>
            {/* <RuleListControl
              key={`${ruleSet.id}-rules`}
              name={`ruleSets.${index}.rules`}
              ruleSetIndex={index}
              control={control}
              register={register}
              errors={errors}
            /> */}
          </div>
        );
      })}
    </div>
  );
};

export default RuleSetListControlView;
