import { Check, Clear } from "@mui/icons-material";
import { ReactNode } from "react";
import {
  MIN_LENGTH,
  MIN_LOWERCASE,
  MIN_NUMBERS,
  MIN_SPECIAL_CHARACTERS,
  MIN_UPPERCASE,
  hasLowerCase,
  hasNumbers,
  hasSpecialCharacters,
  hasUpperCase,
} from "../../../helpers/auth/passwordSchema";

export interface PasswordRuleProps {
  valid?: boolean;
  children: ReactNode;
}

const PasswordRule = ({ valid = false, children }: PasswordRuleProps) => {
  return (
    <li className={valid ? "text-green-600" : "text-red-600"}>
      {valid ? <Check /> : <Clear />}
      {children}
    </li>
  );
};

export interface PasswordRulesProps {
  password?: string;
  confirmPassword?: string;
}

const PasswordRules = ({ password, confirmPassword }: PasswordRulesProps) => {
  return (
    <ul>
      <PasswordRule valid={password === confirmPassword}>
        Passwords must match
      </PasswordRule>
      <PasswordRule valid={(password?.length ?? 0) >= MIN_LENGTH}>
        At least {MIN_LENGTH} characters
      </PasswordRule>
      <PasswordRule valid={!!password && hasLowerCase(password)}>
        At least {MIN_LOWERCASE} lowercase letter(s)
      </PasswordRule>
      <PasswordRule valid={!!password && hasUpperCase(password)}>
        At least {MIN_UPPERCASE} uppercase letter(s)
      </PasswordRule>
      <PasswordRule valid={!!password && hasNumbers(password)}>
        At least {MIN_NUMBERS} number(s)
      </PasswordRule>
      <PasswordRule valid={!!password && hasSpecialCharacters(password)}>
        At least {MIN_SPECIAL_CHARACTERS} special character(s)
      </PasswordRule>
    </ul>
  );
};

export default PasswordRules;
