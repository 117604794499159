import { createContext } from "react";
import { FieldLabel } from "./FieldLabelContext";

export interface IFieldLabelStorageContext {
  getFieldLabelsByTable: (
    tableId: string,
    customerId?: string
  ) => Map<string, FieldLabel>;
  saveFieldLabelsToTable: (
    tableId: string,
    fieldLabels: Map<string, FieldLabel>,
    customerId?: string
  ) => void;
}

const FieldLabelStorageContext = createContext<IFieldLabelStorageContext>({
  getFieldLabelsByTable: () => {
    throw new Error("getFieldLabelsByTable not implemented");
  },
  saveFieldLabelsToTable: () => {
    throw new Error("saveFieldLabelsToTable not implemented");
  },
});

export default FieldLabelStorageContext;
