import {
  FormGroup,
  TextField,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import DialogForm from "../../components/common/DialogForm/DialogForm";
import defaultFormConfig from "../../helpers/validation/defaultFormConfig";

const FORM_ID = "site-form";

const schema = z.object({
  name: z.string().min(1, { message: "Name is required." }),
});

export type SiteFormValues = {
  name: string;
};

export type SiteFormProps = {
  isOpen: boolean;
  action: "Create" | "Edit";
  setIsOpen: (isOpen: boolean) => void;
  createSite: (data: SiteFormValues) => void;
  editSite: (data: SiteFormValues) => void;
  formValues?: SiteFormValues;
};

const DEFAULT_CREATE_VALUES: SiteFormValues = {
  name: ""
};

const SiteForm = ({
  action,
  isOpen,
  setIsOpen,
  createSite,
  editSite,
  formValues,
}: SiteFormProps) => {
  const { register, handleSubmit, reset, formState } = useForm<SiteFormValues>({
    ...defaultFormConfig<SiteFormValues>(schema),
    defaultValues: formValues || DEFAULT_CREATE_VALUES,
  });

  const { isSubmitting, isDirty, errors } = formState;

  useEffect(() => {
    if (isOpen && action === "Edit" && formValues) {
      reset(formValues);
    }
  }, [action, formValues, isOpen, reset]);

  const resetForm = useCallback(() => {
    reset(DEFAULT_CREATE_VALUES);
  }, [reset]);

  return (
    <DialogForm
      formId={FORM_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle={`${action} Site`}
      isSubmitting={isSubmitting}
      isSubmitDisabled={!isDirty}
      afterClose={resetForm} // Reset to default after close
    >
      <form
        id={FORM_ID}
        onSubmit={handleSubmit(action === "Create" ? createSite : editSite)}
        noValidate
      >
        <FormGroup>
          <TextField
            label="Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            required
            {...register("name", {
              required: "Name is required",
            })}
          />
        </FormGroup>
      </form>
    </DialogForm>
  );
};

export default SiteForm;
