import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { FormEventHandler } from "react";
import { Control, FieldErrors } from "react-hook-form";
import { DatePicker } from "../../components/common/DatePicker/DatePicker";
import User from "../../types/user/User";
import { AuditLogFilterValues } from "./AuditLogFilters";

export interface AuditLogFilterFormProps {
  formId: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  control: Control<AuditLogFilterValues>;
  setIsUserQueryReady: (isReady: boolean) => void;
  errors: FieldErrors<AuditLogFilterValues>;
  userOptions: User[];
  defaultValues: AuditLogFilterValues;
  isLoading?: boolean;
}

const AuditLogFilterForm = ({
  formId,
  onSubmit,
  control,
  // setIsUserQueryReady,
  // isLoading = false,
  // userOptions,
  // defaultValues,
  errors,
}: AuditLogFilterFormProps) => {
  return (
    <form id={formId} onSubmit={onSubmit}>
      <Stack
        className="flex flex-nowrap items-center"
        spacing={2}
        direction="row"
      >
        {/* <FormControl>
          <Controller
            name="user"
            control={control}
            render={({ field }) => {
              const { ref, ...rest } = field;
              return (
                <AsyncSelect<AuditLogFilterValues, User>
                  {...rest}
                  className="min-w-72"
                  label="User"
                  name="user"
                  setIsReady={setIsUserQueryReady}
                  error={!!errors.user}
                  loading={isLoading}
                  options={userOptions}
                  getOptionLabel={(option: User) => option.email}
                  isOptionEqualToValue={(option: User, value: User) =>
                    option.userId === value.userId
                  }
                  control={control}
                  limitTags={2}
                />
              );
            }}
          />
          {errors.user && (
            <FormHelperText error>{errors.user.message}</FormHelperText>
          )}
        </FormControl> */}
        <FormControl>
          <DatePicker
            name="from"
            control={control}
            label="From"
            disableFuture
          />
          {errors.from && (
            <FormHelperText error>{errors.from.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <DatePicker name="to" control={control} label="To" disableFuture />
          {errors.to && (
            <FormHelperText error>{errors.to.message}</FormHelperText>
          )}
        </FormControl>
        {/* <FormControl>
          <HttpMethodSelect
            className="w-60"
            name="httpMethod"
            label="HTTP Method"
            control={control}
            defaultValue={defaultValues.httpMethod}
            limitTags={2}
          />
        </FormControl> */}
      </Stack>
    </form>
  );
};

export default AuditLogFilterForm;
