import { useSearchParams } from "react-router-dom";
import Interaction from "../../../types/interaction/Interaction";
import Tooltip from "@mui/material/Tooltip";
import { AltRoute } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export interface SegmentButtonProps {
  interaction: Interaction;
}

const SegmentButton = ({ interaction }: SegmentButtonProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (
    !interaction.masterInteractionId ||
    !interaction.sourceId ||
    interaction.masterInteractionId === interaction.sourceId
  ) {
    return null;
  }

  const addSegmentFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(
      "masterInteractionId",
      interaction.masterInteractionId!
    );
    setSearchParams(newSearchParams);
  };

  return (
    <Tooltip title="View segments">
      <IconButton onClick={addSegmentFilter}>
        <AltRoute />
      </IconButton>
    </Tooltip>
  );
};

export default SegmentButton;
