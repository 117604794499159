import { InfoOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColDef, GridInitialState, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import ResultsPage, {
  ResultsPageProps,
} from "../../components/common/ResultsPage/ResultsPage";
import { useAlert } from "../../context/AlertContext";
import { IAuditLog } from "../../types/audit-logs/auditLog";
import DictionaryGridView from "../../components/common/DataTable/DictionaryGridView";
import { RowSelectorMode } from "../../types/datatable/RowSelectorMode";

export interface AuditLogResultsViewProps
  extends Omit<
    ResultsPageProps<IAuditLog>,
    | "columns"
    | "permissionResource"
    | "tableId"
    | "entityName"
    | "selectedRowId"
    | "setSelectedRowId"
  > {
  selectedAuditLogId: string | null;
  setSelectedAuditLogId: (id: string | null) => void;
  setIsViewModalOpen: (isOpen: boolean) => void;
  rows: IAuditLog[];
  error?: Error | null;
  isLoading?: boolean;
}

const isGuid = (guid: string | undefined) => {
  if (!guid) return false;
  try {
    const guidSchema = z.string().uuid();
    guidSchema.parse(guid);
    return true;
  } catch {
    return false;
  }
};

const findEndpoint = (path: string | undefined) => {
  if (!path) return;
  const trimmedPath = path.replace(
    /(?<leadingSlash>^\/)|(?<trailingSlash>\/$)/g,
    ""
  );
  return trimmedPath
    .split("/")
    .filter((x) => !isGuid(x))
    .pop();
};

const AuditLogResultsView = ({
  selectedAuditLogId,
  setSelectedAuditLogId,
  setIsViewModalOpen,
  rows,
  error,
  isLoading,
  ...props
}: AuditLogResultsViewProps) => {
  const showAlert = useAlert();
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "auditLogId",
        headerName: "ID",
        minWidth: 150,
        maxWidth: 350,
      },
      {
        field: "event",
        headerName: "Event",
        valueGetter: (_: string, row: IAuditLog) => {
          const { httpMethod, path } = row;
          const endpoint = findEndpoint(path);
          if (!httpMethod) return "N/A";
          if (!endpoint) return httpMethod;
          return `${httpMethod} ${endpoint}`;
        },
        hideable: false,
        minWidth: 150,
        maxWidth: 350,
      },
      {
        field: "payload",
        headerName: "Changes",
        renderCell: (params) => <DictionaryGridView {...params} />,
      },
      {
        field: "resourceId",
        headerName: "Resource ID",
      },
      {
        field: "date",
        headerName: "Event Time",
        valueFormatter: (value: string) => new Date(value).toLocaleString(),
        hideable: false,
        minWidth: 175,
        maxWidth: 225,
      },
      {
        field: "userName",
        headerName: "User",
        hideable: false,
        minWidth: 200,
        maxWidth: 400,
      },
      {
        field: "httpMethod",
        headerName: "HTTP Method",
        minWidth: 100,
        maxWidth: 200,
      },
      {
        field: "statusCode",
        headerName: "Status Code",
        minWidth: 100,
        maxWidth: 125,
      },
      {
        field: "path",
        headerName: "Full URL Path",
        cellClassName: "break-words",
        minWidth: 150,
        maxWidth: 500,
      },
      {
        field: "queryString",
        headerName: "Query String",
        cellClassName: "break-words",
        minWidth: 150,
      },
      {
        field: "ipAddress",
        headerName: "IP Address",
        minWidth: 115,
        maxWidth: 275,
      },
      {
        field: "View Log",
        headerName: "",
        type: "actions",
        getActions: ({ row }: GridRowParams<IAuditLog>) => {
          return [
            <IconButton
              key={`${row.auditLogId}-view-btn`}
              onClick={() => {
                setSelectedAuditLogId(row.auditLogId);
                setIsViewModalOpen(true);
              }}
            >
              <InfoOutlined />
            </IconButton>,
          ];
        },
        minWidth: 50,
        maxWidth: 100,
      },
    ],
    [setIsViewModalOpen, setSelectedAuditLogId]
  );
  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel: {
        auditLogId: false,
        statusCode: false,
        queryString: false,
        httpMethod: false,
        path: false,
      },
    },
  };

  useEffect(() => {
    if (!error) return;
    showAlert(error.message, "error");
  }, [error, showAlert]);

  return (
    <ResultsPage
      {...props}
      permissionResource="auditlogs"
      tableId="audit-logs"
      entityName="Audit Log"
      paginationMode="server"
      columns={columns}
      rows={rows}
      selectedRowId={selectedAuditLogId}
      setSelectedRowId={setSelectedAuditLogId}
      getRowId={(row: IAuditLog) => row.auditLogId}
      loading={isLoading}
      initialState={initialState}
      disableCreatable
      disableEditable
      disableDeletable
      hideDefaultButtons
      rowSelectorMode={RowSelectorMode.MULTIPLE}
    />
  );
};

export default AuditLogResultsView;
