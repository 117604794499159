import { Box, LinearProgress } from "@mui/material";
import { useSite } from "../../../hooks/useSite";

export interface SiteNameDisplayProps {
  customerId?: string;
  siteId?: string;
}

export const SiteNameDisplay = (params: SiteNameDisplayProps) => {
  const { data, isLoading } = useSite(params.customerId, params.siteId);
  return isLoading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <span>{data?.name}</span>
  );
};
