import { Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FederatedAuthForm from "./FederatedAuthForm";

const FederatedAuth = () => {
  return (
    <Box className="w-1/2 m-auto">
      <Card>
        <CardContent>
          <Typography variant="h4">Federated Authentication</Typography>
          <p className="text-md text-gray-500">
            Configure a federated authentication provider.
          </p>
          <FederatedAuthForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default FederatedAuth;
