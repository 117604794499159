import Box from "@mui/material/Box";
import SetPasswordForm from "../../components/auth/SetPasswordForm";

const ResetPassword = () => {
  return (
    <Box display="flex" justifyContent="center">
      <SetPasswordForm action="Reset" />
    </Box>
  );
};

export default ResetPassword;
