import Permissions, { type IPermissions } from "../types/permission/permission";
import { getApi } from "./helpers/apiMethods";

const path = "permissions";

export const getAllPermissions = async (): Promise<Permissions> => {
  type Response = { permissions: IPermissions };
  const response = await getApi<Response>(`/v1/${path}`);
  return Permissions.fromIPermissions(response.data?.permissions);
};
