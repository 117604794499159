import { AxiosRequestConfig, AxiosResponse } from "axios";
import { loadConfig } from "../../configuration/config";
import AxiosInstance from "../../helpers/axiosInstance";

export const postApi = async <Response = unknown, Data = unknown>(
  url: string,
  data?: Data,
  config?: AxiosRequestConfig<Data>
): Promise<AxiosResponse<Response | undefined>> => {
  const baseUrl = (await loadConfig()).ApiUrl;
  const instance = await AxiosInstance();
  return await instance.post(`${baseUrl}${url}`, data, {
    ...config,
    params: formatParams(config?.params),
  });
};

export const getApi = async <Response = unknown, Data = unknown>(
  url: string,
  config?: AxiosRequestConfig<Data>
): Promise<AxiosResponse<Response | undefined>> => {
  const baseUrl = (await loadConfig()).ApiUrl;
  const instance = await AxiosInstance();
  return await instance.get(`${baseUrl}${url}`, {
    ...config,
    params: formatParams(config?.params),
  });
};

export const putApi = async <Response = unknown, Data = unknown>(
  url: string,
  data?: Data,
  config?: AxiosRequestConfig<Data>
): Promise<AxiosResponse<Response | undefined>> => {
  const baseUrl = (await loadConfig()).ApiUrl;
  const instance = await AxiosInstance();
  return await instance.put(`${baseUrl}${url}`, data, {
    ...config,
    params: formatParams(config?.params),
  });
};

export const deleteApi = async <Response = unknown, Data = unknown>(
  url: string,
  config?: AxiosRequestConfig<Data>
): Promise<AxiosResponse<Response | undefined>> => {
  const baseUrl = (await loadConfig()).ApiUrl;
  const instance = await AxiosInstance();
  return await instance.delete(`${baseUrl}${url}`, {
    ...config,
    params: formatParams(config?.params),
  });
};

const formatParams = (
  params?: Record<string, unknown>
): Record<string, unknown> | undefined => {
  if (!params) {
    return undefined;
  }

  const formatted: Record<string, unknown> = {};

  for (const key in params) {
    if (Array.isArray(params[key])) {
      formatted[key] = JSON.stringify(params[key]);
    } else {
      formatted[key] = params[key];
    }
  }

  return formatted;
};
