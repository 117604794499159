import { useContext } from "react";
import { AuthContext, IAuthContext } from "../context/AuthContext";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAlert } from "../context/AlertContext";

interface PermissionsRestrictedRouteProps {
  children: ReactNode;
  requiredPermission: string;
  resource: string;
}

const PermissionsRestrictedRoute = ({
  children,
  requiredPermission,
  resource,
}: PermissionsRestrictedRouteProps) => {
  const { user } = useContext(AuthContext) as IAuthContext;
  const showAlert = useAlert();

  if (!user) {
    return null;
  }

  if (!user.hasPermission(`${resource}:${requiredPermission}`)) {
    console.log("resource: ", resource, "required permission: ", requiredPermission)
    console.log(user.hasPermission(`${resource}:${requiredPermission}`))
    showAlert("You do not have permission to view this page")
    return <Navigate to="/403" />;
  }

  return children;
};

export default PermissionsRestrictedRoute;
