import CreateCustomerRequest from "../types/admin/CreateCustomerRequest";
import Customer from "../types/customer/customer";
import { postApi, putApi } from "./helpers/apiMethods";

const path = "admin";

export const configureFederatedAuth = async (
  formData: FormData
): Promise<void> => {
  await putApi(`/v1/${path}/federated-auth`, formData);
};

type CreateCustomerResponse = Omit<Customer, "customerId"> & {
  id: string;
};
export const createCustomer = async (
  request: CreateCustomerRequest
): Promise<Customer | undefined> => {
  const response = await postApi<CreateCustomerResponse>(
    `/v1/${path}/create-customer`,
    request
  );
  if (!response.data) return undefined;
  const customer: Customer = {
    customerId: response.data.id,
    name: request.name,
  };
  return customer;
};
