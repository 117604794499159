import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SetLogoForm from "../../components/customization/SetLogoForm";

const SetLogo = () => {
  return (
    <Box sx={{ margin: "auto", alignContent: "center", maxWidth: "25%" }}>
      <Card>
        <CardContent>
          <SetLogoForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SetLogo;
