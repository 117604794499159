import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import type {
  GetLogoUploadUrlRequest,
  LogoUploadUrlResponse,
  UpdateLogoRequest,
  UpdateLogoResponse,
} from "../api/customization";
import { getLogoUploadUrl, updateLogo } from "../api/customization";

export const useLogoUploadUrl = (
  customerId?: string,
  request?: GetLogoUploadUrlRequest | null,
  options?: UseQueryOptions<LogoUploadUrlResponse, Error>
) => {
  return useQuery<LogoUploadUrlResponse, Error>({
    queryKey: ["logoUploadUrl", customerId],
    queryFn: () => getLogoUploadUrl(customerId, request),
    enabled: !!customerId && !!request,
    ...options,
  });
};

export const useUpdateLogo = () => {
  const mutationOptions: UseMutationOptions<UpdateLogoResponse, Error, UpdateLogoRequest> = {
    mutationFn: (request: UpdateLogoRequest) => {
      if (!request.customerId) throw new Error("Request is missing customerId.");
      return updateLogo(request);
    },
  }
  return useMutation(mutationOptions);
};
