import IconButton from "@mui/material/IconButton";
import { useGridApiContext } from "@mui/x-data-grid";
import LeftArrow from "@mui/icons-material/KeyboardArrowLeft";
import RightArrow from "@mui/icons-material/KeyboardArrowRight";

export type GridPaginationChangePageAction = () => unknown;

interface PaginationActionsProps {
  page: number | undefined;
  rowCount?: number;
  rowsPerPage: number | undefined;
  isLoading?: boolean;
  estimatedRowCount?: number;
  onNextPage?: GridPaginationChangePageAction;
  onPreviousPage?: GridPaginationChangePageAction;
}

const isPreviousDisabled = (page: number | undefined): boolean => {
  return !page;
};

const isNextDisabled = (
  page: number | undefined,
  rowCount: number | undefined,
  rowsPerPage: number | undefined,
  estimatedRowCount?: number
): boolean => {
  if (estimatedRowCount) {
    return false;
  }
  if (page == null || rowCount == null || rowsPerPage == null) {
    return true;
  }
  return page >= Math.ceil(rowCount / rowsPerPage) - 1;
};

const PaginationActions = ({
  page = 0,
  rowCount,
  rowsPerPage,
  isLoading,
  estimatedRowCount,
  onNextPage,
  onPreviousPage,
}: PaginationActionsProps) => {
  const apiRef = useGridApiContext();

  const handleNextPage = async () => {
    apiRef.current.setPage(page + 1);
    await onNextPage?.();
  };

  const handlePreviousPage = async () => {
    apiRef.current.setPage(page - 1);
    await onPreviousPage?.();
  };

  return (
    <div className="flex">
      <IconButton
        onClick={handlePreviousPage}
        disabled={isPreviousDisabled(page) || isLoading}
      >
        <LeftArrow />
      </IconButton>
      <IconButton
        onClick={handleNextPage}
        disabled={
          isNextDisabled(page, rowCount, rowsPerPage, estimatedRowCount) ||
          isLoading
        }
      >
        <RightArrow />
      </IconButton>
    </div>
  );
};

export default PaginationActions;
