import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Typography,
} from "@mui/material";

const sortAlphabetically = (checkboxes: string[]) => {
  return checkboxes.sort((a, b) => a.localeCompare(b));
};

export interface PermissionGroupProps
  extends Omit<CheckboxProps, "onChange" | "onBlur" | "color"> {
  checkboxes: string[];
  getCheckboxLabel?: (value: string) => string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => void;
  onBlur: (event?: React.FocusEvent<HTMLLabelElement, Element>) => void;
  value: Set<string>;
  label: string;
  size?: "small" | "medium";
  resource: string;
}

const PermissionGroup = ({
  className,
  checkboxes,
  getCheckboxLabel = (value) => value,
  onChange,
  onBlur,
  value,
  label,
  resource,
  size = "small",
  ...props
}: PermissionGroupProps) => {
  const resourceCheckboxes = Array.from(value).filter((checkbox) =>
    checkbox.startsWith(resource)
  );

  return (
    <div className={`flex flex-col ${className}`}>
      <Typography color="gray" variant="body1">
        {label}{" "}
        {resourceCheckboxes.length > 0 ? `(${resourceCheckboxes.length})` : ""}
      </Typography>
      <div className="flex flex-col">
        {sortAlphabetically(checkboxes).map((checkbox: string) => {
          return (
            <FormControlLabel
              key={checkbox}
              htmlFor={checkbox}
              label={getCheckboxLabel(checkbox)}
              control={
                <Checkbox
                  {...props}
                  id={checkbox}
                  size={size}
                  checked={value.has(checkbox)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(event, checkbox)
                  }
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default PermissionGroup;
