import { createContext, useEffect, useState, type ReactNode } from "react";
import { Config, loadConfig } from "../configuration/config";

export const ConfigContext = createContext<Config | undefined>(undefined);

export interface ConfigProviderProps {
  children: ReactNode;
}

const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [config, setConfig] = useState<Config | undefined>(undefined);

  useEffect(() => {
    const getConfig = async () => {
      const config = await loadConfig();
      setConfig(config);
    };

    getConfig();
  }, []);

  if (!config) {
    return <>Loading...</>;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigProvider;
