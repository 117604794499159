import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { GridColumnMenuItemProps } from "@mui/x-data-grid";
import { useContext, useRef } from "react";
import FieldLabelContext from "../context/FieldLabelContext";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useAlert } from "../../../context/AlertContext";

const ResetFieldLabelMenuItem = (props: GridColumnMenuItemProps) => {
  const menuItemRef = useRef<HTMLLIElement>(null);
  const showAlert = useAlert();

  const { resetFieldLabel } = useContext(FieldLabelContext);
  const { colDef } = props;
  const { field } = colDef;

  const resetLabel = () => {
    resetFieldLabel(field);
    showAlert("Field label reset to default.", "success");
  };

  return (
    <MenuItem {...props} ref={menuItemRef} onClick={resetLabel}>
      <ListItemIcon>
        <RemoveCircleIcon />
      </ListItemIcon>
      <ListItemText>Reset Name</ListItemText>
    </MenuItem>
  );
};

export default ResetFieldLabelMenuItem;
