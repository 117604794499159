import { IAuditLog } from "../types/audit-logs/auditLog";
import { getApi } from "./helpers/apiMethods";

const path = "auditlogs";

export interface AuditLogSearchQuery {
  userId?: string;
  from?: string;
  to?: string;
  httpMethod?: string;
}

export interface GetAllAuditLogsResponse {
  auditLogs: IAuditLog[];
  nextCursor?: string | null;
  totalCount: number;
}

export const getAllAuditLogs = async (
  customerId: string,
  searchQuery: AuditLogSearchQuery = {},
  cursor?: string
): Promise<GetAllAuditLogsResponse> => {
  const response = await getApi<GetAllAuditLogsResponse>(
    `/v1/customers/${customerId}/${path}`,
    {
      params: {
        ...searchQuery,
        cursor,
      },
    }
  );
  return response.data ?? { auditLogs: [], totalCount: 0 };
};

export const getAuditLogById = async (
  customerId: string,
  auditLogId: string
): Promise<IAuditLog | undefined> => {
  const response = await getApi<IAuditLog>(
    `/v1/customers/${customerId}/${path}/${auditLogId}`
  );
  return response.data;
};
