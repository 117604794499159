import UploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import type {
  GetLogoUploadUrlRequest,
  LogoUploadUrlResponse,
} from "../../api/customization";
import { useParams } from "react-router-dom";
import { useAlert } from "../../context/AlertContext";
import uploadToS3 from "../../helpers/s3Presigned";
import { useLogoUploadUrl, useUpdateLogo } from "../../hooks/useCustomization";
import HiddenInput from "../common/HiddenInput/HiddenInput";

const ACCEPT_FILE_TYPES = ".jpg, .jpeg, .png";

export interface SetLogoRequest {
  file: FileList;
}

const getFileExtension = (file: File) => {
  return `.${file.name.split(".").pop()}`;
};

const SetLogoForm = () => {
  const showAlert = useAlert();
  const { customerId } = useParams();
  const { formState, setError, watch, handleSubmit, register } =
    useForm<SetLogoRequest>();
  const { errors, isSubmitting } = formState;
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [urlRequest, setUrlRequest] = useState<GetLogoUploadUrlRequest | null>(
    null
  );
  const selectedFile = watch("file");
  const logoMutation = useUpdateLogo();

  const upload = async (response: LogoUploadUrlResponse) => {
    try {
      if (!response?.uploadUrl) return;
      const file = selectedFile?.item(0);
      if (!file) return;
      await uploadToS3(file, response?.uploadUrl, {
        "x-amz-acl": "public-read",
      });
      await logoMutation.mutateAsync({
        customerId,
        fileUrl: response.fileUrl,
      });
      showAlert("Logo uploaded successfully.", "success");
    } catch (error: unknown) {
      if (error instanceof Error) showAlert(error.message, "error");
      else showAlert(error as string, "error");
    }
  };

  const { isLoading, isError, isSuccess, data, error } = useLogoUploadUrl(customerId, urlRequest);

  useEffect(() => {
    if (isSuccess){
      upload(data);
    }
  })

  useEffect(() => {
    if (isError) {
      showAlert(error?.message, "error");
    }
  }, [isError, showAlert, error?.message]);

  const onSubmit = async (formData: SetLogoRequest) => {
    const file = formData.file.item(0);
    if (!file) return;
    const fileExtension = getFileExtension(file);
    if (!fileExtension) return;
    setUrlRequest({
      filename: file.name,
      contentType: file.type,
      fileExtension,
      fileSizeBytes: file.size,
    });
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const fileExtension = getFileExtension(file);
    if (!ACCEPT_FILE_TYPES.includes(fileExtension)) {
      setError("file", { message: "Invalid file type." });
      setFileUrl(null);
      event.target.value = "";
      return;
    }
    setError("file", { message: "" });
    setFileUrl(URL.createObjectURL(file));
  };

  return (
    <form
      style={{ display: "flex", flexDirection: "column" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="h4">Set Logo</Typography>
        <Button component="label" variant="outlined" startIcon={<UploadIcon />}>
          Upload Logo
          <HiddenInput
            {...register("file")}
            onChange={onFileChange}
            name="file"
            type="file"
            capture="user"
            accept={ACCEPT_FILE_TYPES}
            max={1}
            required
          />
        </Button>
        {errors.file && (
          <FormHelperText error>{errors.file.message}</FormHelperText>
        )}
        {fileUrl && (
          <>
            <Typography variant="h6">Selected Logo:</Typography>
            <img src={fileUrl} alt="Selected Logo" />
          </>
        )}
        <Button
          type="submit"
          variant="contained"
          disabled={
            !fileUrl || isSubmitting || isLoading || isError || isSuccess
          }
        >
          Confirm
        </Button>
      </Stack>
    </form>
  );
};

export default SetLogoForm;
