import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import videojs from "video.js";
import "videojs-wavesurfer";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import TimeDisplay, { PlayerTimeState } from "../ButtonsAndControls/VideoAndAudioOnly/TimeDisplay";
import LoadingIndicator from "../../common/LoadingIndicator/LoadingIndicator";
import { convertSecondsToTimeDisplay } from "../../../helpers/displayHelpers";
import MediaControls from "./VideoControls";
import useScreenSize from "../../../hooks/useScreenSize";
import { getDefaultVolume } from "../MediaDrawer";

interface MediaPlayerProps {
  playerInteractionId: string | undefined;
  isUrlLoading: boolean;
  mediaUrl: string | undefined;
  closePlayer?: () => void;
  closeWindow?: () => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  mediaDrawerWidth?: number;
  handleOpenInNewWindow?: (
    playerInteractionId: string,
    closeWindow?: () => void
  ) => void;
}

const MediaPlayer = ({
  mediaUrl,
  isUrlLoading,
  closePlayer,
  closeWindow,
  isPlaying,
  setIsPlaying,
  mediaDrawerWidth,
  playerInteractionId,
  handleOpenInNewWindow,
}: MediaPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const [timeState, setTimeState] = useState<PlayerTimeState | undefined>();
  const [muted, setMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(getDefaultVolume(50));
  const isLoading = isUrlLoading;
  const prevMediaUrlRef = useRef<string | undefined>(mediaUrl);
  const screenWidth = useScreenSize().width;


  const videoJsOptions = useMemo(
    () => ({
      controls: false,
      responsive: true,
      bigPlayButton: true,
      inactivityTimeout: 0,
      height: "auto",
      fluid: true,
      playsinline: true,
      plugins: {
        wavesurfer: {
          backend: "MediaElement",
          displayMilliseconds: true,
          debug: true,
          waveColor: "#163b5b",
          progressColor: "black",
          cursorColor: "black",
          hideScrollbar: true,
          container: "#waveform",
          originalHeight: 333,
          height: "auto",
          normalize: true,
          highResolution: false,
        },
      },
    }),
    []
  );

  const updateVolume = (volume: number) => {
    setVolume(volume);
    localStorage.setItem("preferredVolume", volume.toString());
  };

  const handleReady = useCallback(
    (player: Player) => {
      player.on("play", () => setIsPlaying(true));
      player.on("pause", () => setIsPlaying(false));
      player.on("ended", () => setIsPlaying(false));

      player.on("fullscreenchange", () => {
        const player = playerRef.current;
        if (player) {
          const isFullscreen = player.isFullscreen();
          player.controls(isFullscreen);
        }
      });

      player.on("waveReady", () => {
        const wavesurfer = (player as any).wavesurfer();
        if (wavesurfer) {
          wavesurfer.originalHeight = 200;
          console.log("Wavesurfer is ready!");
        }
      });

      player.one("loadedmetadata", () => {
        setTimeState({
          currentTime: "00:00:00",
          duration: convertSecondsToTimeDisplay(player.duration()),
        });
      });

      player.on("error", () => {
        console.log("Player state: ", player);
        const error = player.error();
        console.error("Playback error: ", error);
      });
    },
    [setIsPlaying]
  );

  useEffect(() => {
    if (!videoRef.current || !mediaUrl) return;

    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-default-skin");
      videoRef.current.appendChild(videoElement);

      const player = videojs(videoElement, videoJsOptions);
      handleReady?.(player);

      playerRef.current = player;
      player.src({ src: mediaUrl, type: "video/mp4" });
    } else if (mediaUrl && mediaUrl !== prevMediaUrlRef.current) {
      const player = playerRef.current;
      player.src({ src: mediaUrl, type: "video/mp4" });
      prevMediaUrlRef.current = mediaUrl;
    }

    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        setIsPlaying(false);
        setTimeState(undefined);
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [mediaUrl, videoJsOptions, handleReady, setIsPlaying]);

  useEffect(() => {
    if (muted) {
      playerRef.current?.volume(0);
      return;
    }
    playerRef.current?.volume(volume / 100);
  }, [muted, volume]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.on("timeupdate", () => {
        const currentTime = convertSecondsToTimeDisplay(
          playerRef.current?.currentTime()
        );
        const duration = convertSecondsToTimeDisplay(
          playerRef.current?.duration()
        );
        setTimeState({ currentTime, duration });
      });
    }
  }, [setTimeState, timeState]);

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      if (player) {
        player.on("playerresize", () => {
          const wavesurfer = (player as any).wavesurfer();
          if (wavesurfer) {
            wavesurfer.redrawWaveform(screenWidth, 200);
          }
        });
      }
    }
  }, [screenWidth]);

  useEffect(() => {
    if (!playerRef.current) return;

    const player = playerRef.current;
    const waveformContainer = waveformRef.current;

    if (player && waveformContainer) {
      const observer = new ResizeObserver(() => {
        const wavesurfer = (player as any).wavesurfer();
        if (wavesurfer && wavesurfer.surfer) {
          wavesurfer.redrawWaveform(mediaDrawerWidth, 200);
        }
      });

      observer.observe(waveformContainer);

      return () => {
        observer.unobserve(waveformContainer);
      };
    }
  }, [mediaDrawerWidth]);

  return (
    <div className="w-full h-full">
      <div className="w-full relative" data-vjs-player>
        <div
          id="video-js vjs-default-skin"
          ref={videoRef}
          className="w-full "
        ></div>

        <MediaControls
          player={playerRef.current}
          isPlaying={isPlaying}
          muted={muted}
          setMuted={setMuted}
          volume={volume}
          setVolume={updateVolume}
          isLoading={isLoading}
          handleOpenInNewWindow={handleOpenInNewWindow}
          playerInteractionId={playerInteractionId}
        />
      </div>

      {isLoading && (
        <div className="pl-4 flex items-center justify-center">
          <LoadingIndicator />
        </div>
      )}

      <div
        id="waveform"
        ref={waveformRef}
        className="h-[200px] w-full"
      >
        <div className="flex items-center text-center">
          <TimeDisplay timeState={timeState} />
        </div>
      </div>
    </div>
  );
};

export default MediaPlayer;
