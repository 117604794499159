import {
  PropsWithChildren,
  createContext,
  useState,
  useMemo,
  useEffect,
} from "react";

interface IAdminInfoContext {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

const DEFAULT_CONTEXT: IAdminInfoContext = {
  isVisible: false,
  setVisible: () => {},
};

export const AdminInfoContext =
  createContext<IAdminInfoContext>(DEFAULT_CONTEXT);

const AdminInfoProvider = ({ children }: PropsWithChildren) => {
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    console.log("Is Visible", isVisible);
  }, [isVisible]);

  const context = useMemo<IAdminInfoContext>(
    () => ({ isVisible, setVisible }),
    [isVisible]
  );

  return (
    <AdminInfoContext.Provider value={context}>
      {children}
    </AdminInfoContext.Provider>
  );
};

export default AdminInfoProvider;
