import { PropsWithChildren, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { PropsOf } from "@emotion/react";
import Tooltip from "@mui/material/Tooltip";
import UserAuth from "../../../types/user/UserAuth";

type UnauthorizedAction = "hide" | "disable";

type DisableableComponentWithChildren = React.JSXElementConstructor<{
  children?: React.ReactNode;
  disabled?: boolean;
}>;

export type AuthorizedComponentProps = Omit<
  AuthorizedWrapperProps,
  "Component" | "ComponentProps"
>;

export interface AuthorizedWrapperProps extends PropsWithChildren {
  Component: DisableableComponentWithChildren;
  ComponentProps: PropsOf<DisableableComponentWithChildren>;
  permission?: string;
  unauthorizedAction?: UnauthorizedAction;
}

const getTooltipMessage = (user: UserAuth | null, permission?: string) => {
  if (!!user && (!permission || user.hasPermission(permission))) {
    return "";
  }
  return `You do not have permission: ${permission}`;
};

const AuthorizedWrapper = ({
  children,
  Component,
  ComponentProps,
  permission,
  unauthorizedAction,
}: AuthorizedWrapperProps) => {
  const { user } = useContext(AuthContext);
  const hasInvalidPermissions =
    !user || (!!permission && !user.hasPermission(permission));

  if (hasInvalidPermissions && unauthorizedAction === "hide") {
    return null;
  }

  return (
    <Tooltip title={getTooltipMessage(user, permission)}>
      <span>
        <Component
          {...ComponentProps}
          disabled={hasInvalidPermissions || ComponentProps.disabled}
        >
          {children}
        </Component>
      </span>
    </Tooltip>
  );
};

export default AuthorizedWrapper;
