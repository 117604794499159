import { useInteractionMetadata } from "../../hooks/useInteraction";
import InteractionMetadataView from "./InteractionMetadataView";

export interface InteractionInfoProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  interactionId: string;
  customerId: string;
}

const InteractionInfo = ({
  isOpen,
  setIsOpen,
  interactionId,
  customerId,
}: InteractionInfoProps) => {
  const {
    data: metadata,
    isLoading,
    error,
  } = useInteractionMetadata(customerId, interactionId);

  return (
    <InteractionMetadataView
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      interactionMetadata={metadata ?? {}}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default InteractionInfo;
