import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button, { ButtonProps } from "@mui/material/Button";
import {
  ChangeEvent,
  HTMLAttributes,
  ReactNode,
  RefCallback,
  useCallback,
  useState,
} from "react";
import {
  FieldError,
  FieldPath,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import { FormHelperText, Typography } from "@mui/material";
import HiddenInput from "../HiddenInput/HiddenInput";

export interface FilePickerProps<TFormValues extends FieldValues = FieldValues>
  extends Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "onBlur">,
    Omit<UseFormRegisterReturn<FieldPath<TFormValues>>, "ref"> {
  children: ReactNode;
  inputRef: RefCallback<HTMLInputElement>;
  acceptedFileTypes?: string[];
  ButtonProps?: ButtonProps;
  error?: FieldError;
}

const FilePicker = ({
  onChange,
  acceptedFileTypes,
  ButtonProps,
  children,
  inputRef,
  error,
  ...props
}: FilePickerProps) => {
  const [file, setFilename] = useState<string>("");

  const onFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      setFilename(files && files.length > 0 ? files[0].name : "");
      onChange(event);
    },
    [onChange]
  );

  return (
    <>
      <Button
        component="label"
        startIcon={<CloudUploadIcon />}
        variant="contained"
        {...ButtonProps}
        role={undefined}
        tabIndex={-1}
      >
        {children}
        <HiddenInput
          {...props}
          type="file"
          ref={inputRef}
          onChange={onFileChange}
          capture="user"
          accept={acceptedFileTypes?.join(", ")}
          max={1}
          required
        />
        {error && (
          <FormHelperText error>
            <>{error.message}</>
          </FormHelperText>
        )}
      </Button>
      <Typography fontSize={14} variant="caption" noWrap>
        {file ? `Selected: ${file}` : "No file selected."}
      </Typography>
    </>
  );
};

export default FilePicker;
