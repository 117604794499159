import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from "@mui/x-date-pickers";
import { Control, Controller } from "react-hook-form";

interface DateTimePickerProps<T> extends MuiDateTimePickerProps<T> {
  name: string;
  control: Control;
  label: string;
}

export const DateTimePicker = <T = unknown,>({
  name,
  control,
  label,
  ...props
}: DateTimePickerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiDateTimePicker
          {...props}
          {...field}
          label={label}
          slotProps={{
            textField: { size: "small", fullWidth: true },
            field: { clearable: true, onClear: () => field.onChange(null) },
            openPickerButton: { size: "small" },
            openPickerIcon: { fontSize: "small" },
            clearButton: { size: "small" },
            clearIcon: { fontSize: "small" },
          }}
        />
      )}
    />
  );
};
