import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AuditLogSearchQuery } from "../../api/auditLog";
import { useAlert } from "../../context/AlertContext";
import { useAllAuditLogs } from "../../hooks/useAuditLog";
import { IAuditLog } from "../../types/audit-logs/auditLog";
import AuditLogFilters, { AuditLogFilterValues } from "./AuditLogFilters";
import AuditLogResultsView from "./AuditLogResultsView";
import AuditLogView from "./AuditLogView";
import dayjs from "dayjs";
import { dayjsExtensionMethods } from "../../helpers/dateTimeHelpers";

dayjs.extend(dayjsExtensionMethods);

export const convertToRequest = (
  formValues: AuditLogFilterValues
): AuditLogSearchQuery => {
  return {
    userId: formValues.user?.userId,
    from: formValues.from?.toISODateString(),
    to: formValues.to?.toISOEndDateString(),
    httpMethod: formValues.httpMethod ?? undefined,
  };
};

export const searchAuditLogs = async (
  formValues: AuditLogFilterValues,
  setSearchQuery: (request: AuditLogSearchQuery) => void
) => {
  const request = convertToRequest(formValues);
  setSearchQuery(request);
};

const AuditLogs = () => {
  const { customerId } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [estimatedRowCount, setEstimatedRowCount] = useState<number>(0);
  const [selectedAuditLogId, setSelectedAuditLogId] = useState<string | null>(null);
  const [isAuditLogViewOpen, setIsAuditLogViewOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<AuditLogSearchQuery>({});
  const showAlert = useAlert();

  const {
    data,
    error,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useAllAuditLogs(customerId, searchQuery, currentPage);

  // Handle successful data fetching
  useEffect(() => {
    if (isSuccess && currentPage === 0) {
      setEstimatedRowCount(data?.pages[0]?.totalCount || 0);
    }
  }, [isSuccess, currentPage, data]);

  // Handle error cases
  useEffect(() => {
    if (isError && error) {
      console.error(error);
      showAlert(error.message, "error");
    }
  }, [isError, error, showAlert]);

  const areAuditLogsLoading = isLoading || isFetching;
  const currentPageData = data?.pages?.[currentPage] ?? undefined;


  const handleNextPage = async () => {
    if (hasNextPage) {
      setCurrentPage((prev) => prev + 1);
      await fetchNextPage?.();
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 0) {
      const currentPageLength = currentPageData?.totalCount ?? 0;
      setCurrentPage((prev) => prev - 1);
      await fetchPreviousPage?.();
      setEstimatedRowCount((prev) => prev - currentPageLength);
    }
  };

  return (
    <div className="h-full flex flex-col">
      <AuditLogFilters
        className="mb-2"
        customerId={customerId!}
        search={(formValues) => searchAuditLogs(formValues, setSearchQuery)}
      />
      <AuditLogResultsView
        paginationMeta={{ hasNextPage }}
        selectedAuditLogId={selectedAuditLogId}
        setSelectedAuditLogId={setSelectedAuditLogId}
        setIsViewModalOpen={setIsAuditLogViewOpen}
        rows={currentPageData?.auditLogs ?? []}
        rowCount={hasNextPage ? undefined : estimatedRowCount}
        estimatedRowCount={hasNextPage ? estimatedRowCount : undefined}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        error={error}
        isLoading={areAuditLogsLoading}
      />
      <AuditLogView
        isOpen={isAuditLogViewOpen}
        setIsOpen={setIsAuditLogViewOpen}
        auditLogData={currentPageData?.auditLogs.find(
          (value: IAuditLog) => selectedAuditLogId === value.auditLogId
        )}
      />
    </div>
  );
};

export default AuditLogs;
