import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export interface NewWindowButtonProps {
  playerInteractionId: string | undefined;
  handleOpenInNewWindow?: (playerInteractionId: string) => void;
}

const NewWindowButton = ({
  playerInteractionId,
  handleOpenInNewWindow,
}: NewWindowButtonProps) => {
  return handleOpenInNewWindow ? (
    <IconButton
      onClick={() => {
        if (playerInteractionId) {
          handleOpenInNewWindow(playerInteractionId);
        } else {
          console.warn("playerInteractionId is undefined");
        }
      }}
    >
      <OpenInNewIcon />
    </IconButton>
  ) : null;
};

export default NewWindowButton;
