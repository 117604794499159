import { ReactNode } from "react";
import {
  Control,
  FieldErrors,
  FieldPath,
  UseFormRegister,
} from "react-hook-form";
import CXOneConfigForm from "../forms/CXOneConfigForm";
import Connector, { EditDefaultValuesGetter } from "./Connector";
import { z } from "zod";
import { ConnectorWizardFormValues } from "../forms/ConnectorWizard";

export const cxOneSchema = z
  .object({
    accessKey: z
      .string({
        required_error: "Access key is required.",
      })
      .min(1, {
        message: "Access key is required.",
      }),
    secretKey: z
      .string({
        required_error: "Access key is required.",
      })
      .min(1, {
        message: "Secret key is required.",
      }),
    clientId: z.string().optional(),
    clientSecret: z.string().optional(),
  })
  .superRefine((data, context) => {
    if (!!data.clientId && !!data.clientSecret) {
      return;
    }
    if (data.clientId) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Client secret must be specified when client ID is provided.",
        path: ["clientSecret"],
      });
    }
    if (data.clientSecret) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Client ID must be specified when client secret is provided.",
        path: ["clientId"],
      });
    }
  });

export type CXoneFormValues = z.infer<typeof cxOneSchema>;

export default class CXoneConnector implements Connector<CXoneFormValues> {
  public static readonly Type = "CXone";

  public get type(): string {
    return CXoneConnector.Type;
  }

  public get defaultValues(): CXoneFormValues {
    return {
      accessKey: "",
      secretKey: "",
      clientId: "",
      clientSecret: "",
    };
  }

  public getEditDefaultValues: EditDefaultValuesGetter<CXoneFormValues> = (
    _: Partial<CXoneFormValues>
  ): CXoneFormValues => {
    return {
      accessKey: "",
      secretKey: "",
      clientId: "",
      clientSecret: "",
    };
  };

  public get fields(): FieldPath<ConnectorWizardFormValues>[] {
    return [
      ...cxOneSchema._def.schema
        .keyof()
        .options.map((key) => `${CXoneConnector.Type}.${key}`),
    ];
  }

  public renderFormFields(
    register: UseFormRegister<ConnectorWizardFormValues>,
    control: Control<ConnectorWizardFormValues>,
    errors: FieldErrors<ConnectorWizardFormValues>
  ): ReactNode {
    return (
      <CXOneConfigForm
        defaultValues={this.defaultValues}
        register={register}
        control={control}
        errors={errors}
      />
    );
  }
}
