import Stack from "@mui/material/Stack";
import { ReactNode } from "react";
import AuthorizedButton from "../AuthorizedButton/AuthorizedButton";

export interface ResultsPageControlsProps {
  permissionResource?: string;
  entityName: string;
  onCreate: () => void;
  onEdit: () => void;
  onDelete: () => void;
  disableCreatable?: boolean;
  disableEditable?: boolean;
  disableDeletable?: boolean;
  hideDefaultButtons?: boolean;
  selectedRowId: string | null;
  buttons?: ReactNode[];
}

const ResultsPageControls = ({
  permissionResource,
  entityName,
  selectedRowId,
  onCreate,
  onEdit,
  onDelete,
  disableCreatable = false,
  disableEditable = false,
  disableDeletable = false,
  hideDefaultButtons = false,
  buttons = [],
}: ResultsPageControlsProps) => {
  return !hideDefaultButtons || buttons.length > 0 ? (
    <Stack className="mb-2 flex-grow-0" spacing={2} direction="row">
      {!hideDefaultButtons && (
        <>
          <AuthorizedButton
            permission={`${permissionResource}:Create`}
            onClick={onCreate}
            color="primary"
            variant="contained"
            disabled={disableCreatable}
          >
            Create {entityName}
          </AuthorizedButton>
          <AuthorizedButton
            permission={`${permissionResource}:Update`}
            onClick={onEdit}
            color="primary"
            variant="outlined"
            disabled={disableEditable || !selectedRowId}
          >
            Edit {entityName}
          </AuthorizedButton>
          <AuthorizedButton
            permission={`${permissionResource}:Delete`}
            onClick={onDelete}
            color="error"
            variant="contained"
            disabled={disableDeletable || !selectedRowId}
          >
            Delete {entityName}
          </AuthorizedButton>
        </>
      )}
      {buttons}
    </Stack>
  ) : null;
};

export default ResultsPageControls;
