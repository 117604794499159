import type { CognitoUserSession } from "amazon-cognito-identity-js";
import { fetchAuthSession } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type {
  LoginRequest,
  ResetPasswordRequest,
  SetPasswordRequest,
} from "../services/AuthService";
import AuthService from "../services/AuthService";
import UserAuth from "../types/user/UserAuth";
import { ConfigContext } from "./ConfigContext";

type Props = {
  children?: ReactNode;
};

export type IAuthContext = {
  authenticated: boolean;
  user: UserAuth | null;
  validating: boolean;
  login: (request: LoginRequest) => Promise<boolean>;
  logout: () => Promise<void>;
  newPasswordChallenge: (request: SetPasswordRequest) => Promise<void>;
  resetPassword: (request: ResetPasswordRequest) => Promise<void>;
};

const initialValue: IAuthContext = {
  authenticated: false,
  user: null,
  validating: true,
  login: () => Promise.resolve(false),
  logout: () => Promise.resolve(),
  newPasswordChallenge: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
};

export interface TokenPayload {
  iss: string;
  aud: string | string[];
  nbf: number;
  iat: number;
  scope: string;
  jti: string;
  exp: number;
  sub: string;
  userName: string;
  customerId: string;
  roleId: string;
  permission: string;
}

const createUserAuthFromPayload = (payload: TokenPayload): UserAuth => {
  return new UserAuth({
    userId: payload.sub,
    customerId: payload.customerId,
    username: payload.userName,
    roleId: payload.roleId,
    scope: JSON.parse(payload.scope ?? "{}"),
    permissions: JSON.parse(payload.permission ?? "[]"),
  });
};

export const AuthContext = createContext<IAuthContext>(initialValue);

export const AuthProvider = ({ children }: Props) => {
  const config = useContext(ConfigContext);
  const [authenticated, setAuthenticated] = useState(
    initialValue.authenticated
  );
  const [userAuth, setUserAuth] = useState<UserAuth | null>(initialValue.user);
  const [validating, setValidating] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const removeHubListener = Hub.listen("auth", async ({ payload }) => {
      if (payload.event !== "signInWithRedirect") return;
      setAuthenticated(true);
      setValidating(false);
      try {
        const user = await fetchAuthSession();
        const idToken = user.tokens?.idToken?.payload as
          | TokenPayload
          | undefined;
        const newUserAuth: UserAuth = new UserAuth({
          customerId: idToken?.customerId ?? "",
          permissions: JSON.parse(idToken?.permission ?? "[]"),
          roleId: idToken?.roleId ?? "",
          scope: JSON.parse(idToken?.scope ?? "{}"),
          userId: user.userSub!,
          username: idToken?.userName ?? "",
        });
        setUserAuth(newUserAuth);
      } catch (error: unknown) {
        console.error(error);
        setAuthenticated(false);
        setValidating(false);
        setUserAuth(null);
      }
    });

    return () => {
      removeHubListener();
    };
  }, []);

  const handleNewPasswordRequired = useCallback(
    (
      userAttributes: unknown,
      requiredAttributes: unknown,
      email: string,
      currentPassword: string
    ) => {
      navigate("/set-password", {
        state: {
          userAttributes,
          requiredAttributes,
          email,
          currentPassword,
        },
      });
    },
    [navigate]
  );

  const handleLoginSuccess = useCallback(
    (session: CognitoUserSession): void => {
      const idToken = session.getIdToken();
      const decoded = idToken.decodePayload() as TokenPayload;

      const userAuth: UserAuth = createUserAuthFromPayload(decoded);
      setAuthenticated(true);
      setValidating(false);
      setUserAuth(userAuth);
      const isAdmin = userAuth.scope.customer === "*";
      if (isAdmin) {
        navigate("/customers/admin");
        return;
      }
      navigate(`/customers/${userAuth.customerId}`);
    },
    [navigate]
  );

  const handleAuthenticatedUser = useCallback(
    (customerId: string) => {
      if (!config) return;
      setAuthenticated(true);
      setValidating(false);
      if (["/login", "/"].includes(location.pathname))
        navigate(`/customers/${customerId}`);
    },
    [config, location.pathname, navigate]
  );

  const handleUnauthenticatedUser = useCallback(() => {
    setAuthenticated(false);
    setValidating(false);
  }, []);

  const tokenExpired = useCallback((payload: TokenPayload) => {
    return new Date() >= new Date(payload.exp * 1000);
  }, []);

  useEffect(() => {
    if (!config) return;
    setValidating(true);
    const user = AuthService.getInstance(config).getCurrentUser();
    if (!user) {
      handleUnauthenticatedUser();
      return;
    }
    user.getSession(
      (error: Error | null, session: CognitoUserSession | null) => {
        const { doesSessionStillExist, decoded} = AuthService.getInstance(config).sessionExist(error, session);
        if(doesSessionStillExist){
          const newUserAuth: UserAuth = createUserAuthFromPayload(decoded as TokenPayload);
          setUserAuth(newUserAuth);
          handleAuthenticatedUser(newUserAuth.customerId);
          if (location.pathname === "/login") {
            navigate(`/customers/${newUserAuth.customerId}`);
          }
        }
        else{
          handleUnauthenticatedUser();
          navigate("/440")
        }
      }
    );
    return () => {
      setValidating(false);
    };
  }, [
    config,
    handleAuthenticatedUser,
    handleUnauthenticatedUser,
    location.pathname,
    navigate,
    tokenExpired,
  ]);

  const authContextValue = useMemo(() => {
    if (!config) return initialValue;
    return {
      validating,
      authenticated,
      user: userAuth,
      login: (request: LoginRequest) =>
        AuthService.getInstance(config).login(
          request,
          handleLoginSuccess,
          handleNewPasswordRequired
        ),
      logout: async () => {
        await AuthService.getInstance(config).logout(() => {
          setAuthenticated(false);
          setUserAuth(null);
          navigate("/login");
        });
      },
      newPasswordChallenge: async (request: SetPasswordRequest) => {
        await AuthService.getInstance(config).handleNewPasswordChallenge(
          request,
          handleLoginSuccess
        );
      },
      resetPassword: async (request: ResetPasswordRequest) =>
        await AuthService.getInstance(config).resetPassword(request),
    };
  }, [
    authenticated,
    config,
    handleLoginSuccess,
    handleNewPasswordRequired,
    navigate,
    userAuth,
    validating,
  ]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
