import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface ClosePlayerButtonProps {
  closePlayer: () => void;
}

const ClosePlayerButton = ({closePlayer}: ClosePlayerButtonProps) => {
  return(
    <IconButton onClick={() => closePlayer()}>
      <CloseIcon />
    </IconButton>
  )
};

export default ClosePlayerButton;

