import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Five9Connector, { Five9FormValues } from "../models/Five9Connector";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ConnectorWizardFormValues } from "./ConnectorWizard";

export interface Five9ConfigFormProps {
  register: UseFormRegister<ConnectorWizardFormValues>;
  defaultValues: Five9FormValues;
  errors?: FieldErrors<
    ConnectorWizardFormValues & Record<"Five9", Five9FormValues>
  >;
}

const Five9ConfigForm = ({
  register,
  defaultValues,
  errors,
}: Five9ConfigFormProps) => {
  const registerPrefix = Five9Connector.Type;

  return (
    <>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.username`)}
          label="Username"
          defaultValue={defaultValues.username}
          error={!!errors?.Five9?.username}
          autoComplete="off"
          required
        />
        {errors?.Five9?.username && (
          <FormHelperText error>
            <>{errors.Five9.username.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.password`)}
          type="password"
          label="Password"
          defaultValue={defaultValues.password}
          error={!!errors?.Five9?.password}
          autoComplete="off"
          required
        />
        {errors?.Five9?.password && (
          <FormHelperText error>
            <>{errors.Five9.password.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.reportName`)}
          label="Report Name"
          defaultValue={defaultValues.reportName}
          error={!!errors?.Five9?.reportName}
          autoComplete="off"
          required
        />
        {errors?.Five9?.reportName && (
          <FormHelperText error>
            <>{errors.Five9.reportName.message}</>
          </FormHelperText>
        )}
      </FormGroup>
      <FormGroup>
        <TextField
          {...register(`${registerPrefix}.folderName`)}
          label="Folder Name"
          defaultValue={defaultValues.folderName}
          error={!!errors?.Five9?.folderName}
          autoComplete="off"
          required
        />
        {errors?.Five9?.folderName && (
          <FormHelperText error>
            <>{errors.Five9.folderName.message}</>
          </FormHelperText>
        )}
      </FormGroup>
    </>
  );
};

export default Five9ConfigForm;
