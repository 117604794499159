import { FormGroup, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useAlert } from "../../context/AlertContext";
import { AuthContext } from "../../context/AuthContext";
import passwordSchema from "../../helpers/auth/passwordSchema";
import defaultFormConfig from "../../helpers/validation/defaultFormConfig";
import {
  ResetPasswordRequest,
  SetPasswordRequest,
} from "../../services/AuthService";
import ToggleShowPassword from "../common/ToggleShowPassword/ToggleShowPassword";
import PasswordRules from "./PasswordRules/PasswordRules";

const setPasswordSchema = z
  .object({
    email: z.string().min(1, {
      message: "Email is required.",
    }),
    currentPassword: z.string().min(1, {
      message: "Current password is required.",
    }),
    password: passwordSchema("Password"),
    confirmPassword: z.string().min(1, {
      message: "Please confirm your password.",
    }),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: "Passwords must match.",
    path: ["confirmPassword"],
  });
const resetPasswordSchema = z
  .object({
    currentPassword: z.string().min(1, {
      message: "Current password is required.",
    }),
    password: passwordSchema("Password"),
    confirmPassword: z.string().min(1, {
      message: "Please confirm your password.",
    }),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: "Passwords must match.",
    path: ["confirmPassword"],
  });

const schemaByAction = {
  Set: setPasswordSchema,
  Reset: resetPasswordSchema,
};

export interface SetPasswordValues {
  email?: string;
  currentPassword: string;
  password: string;
  confirmPassword: string;
  requiredAttributeData?: unknown;
}

export type PasswordAction = "Set" | "Reset";

interface SetPasswordFormProps {
  email?: string;
  currentPassword?: string;
  requiredAttributeData?: unknown;
  action: PasswordAction;
}

const convertToResetRequest = (
  formData: SetPasswordValues
): ResetPasswordRequest => {
  return {
    oldPassword: formData.currentPassword,
    newPassword: formData.password,
  };
};

const convertToSetRequest = (
  formData: SetPasswordValues,
  email: string,
  requiredAttributeData: unknown
): SetPasswordRequest => {
  return {
    email,
    currentPassword: formData.currentPassword,
    password: formData.password,
    requiredAttributeData,
  };
};

const SetPasswordForm = ({
  email,
  currentPassword,
  requiredAttributeData,
  action = "Set",
}: SetPasswordFormProps) => {
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const { formState, register, watch, handleSubmit } =
    useForm<SetPasswordValues>({
      ...defaultFormConfig<SetPasswordValues>(schemaByAction[action]),
      defaultValues: {
        email,
        currentPassword: currentPassword ?? "",
        requiredAttributeData: requiredAttributeData ?? {},
        password: "",
        confirmPassword: "",
      },
    });
  const { errors, isValid, isLoading, isSubmitting, isDirty } = formState;
  const { newPasswordChallenge, resetPassword } = useContext(AuthContext);
  const showAlert = useAlert();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = async (formData: SetPasswordValues) => {
    try {
      const successMessage = `${action} password successfully.`;
      if (action === "Reset") {
        await resetPassword(convertToResetRequest(formData));
        showAlert(successMessage, "success");
        goBack();
      } else {
        await newPasswordChallenge(
          convertToSetRequest(formData, email!, requiredAttributeData)
        );
        showAlert(successMessage, "success");
        navigate("/");
      }
    } catch (error: unknown) {
      console.error(error);
      if (!(error instanceof Error)) return;
      showAlert(error.message, "error");
    }
  };

  return (
    <Card sx={{ minWidth: 400 }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={2}>
            <Typography variant="h5">{action} Password</Typography>
            {action === "Reset" && (
              <FormGroup>
                <TextField
                  {...register("currentPassword")}
                  type="password"
                  label="Current Password"
                  error={!!errors.currentPassword}
                  autoComplete="current-password"
                  required
                />
              </FormGroup>
            )}
            <FormGroup>
              <TextField
                {...register("password")}
                type={showNewPassword ? "text" : "password"}
                label="New Password"
                error={!!errors.password}
                autoComplete="new-password"
                required
                InputProps={{
                  endAdornment: (
                    <ToggleShowPassword
                      showPassword={showNewPassword}
                      toggleShowPassword={() =>
                        setShowNewPassword(!showNewPassword)
                      }
                    />
                  ),
                }}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                {...register("confirmPassword")}
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                error={!!errors.confirmPassword}
                autoComplete="new-password"
                required
                InputProps={{
                  endAdornment: (
                    <ToggleShowPassword
                      showPassword={showConfirmPassword}
                      toggleShowPassword={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  ),
                }}
              />
            </FormGroup>
            <PasswordRules
              password={watch("password")}
              confirmPassword={watch("confirmPassword")}
            />
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button size="large" onClick={goBack}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={!isDirty || isLoading || !isValid || isSubmitting}
              >
                {action} Password
              </Button>
            </Stack>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default SetPasswordForm;
