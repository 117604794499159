export interface AuthScope {
  customer: string;
  sites: string[];
}

export interface IUserAuth {
  userId: string;
  username: string;
  customerId: string;
  scope: AuthScope;
  permissions: string[];
  roleId: string;
}

export default class UserAuth implements IUserAuth {
  public userId: string;
  public username: string;
  public customerId: string;
  public scope: AuthScope;
  public permissions: string[];
  public roleId: string;

  public constructor(data: IUserAuth) {
    this.userId = data.userId;
    this.username = data.username;
    this.customerId = data.customerId;
    this.scope = data.scope;
    this.permissions = data.permissions;
    this.roleId = data.roleId;
  }

  public hasPermission(permission: string): boolean {
    const [resource] = permission.split(":");

    return this.permissions.some(
      (p) => p === permission || p === `${resource}:*`
    );
  }
}
