import { ReactNode } from "react";
import {
  Control,
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { ConnectorWizardFormValues } from "../forms/ConnectorWizard";
import ConnectorRuleSet from "./ConnectorRuleSet";

export type EditDefaultValuesGetter<
  TFormValues extends FieldValues = FieldValues
> = (_: Partial<TFormValues>) => TFormValues;

export interface ConnectorConfigFormProps<
  TFormValues extends FieldValues,
  ConnectorType extends string
> {
  defaultValues: TFormValues;
  register: UseFormRegister<ConnectorWizardFormValues>;
  control: Control<ConnectorWizardFormValues>;
  errors?: FieldErrors<
    ConnectorWizardFormValues & Record<ConnectorType, TFormValues>
  >;
}

export interface IConnectorRow {
  customerId: string;
  connectorId: string;
  jobConfigId: string;
  type: string;
  intervalSeconds: number;
  ruleSets: ConnectorRuleSet[];
  connectorDetails?: {};
}

export default interface Connector<
  TFormValues extends FieldValues = FieldValues
> {
  get type(): string;
  get defaultValues(): TFormValues;
  get fields(): FieldPath<ConnectorWizardFormValues>[];
  getEditDefaultValues: EditDefaultValuesGetter<TFormValues>;
  renderFormFields(
    register: UseFormRegister<ConnectorWizardFormValues>,
    control: Control<ConnectorWizardFormValues>,
    errors?: FieldErrors<ConnectorWizardFormValues>
  ): ReactNode;
}

// Omit connectorDetails if it's empty
export function omitEmptyConnectorDetails(
  connector: IConnectorRow
): IConnectorRow {
  const { connectorDetails, ...rest } = connector;

  if (connectorDetails && Object.keys(connectorDetails).length === 0) {
    return rest as IConnectorRow;
  }

  return connector;
}
