import Card from "@mui/material/Card";
import type Customer from "../../types/customer/customer";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";
import { Star } from "@mui/icons-material";

export interface AdminPortalCustomerProps {
  customer: Customer;
  userCustomer?: boolean;
}

const AdminPortalCustomer = ({
  customer,
  userCustomer = false,
}: AdminPortalCustomerProps) => {
  const navigateTo = useNavigate();

  const navigateToCustomer = () => {
    navigateTo(`/customers/${customer.customerId}`);
  };

  return (
    <Card>
      <CardActionArea onClick={navigateToCustomer}>
        <CardContent className="flex flex-col">
          <div className="flex space-x-2">
            {userCustomer && <Star className="text-yellow-300" />}
            <Typography variant="h6">{customer.name}</Typography>
          </div>
          <Typography color="grey" variant="body1">
            {customer.customerId}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AdminPortalCustomer;
