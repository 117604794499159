import { Component } from "react";
import Player from "video.js/dist/types/player";
import { PlayArrow, PauseCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import VolumeControl from "../ButtonsAndControls/VideoAndAudioOnly/VolumeControl";
import NewWindowButton from "../ButtonsAndControls/NewWindowButton";

interface CustomControlsProps {
  player?: Player | null;
  isPlaying: boolean;
  muted: boolean;
  setMuted: (muted: boolean) => void;
  volume: number;
  setVolume: (volume: number) => void;
  isLoading: boolean;
  handleOpenInNewWindow?: (playerInteractionId: string) => void;
  playerInteractionId: string | undefined;
}

class MediaControls extends Component<CustomControlsProps> {
  handlePlayPause = () => {
    const { player, isPlaying } = this.props;
    if (player) {
      if (isPlaying) {
        player.pause();
      } else {
        player.play();
      }
    }
  };

  handleFullscreen = () => {
    const { player } = this.props;
    if (player) {
      if (player.isFullscreen()) {
        console.log("leaving full screen");
        player.exitFullscreen();
      } else {
        console.log("requesting full screen");
        player.requestFullscreen();
      }
    }
  };

  render() {
    const {
      isPlaying,
      muted,
      setMuted,
      volume,
      setVolume,
      isLoading,
      handleOpenInNewWindow,
      playerInteractionId,
    } = this.props;

    return (
      <div className="custom-controls flex justify-between">
        <div className="flex">
          <IconButton onClick={this.handlePlayPause} disabled={isLoading}>
            {isPlaying ? <PauseCircle /> : <PlayArrow />}
          </IconButton>
          <VolumeControl
            muted={muted}
            setMuted={setMuted}
            volume={volume}
            setVolume={setVolume}
          />
        </div>
        <div>
          <IconButton onClick={() => this.handleFullscreen()}>
            <FullscreenIcon />
          </IconButton>
            <NewWindowButton
              handleOpenInNewWindow={handleOpenInNewWindow}
              playerInteractionId={playerInteractionId!}
              />
        </div>
      </div>
    );
  }
}

export default MediaControls;
