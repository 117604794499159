import Button from "@mui/material/Button";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAlert } from "../../context/AlertContext";
import defaultFormConfig from "../../helpers/validation/defaultFormConfig";
import { useUsers } from "../../hooks/useUser";
import User from "../../types/user/User";
import AuditLogFilterForm from "./AuditLogFilterForm";

const FORM_ID = "audit-log-filter-form";

export interface AuditLogFilterValues {
  user?: User | null;
  from?: Dayjs | null;
  to?: Dayjs | null;
  httpMethod?: string | null;
}

export const defaultValues: AuditLogFilterValues = {
  user: null,
  from: null,
  to: null,
  httpMethod: null,
};

export interface AuditLogFilterProps {
  className?: string;
  customerId: string;
  search: (formValues: AuditLogFilterValues) => Promise<void>;
}

const AuditLogFilters = ({
  customerId,
  search,
  className,
}: AuditLogFilterProps) => {
  const [isUserQueryReady, setIsUserQueryReady] = useState<boolean>(false);
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const { data, error, isLoading } = useUsers(customerId, isUserQueryReady);
  const form = useForm({
    ...defaultFormConfig<AuditLogFilterValues>(),
    defaultValues,
  });
  const { control, formState, handleSubmit } = form;
  const { isSubmitting, errors } = formState;
  const showAlert = useAlert();

  useEffect(() => {
    if (!data) return;
    setUserOptions(data.users);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    showAlert(error.message, "error");
  }, [error, showAlert]);

  return (
    <div className={`flex space-x-4 ${className}`}>
      <AuditLogFilterForm
        formId={FORM_ID}
        onSubmit={handleSubmit(search)}
        control={control}
        defaultValues={defaultValues}
        errors={errors}
        setIsUserQueryReady={setIsUserQueryReady}
        userOptions={userOptions}
        isLoading={isLoading}
      />
      <Button
        form={FORM_ID}
        type="submit"
        variant="contained"
        disabled={isSubmitting}
      >
        Search
      </Button>
    </div>
  );
};

export default AuditLogFilters;
