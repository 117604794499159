import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

export interface DeleteDialogProps {
  entityName: string;
  deleteEntity: () => Promise<void>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLoading?: boolean;
}

const DeleteDialog = ({
  entityName,
  deleteEntity,
  isOpen,
  setIsOpen,
  isLoading = false,
}: DeleteDialogProps) => {
  const handleDelete = async () => {
    await deleteEntity();
    setIsOpen(false);
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`Delete ${entityName}`}
      action={`delete this ${entityName.toLowerCase()}`}
      extraMessage="This action cannot be undone."
      onConfirm={handleDelete}
      cancelDisabled={isLoading}
      confirmDisabled={isLoading}
      confirmText="Delete"
      confirmLeftAdornment={
        isLoading ? (
          <LoadingIndicator className="mr-2" color="inherit" size="1.25rem" />
        ) : undefined
      }
    />
  );
};

export default DeleteDialog;
