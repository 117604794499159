import { CognitoIdToken } from "amazon-cognito-identity-js";
import axios, { InternalAxiosRequestConfig } from "axios";
import { Config, loadConfig } from "../configuration/config";
import AuthService from "../services/AuthService";

export const isApiRequest = async (config: Config, url?: string) => {
  const apiUrl = config.ApiUrl;
  if (!url) return false;
  return url.startsWith(apiUrl);
};

export async function addBearerAuth(
  appConfig: Config,
  config: InternalAxiosRequestConfig,
  idToken?: CognitoIdToken
) {
  if (!(await isApiRequest(appConfig, config.url)) || !idToken) return config;
  config.headers.Authorization = idToken
    ? `Bearer ${idToken.getJwtToken()}`
    : "";
  return config;
}

export default async function AxiosInstance() {
  const appConfig = await loadConfig();
  const idToken = await AuthService.getInstance(appConfig).getIdToken();
  const instance = axios.create();

  // Add auth to the headers
  instance.interceptors.request.use((config: InternalAxiosRequestConfig) =>
    addBearerAuth(appConfig, config, idToken)
  );

  // Return the axios instance
  return instance;
}
