import ConnectorRegistry from "../components/connectors/controllers/ConnectorRegistry";
import CXoneConnector, {
  cxOneSchema,
} from "../components/connectors/models/CXOneConnector";
import Five9Connector, {
  five9Schema,
} from "../components/connectors/models/Five9Connector";

export const registerConnectors = () => {
  const registry = ConnectorRegistry.getInstance();

  registry.registerConnector(
    CXoneConnector.Type,
    new CXoneConnector(),
    cxOneSchema
  );
  registry.registerConnector(
    Five9Connector.Type,
    new Five9Connector(),
    five9Schema
  );
};
