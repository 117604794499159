import Dialog from "@mui/material/Dialog";
import OpenableProps from "../../../helpers/openableProps";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { ReactElement } from "react";

export interface ConfirmDialogProps extends OpenableProps {
  title: string;
  action: string;
  extraMessage?: string;
  cancelDisabled?: boolean;
  confirmLeftAdornment?: ReactElement;
  confirmText?: string;
  confirmDisabled?: boolean;
  onCancel?: () => unknown;
  onConfirm: (...args: unknown[]) => unknown;
}

const ConfirmDialog = ({
  isOpen,
  setIsOpen,
  title,
  extraMessage = "",
  action,
  cancelDisabled = false,
  confirmDisabled = false,
  confirmText = "Confirm",
  confirmLeftAdornment = <></>,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        onCancel?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to {action}? {extraMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsOpen(false);
            onCancel?.();
          }}
          disabled={cancelDisabled}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          disabled={confirmDisabled}
          variant="contained"
          color="error"
        >
          {confirmLeftAdornment}
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
