import OpenableProps from "../../helpers/openableProps";
import ContactSupportDialog, {
  ContactSupportDialogProps,
} from "../../components/common/ContactSupportDialog/ContactSupportDialog";

interface DeleteSiteDialogProps
  extends OpenableProps,
    ContactSupportDialogProps {}

const DeleteSiteDialog = ({ ...props }: DeleteSiteDialogProps) => {
  return <ContactSupportDialog {...props} action="delete this site" />;
};

export default DeleteSiteDialog;
