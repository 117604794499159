import { createWriteStream } from "streamsaver";
import { getFileUrl } from "../api/interaction";

export interface DownloadInteractionFileRequest {
  customerId: string;
  interactionId: string;
}

export const downloadInteractionFile = async ({
  customerId,
  interactionId,
}: DownloadInteractionFileRequest): Promise<void> => {
  const fileUrl = await getFileUrl(interactionId!, customerId);
  if (!fileUrl) {
    throw new Error("Failed to get file URL.");
  }
  const response = await fetch(fileUrl.temporaryUrl);
  if (!response.body) {
    throw new Error("Failed to get file URL.");
  }
  return await downloadFromStream(
    response.body,
    `${customerId}.${interactionId}.wav`
  );
};

const downloadFromStream = async (
  readableStream: ReadableStream<Uint8Array>,
  fileName: string
) => {
  let done = false;
  const fileStream = createWriteStream(fileName);

  try {
    window.onunload = () => {
      writer?.abort();
    };

    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (!done) {
        event.returnValue =
          "Are you sure you want to leave? A download is in progress.";
      }
    };

    if (window.WritableStream && readableStream.pipeTo) {
      await readableStream.pipeTo(fileStream);
      console.log(`Downloaded file (${fileName}) successfully`);
      done = true;
      return;
    }

    const writer = fileStream.getWriter();

    const reader = readableStream.getReader();
    const pump = async () => {
      const response = await reader.read();
      if (response.done) {
        writer.close();
        console.log(`Downloaded file (${fileName}) successfully`);
        done = true;
        return;
      }
      await writer.write(response.value);
      pump();
    };
    pump();
  } catch (error: unknown) {
    console.error("Error downloading file:", error);
  }
};
