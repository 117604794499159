import { z } from "zod";
import Connector from "../models/Connector";
import { FieldValues } from "react-hook-form";

const siteSchema = z.object(
  {
    siteId: z
      .string()
      .min(1, {
        message: "Site ID is required.",
      })
      .uuid({
        message: "Specified site is invalid.",
      }),
  },
  {
    required_error: "Site is required.",
    invalid_type_error: "Site is required.",
  }
);

export default class ConnectorRegistry {
  private static _instance: ConnectorRegistry;
  private _connectorSchemas: Record<string, z.ZodTypeAny>;
  private _connectors: Record<string, Connector>;
  private _defaultValues: Record<string, unknown>;
  private _baseSchema: z.AnyZodObject;

  private constructor() {
    this._connectorSchemas = {};
    this._connectors = {};
    this._baseSchema = z.object({
      type: z
        .string({
          required_error: "Type is required.",
        })
        .min(1, {
          message: "Type is required.",
        }),
      intervalSeconds: z
        .number({
          required_error: "Interval is required.",
        })
        .optional()
        .default(604_800),
      ruleSets: z
        .array(
          z.object({
            site: siteSchema,
            rules: z
              .array(
                z.object({
                  attributeName: z.string().min(1, {
                    message: "Attribute name is required.",
                  }),
                  attributeValue: z.string().min(1, {
                    message: "Attribute value is required.",
                  }),
                })
              )
              .optional()
              .default([]),
          })
        )
        .min(1, {
          message: "At least one site mapping rule is required.",
        }),
      shouldSaveNoMedia: z.boolean().default(false),
    });
    this._defaultValues = {
      type: "",
      intervalSeconds: 86_400, // 1 day
      ruleSets: [
        {
          siteId: "",
          rules: [],
        },
      ],
      shouldSaveNoMedia: false,
    };
  }

  public registerConnector<TFormValues extends FieldValues = FieldValues>(
    type: string,
    connector: Connector<TFormValues>,
    schema: z.ZodTypeAny
  ) {
    this._connectors[type] = connector as Connector<FieldValues>;
    const connectorNames = Object.keys(this._connectors);
    this._defaultValues[type] = undefined;
    this._baseSchema = this._baseSchema
      .omit({
        type: true,
      })
      .extend({
        type: z.enum(connectorNames as [string, ...string[]], {
          required_error: "Type is required.",
        }),
      });
    this._connectorSchemas[type] = schema.optional();
  }

  public get connectorMap() {
    return this._connectors;
  }

  public get defaultValues() {
    return this._defaultValues;
  }

  public get schema() {
    const schema = this._baseSchema.extend(this._connectorSchemas);
    return schema.refine((data) => {
      if (!data[data.type]) {
        return false;
      }
      return true;
    });
  }

  public static getInstance() {
    if (!this._instance) {
      this._instance = new ConnectorRegistry();
    }
    return this._instance;
  }
}
