import { PropsWithChildren, useCallback, useMemo } from "react";
import { getItem, setItem } from "../../../helpers/localStorage";
import { FieldLabel } from "./FieldLabelContext";
import FieldLabelStorageContext from "./FieldLabelStorageContext";

const FieldLabelLocalStorageProvider = ({ children }: PropsWithChildren) => {
  const getFieldLabelsKey = (tableId: string): string => {
    return `${tableId}-fieldLabels`;
  };

  const getFieldLabelsByTable = useCallback(
    (tableId: string): Map<string, FieldLabel> => {
      const fieldLabels: Record<string, FieldLabel> | undefined = getItem(
        getFieldLabelsKey(tableId)
      );
      if (!fieldLabels) {
        return new Map();
      }
      return new Map(Object.entries(fieldLabels));
    },
    []
  );

  const saveFieldLabelsToTable = useCallback(
    (tableId: string, fieldLabels: Map<string, FieldLabel>): void => {
      setItem(getFieldLabelsKey(tableId), Object.fromEntries(fieldLabels));
    },
    []
  );

  const contextValue = useMemo(() => {
    return {
      getFieldLabelsByTable,
      saveFieldLabelsToTable,
    };
  }, [getFieldLabelsByTable, saveFieldLabelsToTable]);

  return (
    <FieldLabelStorageContext.Provider value={contextValue}>
      {children}
    </FieldLabelStorageContext.Provider>
  );
};

export default FieldLabelLocalStorageProvider;
