import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { configureFederatedAuth, createCustomer } from "../api/admin";
import CreateCustomerRequest from "../types/admin/CreateCustomerRequest";
import Customer from "../types/customer/customer";

export const useConfigureFederatedAuth = () => {
  const mutationOptions: UseMutationOptions<void, Error, FormData> = {
    mutationFn: (request: FormData) => {
      if (!request.get("customerId")) {
        throw new Error("Request is missing customerId.");
      }
      return configureFederatedAuth(request);
    },
  };

  return useMutation(mutationOptions);
};

export const useCreateCustomer = () => {
  const mutationOptions: UseMutationOptions<Customer | undefined, Error, CreateCustomerRequest> = {
    mutationFn: (request: CreateCustomerRequest) => {
      return createCustomer(request);
    },
  };

  return useMutation(mutationOptions);
};
