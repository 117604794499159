import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  TextFieldProps,
} from "@mui/material";

import { useState } from "react";
import ClearableTextField from "../ClearableTextField/ClearableTextField";

export interface IntervalPickerProps {
  className?: string;
  value: number | undefined | null;
  setValue: (value: number | undefined) => void;
  units: Unit[];
  label: string;
  clearable?: boolean;
  defaultUnit?: IntervalUnit;
  TextFieldProps?: Partial<TextFieldProps>;
  SelectProps?: Partial<SelectProps<number>>;
}

export type Unit =
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "year";

export const multipliers: Record<Unit, number> = {
  second: 1,
  minute: 60,
  hour: 3600,
  day: 86_400,
  week: 604_800,
  month: 2_592_000,
  year: 31_536_000,
};

export type IntervalUnit = keyof typeof multipliers;

const IntervalPicker = ({
  className = "",
  value: intervalSeconds,
  setValue: setIntervalSeconds,
  units,
  label,
  TextFieldProps = {},
  SelectProps = {},
  clearable = false,
  defaultUnit = "minute",
}: IntervalPickerProps) => {
  const defaultMultiplier =
    defaultUnit in multipliers
      ? multipliers[defaultUnit]
      : multipliers[units[0]];

  const [multiplier, setMultiplier] = useState<number>(defaultMultiplier);

  if (units.length === 0) {
    return <>No units provided.</>;
  }

  return (
    <div className={className}>
      <Stack direction="row">
        <ClearableTextField
          {...TextFieldProps}
          defaultValue={undefined}
          data-testid="interval-picker-number-input"
          type="text"
          inputProps={{
            pattern: "[0-9]*",
          }}
          label={label}
          value={
            intervalSeconds ? (intervalSeconds / multiplier).toFixed(0) : ""
          }
          onChange={(value: string | null) => {
            const parsed = Number(value);
            if (isNaN(parsed) && clearable) {
              setIntervalSeconds(undefined);
              return;
            } else if (isNaN(parsed)) {
              return;
            }
            if (value) {
              setIntervalSeconds(parsed * multiplier);
            } else {
              setIntervalSeconds(undefined);
            }
          }}
        />
        <Select
          {...SelectProps}
          onChange={(event: SelectChangeEvent<number>) => {
            const value = Number(event.target.value);
            setMultiplier(value);
          }}
          defaultValue={defaultMultiplier}
        >
          {units.map((unit) => (
            <MenuItem key={unit} value={multipliers[unit]}>
              {unit}(s)
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </div>
  );
};

export default IntervalPicker;
