import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from "react-hook-form";

export interface RadioButtonsProps<
  TFormData extends FieldValues,
  TOption extends { value: any; label: string }
> {
  name: Path<TFormData>;
  label: string;
  control: Control<TFormData, unknown>;
  rules?: Omit<
    RegisterOptions<TFormData, Path<TFormData>>,
    "disabled" | "setValueAs" | "valueAsNumber" | "valueAsDate"
  >;
  options: TOption[];
  error?: boolean;
  required?: boolean;
  defaultValue?: PathValue<TFormData, Path<TFormData>>;
  mutateOption?: (option: TOption) => PathValue<TFormData, Path<TFormData>>;
}

const RadioButtons = <
  TFormData extends FieldValues,
  TOption extends { value: any; label: string }
>({
  name,
  label,
  control,
  rules,
  options,
  mutateOption,
  required = false,
  defaultValue,
}: RadioButtonsProps<TFormData, TOption>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: required ? `${label} is required.` : false, ...rules }}
      render={({ field }) => (
        <div>
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            {...field}
            onChange={(e) => {
              const selectedOption = options.find(
                (option) => String(option.value) === e.target.value
              );
              if (selectedOption) {
                field.onChange(
                  mutateOption ? mutateOption(selectedOption) : selectedOption
                );
              }
            }}
            value={String(field.value?.value)}
          >
            {options.map((option) => (
              <FormControlLabel
                key={String(option.value)}
                value={String(option.value)}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </div>
      )}
    />
  );
};

export default RadioButtons;
