import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

export interface KeyValuePairProps {
  label: ReactNode;
  value: ReactNode | undefined;
}

const KeyValuePair = ({ label, value }: KeyValuePairProps) => (
  <div className="flex flex-col mb-4">
    <Typography fontSize={18} color="gray" variant="body1">
      {label}
    </Typography>
    <Typography
      className="break-words"
      fontSize={16}
      color="black"
      variant="body2"
    >
      {value}
    </Typography>
  </div>
);

export default KeyValuePair;
