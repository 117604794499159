import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Card, CardContent, Typography } from "@mui/material";
import { InteractionByMediaType } from "../../types/dashboard/Dashboard";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";

interface MediaTypeProps {
  data: InteractionByMediaType[] | null;
  isLoading: boolean;
}

const COLORS = ["#0088FE", "#EBEFF9", "#17254A", "#FF8042", "#FFC0CB"];

const MediaTypeAnalyticsChart = ({ data, isLoading }: MediaTypeProps) => {
  if (!data) return <div>No Data Preview Available</div>;

  const totalMediaInteractions = data.reduce(
    (acc, currentValue) => acc + currentValue.value,
    0
  );

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Card>
        <CardContent>
          <Typography variant="h6">Interactions by Media Type</Typography>
          <PieChart
            height={300}
            series={[
              {
                data,
                type: "pie",
                paddingAngle: 1,
                cornerRadius: 8,
                innerRadius: 90,
                arcLabelMinAngle: 35,
                arcLabel: (item) =>
                  `${
                    Math.round(item.value / totalMediaInteractions * 100)
                  }%`,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
                fontWeight: "bold",
              },
            }}
            colors={COLORS}
          ></PieChart>
        </CardContent>
      </Card>
    </>
  );
};

export default MediaTypeAnalyticsChart;
