import Dialog, { DialogProps } from "@mui/material/Dialog";
import OpenableProps from "../../../helpers/openableProps";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { SUPPORT_EMAIL } from "../../../common/const";

const DEFAULT_ACTION = "perform this action";

export interface ContactSupportDialogProps
  extends OpenableProps,
    Omit<DialogProps, "onClose" | "open"> {
  action?: string;
}

const ContactSupportDialog = ({
  isOpen,
  setIsOpen,
  action = DEFAULT_ACTION,
  ...props
}: ContactSupportDialogProps) => {
  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog {...props} open={isOpen} onClose={closeDialog}>
      <DialogTitle>Contact Support</DialogTitle>
      <Box className="px-4 pb-4">
        <Typography variant="body1">
          If you would like to {action}, please contact support at{" "}
          <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>.
        </Typography>
      </Box>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactSupportDialog;
