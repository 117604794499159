import SuccessIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Alert, Snackbar } from "@mui/material";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

type AnchorOrigin = {
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
};

const ALERT_DURATION_MS = 5000;
const ANCHOR_ORIGIN: AnchorOrigin = { vertical: "bottom", horizontal: "right" };

type AlertProviderProps = {
  children: ReactNode;
};

export type Severity = "error" | "info" | "success" | "warning";

interface IAlert {
  message: string;
  severity: Severity;
  icon?: ReactNode;
}

const initialAlert: IAlert = {
  message: "",
  severity: "info",
};

export type UseShowAlert = (
  message?: string,
  severity?: Severity,
  icon?: ReactNode
) => void;

/* istanbul ignore next */
export const AlertContext = createContext<UseShowAlert>(
  (_message?: string, _severity: Severity = "info", _icon?: ReactNode) => {}
);

/* istanbul ignore next */
const getIcon = (severity: Severity) => {
  if (severity === "error") {
    return <ErrorIcon />;
  }
  if (severity === "info") {
    return <InfoIcon />;
  }
  if (severity === "success") {
    return <SuccessIcon />;
  }
  return <WarningIcon />;
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlert>(initialAlert);

  const showAlert = useCallback(
    (message?: string, severity: Severity = "info", icon?: ReactNode) => {
      if (!message) return;
      setIsOpen(true);
      setAlert({
        message,
        severity,
        icon,
      });
    },
    []
  );

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={ALERT_DURATION_MS}
        onClose={() => setIsOpen(false)}
        anchorOrigin={ANCHOR_ORIGIN}
      >
        <Alert
          sx={{ cursor: "pointer" }}
          icon={getIcon(alert.severity)}
          variant="filled"
          severity={alert.severity}
          onClick={() => setIsOpen(false)}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export default AlertProvider;
