import {
  useInfiniteQuery,
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  InfiniteData,
  QueryKey,
  useQuery
} from "@tanstack/react-query";
import {
  AuditLogSearchQuery,
  GetAllAuditLogsResponse,
  getAllAuditLogs,
  getAuditLogById,
} from "../api/auditLog";
import { IAuditLog } from "../types/audit-logs/auditLog";

const getPreviousPageParam = (
  pages: GetAllAuditLogsResponse[],
  currentPage: number
): string | null | undefined => {
  if (currentPage === 0) {
    return undefined;
  }
  if (currentPage === 1) {
    return null;
  }
  return pages[currentPage - 2]?.nextCursor;
};

export const useAllAuditLogs = (
  customerId?: string,
  searchQuery?: AuditLogSearchQuery,
  currentPage: number = 0,
  options?: UseInfiniteQueryOptions<
    GetAllAuditLogsResponse,
    Error,
    GetAllAuditLogsResponse,
    GetAllAuditLogsResponse,
    QueryKey
  >
) => {
  return useInfiniteQuery<GetAllAuditLogsResponse, Error>({
    ...options,
    queryKey: ["audit-logs", customerId, searchQuery],
    queryFn: ({ pageParam }: QueryFunctionContext) =>
      getAllAuditLogs(customerId!, searchQuery, pageParam as string | undefined),
    enabled: !!customerId,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    getPreviousPageParam: (_, pages) => {
      return getPreviousPageParam(pages, currentPage);
    },
    select: (data: InfiniteData<GetAllAuditLogsResponse, unknown>) => data,
    initialPageParam: undefined,
  });
};

export const useAuditLog = (
  customerId?: string,
  auditLogId?: string | null
) => {
  return useQuery<IAuditLog | undefined, Error>({
    queryKey: ["audit-log", customerId, auditLogId],
    queryFn: () => getAuditLogById(customerId!, auditLogId!),
    enabled: !!customerId || !!auditLogId,
  });
};
