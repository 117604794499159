import MenuItem from "@mui/material/MenuItem";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import UserAuth from "../../types/user/UserAuth";

export interface PermissionMenuItemProps extends PropsWithChildren {
  user: UserAuth | null;
  resource: string;
  requiredPermission: string;
  path: string;
  close: () => void;
}

const PermissionMenuItem = ({
  user,
  resource,
  requiredPermission,
  path,
  close,
  children,
}: PermissionMenuItemProps) => {
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  if (!user.hasPermission(`${resource}:${requiredPermission}`)) {
    return null;
  }

  const goTo = () => {
    navigate(path);
    close();
  };

  return <MenuItem onClick={goTo}>{children}</MenuItem>;
};

export default PermissionMenuItem;
