import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import ConnectorConfigFormView from "./ConnectorConfigFormView";
import { ConnectorWizardFormValues } from "./ConnectorWizard";

export type ConnectorFormAction = "Create" | "Edit";

export interface ConnectorConfigFormProps {
  formId: string;
  form: UseFormReturn<ConnectorWizardFormValues>;
  onSubmit: SubmitHandler<ConnectorWizardFormValues>;
  action: ConnectorFormAction;
}

const ConnectorConfigForm = ({
  formId,
  form,
  onSubmit,
  action,
}: ConnectorConfigFormProps) => {
  const { handleSubmit, reset } = form;

  const submit = async (formValues: ConnectorWizardFormValues) => {
    await onSubmit(formValues);
    reset();
  };

  return (
    <FormProvider {...form}>
      <form id={formId} onSubmit={handleSubmit(submit)} noValidate>
        <ConnectorConfigFormView action={action} />
      </form>
    </FormProvider>
  );
};

export default ConnectorConfigForm;
