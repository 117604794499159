import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import AuditLog, { IAuditLog } from "../../types/audit-logs/auditLog";

export interface AuditLogViewProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  auditLogData: IAuditLog | undefined;
}

const AuditLogView = ({
  isOpen,
  setIsOpen,
  auditLogData,
}: AuditLogViewProps) => {
  const [auditLog, setAuditLog] = useState<AuditLog | undefined>();

  useEffect(() => {
    if (!auditLogData) return;
    setAuditLog(AuditLog.fromIAuditLog(auditLogData));
  }, [auditLogData]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md">
      <DialogTitle>Audit Log Event</DialogTitle>
      <DialogContent>
        {auditLog?.displayInfo() ?? "No data found."}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditLogView;
